import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  Output,
  ViewChild,
  Inject,
} from '@angular/core';
import { FormArray, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { debounceTime, finalize } from 'rxjs/operators';

import { OptionsListItemIfc } from '@app/shared/interfaces/options-list-item.ifc';
import { URLS } from '@app/shared/urls';
import { HttpService, NotificationsService } from '@app/core/services';
import { NgModelBase } from '@app/shared/ng-model.base';
import { PaginatedResponse } from '@app/models/paginated-response.model';
import { OptionsListItem } from '@app/models/options-list-item.model';
import { ServerAPICache } from '@app/cache-token';
import { ObservableCache } from '@app/core/cache';
import { UserType } from '@app/models/users/user-profile.model';

const URLS_MAP = {
  offices: { url: `${ URLS.office }?`, value: 'id', label: 'name' },
  hr: { url: `${ URLS.hr_profile }?`, value: 'id', label: 'full_name' },
  languages: { url: `${ URLS.language }?`, value: 'id', label: 'name', useCache: true },
  basicDisciplines: { url: `${ URLS.basic_discipline }?`, value: 'id', label: 'code', useCache: true },
  disciplines: { url: `${ URLS.discipline }?`, value: 'id', label: 'base_name_value' },
  animals: { url: `${ URLS.animal }?`, value: 'id', label: 'name', useCache: true },
  disease: { url: `${ URLS.dictionary }?dictionary=disease&`, value: 'id', label: 'value', useCache: true },
  electricEquipmentDependencies: { url: `${ URLS.electric_equipment_dependency }?`, value: 'id', label: 'dependency' },
  users: { url: `${ URLS.profile }?`, value: 'id', label: 'full_name' },
  reactions: { url: `${ URLS.reaction }?`, value: 'id', label: 'name', useCache: true },
  physicians: { url: `${ URLS.physician }?`, value: 'id', label: 'full_name' },
  coordinators: { url: `${ URLS.profile }?profile_types=${ UserType.Coordinator }&`, value: 'id', label: 'full_name' },
  caregivers: { url: `${ URLS.profile }?profile_types=${ UserType.Caregiver }&`, value: 'id', label: 'full_name' },
  payers: { url: `${ URLS.payer }?`, value: 'id', label: 'name' },
  categories: { url: `${ URLS.duty_category }?`, value: 'id', label: 'name' },
  patients: { url: `${ URLS.patient }?`, value: 'id', label: 'full_name' },
  payrollBatches: { url: `${ URLS.caregiver_invoice_batch }?`, value: 'id', label: 'number' },
  billingBatches: { url: `${ URLS.payer_batch }?`, value: 'id', label: 'number' },
  payrollTemplates: { url: `${ URLS.payroll_template }?`, value: 'id', label: 'name' },
  authorization: { url: `${ URLS.payer_authorization }?`, value: 'id', label: 'code' }
};

const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => ManyToManyFieldComponent),
  multi: true
};

// DEPRECATED component. Use multiselect-field.component
@UntilDestroy()
@Component({
  selector: 'app-many-to-many-field',
  templateUrl: './many-to-many-field.component.html',
  styleUrls: ['./many-to-many-field.component.scss'],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR]
})
export class ManyToManyFieldComponent extends NgModelBase<Array<any>> implements OnInit {
  @Input() title: string = '';
  @Input() allCheckboxTitle: string = 'All';
  @Input() field: FormArray;
  @Input() optionsPosition: 'bottom' | 'top' | 'center' = 'bottom';
  @Input() retrieveObjects: (keyof typeof URLS_MAP | '') = '';
  @Input() searchKwargs: Array<string | number> = [];
  @Input() placeholder: string = '';
  @Input() noItemsPlaceholder: string;
  @Input() size: 'small' | 'medium' | 'large' = 'medium';
  @Input() delimiter: string = '/';
  @Input() isDisabled: boolean = false;
  @Input() invalid: boolean = false;
  @Input() isDisplayByCount: boolean = false;
  @Input() customOptionsList: OptionsListItem[];
  @Input() limit: number = 100;
  @Input() ifChoosenFirstItemOthersAreNot: boolean = false;
  @Input() enableSorting: boolean = false;
  @Input() notSelectOptionsOnInitialLoad: boolean = false;
  @Input() emitValueOnInit: boolean;
  @Input() maxOptionsListHeight: number;
  @Input() downloadOptions$: Observable<void>;
  @Input()
  set initOptions(options: object[]) {
    if (options) {
      this.options = options.map(
        (item) =>
          new OptionsListItemIfc(
            item[URLS_MAP[this.retrieveObjects].value],
            item[URLS_MAP[this.retrieveObjects].label],
            this.field.value.findIndex(
              (i) => i === item[URLS_MAP[this.retrieveObjects].value]
            ) >= 0
          )
      );

      this.options.forEach((item) =>
        this.field.controls.push(new FormControl(item.value))
      );
      this.recalculateSelectedLabel();
    }
  }

  @Output() selectedEmitter = new EventEmitter<OptionsListItemIfc[]>();
  @Output() stateAllCheckbox = new EventEmitter<boolean>();

  @ViewChild('optionsListEl', { static: false }) optionsListEl: ElementRef;

  optionsAreOpened: boolean = false;
  optionsAreLoaded: boolean = true;
  options: OptionsListItem[];
  legendWidth: number = 0;
  labelHidden: boolean = false;
  selectedLabel: string = '';
  checkedCount: number = 0;
  allCheckbox: boolean = false;
  isInitial: boolean = true;

  private isFirstClick: boolean = false;

  constructor(
    private readonly httpService: HttpService,
    private translate: TranslateService,
    private notificationsService: NotificationsService,
    private cdr: ChangeDetectorRef,
    @Inject(ServerAPICache) private cache: ObservableCache<string>,
  ) {
    super();
  }

  ngOnInit(): void {
    if (this.customOptionsList) {
      this.options = this.customOptionsList.map(option => ({ ...option, checked: this.field.value.includes(option.value) }));
      this.sortAndRecalculateOptions();
    } else {
      this.downloadOptions();
    }

    if (this.downloadOptions$) {
      this.downloadOptions$.pipe(debounceTime(100), untilDestroyed(this))
        .subscribe(() => this.downloadOptions());
    }
  }

  downloadOptions(): void {
    this.optionsAreLoaded = false;
    const request = URLS_MAP[this.retrieveObjects];
    if (request) {
      const kwargs = this.searchKwargs ? this.searchKwargs.join('') : '';
      let requestUrl = `${ request.url }widget=m2m&limit=${ this.limit }${ kwargs }`;
      if (this.searchKwargs?.includes('&no_limits=yes')) {
        requestUrl = requestUrl.replace(`&limit=${ this.limit }`, '');
      }

      const request$ = this.httpService.GET(requestUrl);
      const loadData$ = request.useCache ? this.cache.get(requestUrl, request$) : request$;

      loadData$.pipe(
        finalize(() => {
          this.optionsAreLoaded = true;
          this.cdr.markForCheck();
        }),
        untilDestroyed(this)
      ).subscribe((response: PaginatedResponse | any) => {
          const res = Array.isArray(response) ? response : response.results;

          this.options = res.map((item: any) =>
            new OptionsListItemIfc(
              item[request.value],
              item[request.label],
              this.field?.value?.length
                ? this.field.value.findIndex(i => i === item[request.value]) >= 0
                : this.notSelectOptionsOnInitialLoad ? false : this.isDisplayByCount
            )
          );
          this.sortAndRecalculateOptions();
        },
      (errors) => {
        this.notificationsService.showError(errors);
      });
    }
  }

  sortAndRecalculateOptions(): void {
    if (this.enableSorting) {
      this.options.sort((a, b) => a.label > b.label ? 1 : -1);
    }
    this.recalculateSelectedLabel();
  }

  recalculateSelectedLabel(): void {
    const checkedOptions = this.options.filter((item) => item.checked);
    this.checkedCount = checkedOptions.length;
    this.value = this.options.filter((item) => item.checked);
    this.selectedLabel = this.value.map((item) => this.translate.instant(item.label)).join(` ${ this.delimiter } `);
    this.allCheckbox = this.options.every((item) => item.checked);
    this.stateAllCheckbox.emit(this.allCheckbox);
    this.field.updateValueAndValidity();
    this.invalid = this.field.touched && this.field.invalid;

    if (this.emitValueOnInit) {
      this.selectedEmitter.emit(this.value);
    }

    if (this.isInitial) {
      this.isInitial = false;
      this.cdr.detectChanges();
    } else {
      this.selectedEmitter.emit(this.value);
    }
  }

  toggleOpenOptions(): void {
    if (this.isFirstClick) {
      this.isFirstClick = false;
      return;
    }
    this.optionsAreOpened = !this.optionsAreOpened;
  }

  openOptions(): void {
    if (this.optionsAreOpened || this.isDisabled) {
      return;
    }

    this.isFirstClick = true;
    this.optionsAreOpened = true;
  }

  closeOptions(): void {
    this.isFirstClick = false;
    this.optionsAreOpened = false;
    this.field.markAllAsTouched();
    this.field.updateValueAndValidity();
  }

  optionWasClicked(index: number): void {
    this.options[index].checked = !this.options[index].checked;

    if (this.ifChoosenFirstItemOthersAreNot) {
      this.field.controls = [];
      if (index === 0) {
        this.options.forEach(opt => opt.checked = false);
        this.options[0].checked = true;
      } else {
        this.options[0].checked = false;
        this.options.forEach(option => {
          if (option.checked) {
            this.field.controls.push(new FormControl(option.value));
          }
        });
      }
    }

    if (this.options[index].checked) {
      this.field.controls.push(new FormControl(this.options[index].value));
    } else {
      const optionIndex = this.field.controls.findIndex(
        (control) => control.value === this.options[index].value
      );
      if (optionIndex >= 0) {
        this.field.controls.splice(optionIndex, 1);
      }
    }

    this.field.markAsDirty();
    this.recalculateSelectedLabel();
  }

  switchAllCheckbox(): void {
    this.allCheckbox = !this.allCheckbox;
    this.options.forEach((item) => item.checked = this.allCheckbox);
    this.recalculateSelectedLabel();
    // this.selectedEmitter.emit(this.options);
    this.stateAllCheckbox.emit(this.allCheckbox);
  }
}
