<nav class="submenu flex">
	<ul class="submenu__list flex">
		<li class="submenu__list-item" *ngFor="let item of submenuList">
			<a matRipple
				 class="submenu__list-item-link flex"
				 [routerLink]="[item.link]"
				 [queryParams]="item.queryParams"
				 [routerLinkActiveOptions]="{ exact: false }"
				 routerLinkActive="submenu__list-item-link_active">
				{{ item.name | translate }}
			</a>
		</li>
	</ul>
</nav>
