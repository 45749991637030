import {
  DispatchAddressDetail,
  DispatchCaregiverDetail, DispatchPatientAddressDetail,
  DispatchPatientDetail,
  DispatchServiceNameDetail
} from './dispatch-screen.model';
import { VisitStatus } from "@app/models/visits/visits.model";

export interface DispatchScreenVisit {
  id: number;
  visit_type: number;
  visit_type_value: string;
  status: VisitStatus;
  status_value: string;
  start_date_time: string;
  end_date_time: string;
  fact_start_date_time: string;
  fact_end_date_time: string;
  has_pin: boolean;
  is_confirmed: boolean;
  patient: number;
  patient_detail: DispatchVisitPatientDetail;
  caregiver: number;
  caregiver_detail: DispatchVisitCaregiverDetail;
  service_name: number;
  service_name_detail: DispatchServiceNameDetail;
  patient_address: number;
  patient_address_detail: DispatchPatientAddressDetail;
  payer: number;
  payer_detail: DispatchVisitPayerDetail;
  authorization: number;
  authorization_detail: Partial<DispatchVisitAuthDetail>;
  master_week: number;
  notes: string;
}

export interface DispatchVisitAuthDetail {
  id: number;
  code: string;
  sub_authorizations_details: Partial<DispatchVisitSubAuthorizationsDetails[]>;
}

interface DispatchVisitSubAuthorizationsDetails {
  id: number;
  pricing_service_name: string;
  pricing_service_is_nurse: boolean;
  per_week: number;
  total: number;
}

export interface DispatchVisitPayerDetail {
  id: number;
  name: string;
}

export interface DispatchVisitPatientDetail extends DispatchPatientDetail {
  office_detail: DispatchVisitOfficeDetail;
  coordinators: DispatchVisitCoordinator[];
}

export interface DispatchVisitCaregiverDetail extends DispatchCaregiverDetail {
  teams?: string[];
  phone_number?: string;
  has_mobile_app?: boolean;
  address_detail?: DispatchAddressDetail;
}

export interface DispatchVisitCoordinator {
  id: number;
  first_name: string;
  last_name: string;
  middle_name: string;
  full_name: string;
  phone_number: string;
}

export interface DispatchVisitOfficeDetail {
  id: number;
  name: string;
}

export enum AvailableStatusEnum {
  Available = 0,
  NotAvailable = 1
}