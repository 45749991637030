import { Component, forwardRef, Input, ChangeDetectionStrategy, Output, EventEmitter } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

import { OptionsMultiselectEvent } from '@app/shared/fields/multiselect-field/models';
import { BaseSelectComponent } from '@app/shared/fields/base-select/select.component';

@Component({
  selector: 'app-multiselect-field',
  templateUrl: 'multiselect-field.component.html',
  styleUrls: ['multiselect-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MultiselectFieldComponent),
      multi: true
    }
  ]
})
export class MultiselectFieldComponent extends BaseSelectComponent implements ControlValueAccessor {
  @Input() translateLabel = false;
  @Input() showValueAsCounter = false;
  @Input() showToggleAllOption = false;
  @Input() autoselectAllAfterFirstLoad: boolean = false;
  @Input() placeholder: string = 'placeholders.noSelectedOptions';
  @Input() set viewInputPrefix(value: string) {
    this.inputPrefix = value ? `${ value }: ` : '';
  }

  @Output() optionSelect: EventEmitter<OptionsMultiselectEvent> = new EventEmitter<OptionsMultiselectEvent>();

  value: any[];
  selectedLabels: string[] = [];
  selectedOptions: object[];
  inputPrefix: string = '';

  get isAllOptionsSelected() {
    return this.value?.length === this.options?.length;
  }

  get showSelectedAllWithoutLoadedItems(): boolean {
    return this.autoselectAllAfterFirstLoad && !this.optionsWereLoadedAtLeastOnce;
  }

  constructor(private translateService: TranslateService) {
    super();
  }

  onOptionSelected(): void {
    this.updateInputView();
    this.onChangeCallback(this.value);
    this.optionSelect.emit({
      newValues: this.value,
      optionsData: this.selectedOptions,
      changedByAutoselect: false,
      isSelectedAll: this.isAllOptionsSelected
    });
  }

  toggleAllOptions(): void {
    this.value = this.isAllOptionsSelected ? [] : this.options.map(option => option[this.bindValue]);
    this.onOptionSelected();
    this.cdr.markForCheck();
  }

  protected override onOptionsLoad(options: any[]) {
    super.onOptionsLoad(options);
    if (!this.optionsWereLoadedAtLeastOnce && this.autoselectAllAfterFirstLoad) {
      this.autoselectAllItems();
    }
  }

  protected override updateInputView(): void {
    this.selectedOptions = (this.value ?? []).map((value) => this.options.find(option => option[this.bindValue] === value));
    this.selectedLabels = this.selectedOptions.map(option => {
      const label = option ? option[this.bindLabel] : null;

      return this.translateLabel && label ? this.translateService.instant(label) : label;
    });
  }

  private autoselectAllItems(): void {
    this.writeValue(this.options.map(option => option[this.bindValue]));
    this.onChangeCallback(this.value);
    this.optionSelect.emit({
      newValues: this.value,
      optionsData: this.selectedOptions,
      changedByAutoselect: true,
      isSelectedAll: true
    });
  }
}
