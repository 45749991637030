type Params = { [key: string]: any };

export class QueryBuilder {
  static buildFromObject(obj: Params): string {
    if (!obj) {
      return '';
    }

    const queryParams = [];
    const objectKeys: string[] = Object.keys(obj).sort((a, b) => a > b ? 1 : -1);

    for (const key of objectKeys) {
      if (Array.isArray(obj[key])) {
        queryParams.push(`${ key }=${ obj[key].join(',') }`);
      } else {
        queryParams.push(`${ key }=${ obj[key] }`);
      }
    }

    return queryParams.join('&');
  }

  static isParamsSame(obj1: Params, obj2: Params): boolean {
    return this.buildFromObject(obj1) === this.buildFromObject(obj2);
  }
}
