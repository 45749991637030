<div
  class="field {{ size }}"
  [ngClass]="{
    'disabled': isDisabled,
    'active': this.active,
    'readonly': readonly
  }">
  <label (click)="changeValue($event)">
    <span class="checkbox" [ngClass]="{ 'checked': value }">
      <input
        #checkbox
        type="checkbox"
        (focus)="focusIn()"
        (focusout)="focusOut()"
        [checked]="value"
        [disabled]="isDisabled"/>
      <i class="fa fa-check"></i>
    </span>
    <span class="placeholder">{{ placeholder | translate }}</span>
  </label>
</div>
