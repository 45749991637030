<ng-select
  #ngSelect
  class="app-ng-select"
  bindLabel="label"
  bindValue="value"
  appendTo="body"
  placeholder="{{ 'placeholders.select' | translate }}"
  appearance="outline"
  [(ngModel)]="caregiver"
  [items]="list"
  [readonly]="visitStatus === visitsStatuses.completed"
  [notFoundText]="customNotFoundText | translate"
  (search)="searchChange($event)"
  (change)="selectChange($event)"
  (open)="openInput()">

  <ng-template ng-option-tmp let-item="item">
   <span class="option-label"> {{ item.label }} </span>
  </ng-template>
</ng-select>
