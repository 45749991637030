import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-chat-doc',
  templateUrl: './chat-doc.component.html',
  styleUrls: ['./chat-doc.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatDocComponent implements OnInit {
  @Input() docUrl: string;
  @Input() contentType: string;

  constructor() { }

  ngOnInit(): void {
  }

  getTypeofDoc(): string {
    if (this.contentType.endsWith('document') || this.contentType.endsWith('msword')) {
      return 'word';
    } else if (this.contentType.endsWith('plain')) {
      return 'text';
    } else if (this.contentType.endsWith('sheet') || this.contentType.endsWith('ms-excel')) {
      return 'excel';
    }
    return 'pdf';
  }

}
