import { ChangeDetectorRef, inject, InjectionToken, Provider } from '@angular/core';

type HideLoader = () => void;

export const LoadingHandlerToken = new InjectionToken<LoadingHandler>('Loading Handler');

export function provideLoadingHandler(): Provider {
  return {
    provide: LoadingHandlerToken,
    useFactory: () => new LoadingHandler()
  };
}

export class LoadingHandler {
  private changeDetectorRefs: ChangeDetectorRef[] = [ inject(ChangeDetectorRef) ];
  private loadingProcesses = 0;
  // tslint:disable-next-line:variable-name
  private _loading = false;

  get loading(): boolean {
    return this._loading;
  }

  get initializing(): boolean {
    return this._initializing;
  }

  // tslint:disable-next-line:variable-name
  constructor(private _initializing = false) {
  }

  showLoading(initializing: boolean = false): HideLoader {
    this.loadingProcesses++;
    this._loading = true;
    this._initializing = initializing;
    this.markForCheck();

    return this.hideLoader.bind(this);
  }

  bindChangeDetector(cdr: ChangeDetectorRef): void {
    this.changeDetectorRefs.push(cdr);
  }

  private hideLoader(): void {
    if (this.loadingProcesses <= 0) {
      return;
    }

    if (--this.loadingProcesses === 0) {
      if (this.loadingProcesses === 0) {
        this._initializing = false;
        this._loading = false;
        this.markForCheck();
      }
    }
  }

  private markForCheck(): void {
    this.changeDetectorRefs.forEach(cdr => cdr.markForCheck());
  }
}
