import * as jose from 'jose';

export class JwtGenerator {
  static async generateJWT(token: string, secret: string): Promise<string> {
    const encodedSecret = new TextEncoder().encode(secret);
    const alg = 'HS256';

    return await new jose.SignJWT({ token })
      .setProtectedHeader({ alg })
      .sign(encodedSecret);
  }
}
