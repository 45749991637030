import { Permissions, ProfilePermissionsFeatures } from '@app/models/settings/permissions.model';
import { NormalizedContentTypes } from '@app/models/login/login.model';

export interface UserProfile {
  id: number;
  avatar: string;
  full_name: string;
}

export interface UserOfficesDetails {
  id: number;
  name: string;
}

export enum UserType {
  Administrator = 0,
  Coordinator = 1,
  Payroll = 2,
  Biller = 3,
  Caregiver = 4,
  Trainee_Applicant = 5,
  HR = 6,
  Instructor = 7
}

export interface AuthProfileInfo {
  id: number;
  companyId: number;
  contentTypes: NormalizedContentTypes;
  permissions: Permissions;
  features: ProfilePermissionsFeatures;
  fullName: string;
  avatar: string;
}
