<div class="message" [class.not-sent]="message.status === messagesStatuses.notSent">
  <div class="message__avatar">
    <app-user-avatar
      [name]="message.sender.short_name"
      [avatar]="message.sender.avatar"
      iconSize="18px"
    ></app-user-avatar>
  </div>

  <div *ngIf="!isThread" class="message__actions">
    <span class="material-icons-two-tone action-icon delete-icon" (click)="deleteMessage(message.id)">delete</span>
    <span
      class="material-icons action-icon replay-icon"
      [class.resending]="message.resending"
      (click)="resendMessage(message.id)"
    >replay</span>
  </div>

  <div class="message__body">
    <div class="message__data">
      <div class="message__name">{{ message.sender.short_name }}</div>
      <div class="message__text">
        <span *ngIf="message.channel === messagesChannels.email" class="material-icons-two-tone channel-icon send-email">email</span>
        <span *ngIf="message.channel === messagesChannels.sms" class="material-icons-two-tone channel-icon sms-done">sms</span>
        <span *ngIf="message.channel === messagesChannels.sms" class="material-icons-two-tone channel-icon sms-failed">sms_failed</span>
        {{ message.text }}
      </div>
      <div *ngIf="message.files.length" [style.height.px]="setFilesHeight()">
        <div class="message__file" *ngFor="let file of message.files; index as i">
          <app-chat-image
            *ngIf="isImage(file.content_type)"
            [imageUrl]="file.file"
            (clickOnImage)="openImageView(file.id)"
          ></app-chat-image>
          <app-chat-doc
            *ngIf="isDocument(file.content_type)"
            [contentType]="file.content_type"
            [docUrl]="file.file"
          ></app-chat-doc>
        </div>
      </div>
      <div *ngIf="!!message.created_at" class="message__time">{{ setTime(message.created_at) }}</div>
    </div>

    <div *ngIf="!!message.replies_info.messages_count && !isThread" class="message__replies replies">
      <span class="replies__count" (click)="answerToThread(message)">{{ message.replies_info.messages_count }} replies</span>
      <span *ngIf="!!message.replies_info.last_message_time" class="replies__time">{{ setTime(message.replies_info.last_message_time) }}</span>
    </div>

    <div class="check {{ getMessageDeliveryStatus() }}"></div>
  </div>

  <div *ngIf="!isThread" class="message__actions action-message">
    <span class="material-icons-two-tone action-icon" (click)="answerToThread(message)">question_answer</span>
    <!--<span class="material-icons action-icon" (click)="reply()">reply</span>-->
  </div>
</div>

<div *ngIf="showDivider" class="divider"></div>
