<button #trigger="cdkOverlayOrigin" mat-icon-button cdkOverlayOrigin type="button" (click)="openContent()">
  <mat-icon>{{ icon }}</mat-icon>
</button>
<ng-template
  cdkConnectedOverlay
  cdkConnectedOverlayBackdropClass="cdk-overlay-transparent-backdrop"
  cdkConnectedOverlayPanelClass="visit-log-panel"
  [cdkConnectedOverlayOrigin]="trigger"
  [cdkConnectedOverlayOpen]="isContentVisible"
  [cdkConnectedOverlayHasBackdrop]="true"
  [cdkConnectedOverlayPositions]="positionPairs"
  (backdropClick)="closeContent()">
  <ng-content></ng-content>
</ng-template>
