import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

export interface Identifier {
  value: string;
  status: number;
}

@Component({
  selector: 'app-chip',
  templateUrl: './chip.component.html',
  styleUrls: ['./chip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChipComponent {
  @Input() value: string;
  @Input() status: number;
}
