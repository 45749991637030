import { NgModule } from '@angular/core';

import { MessageCenterChatComponent } from './message-center-chat.component';
import { CreateNewMessageComponent } from '../create-new-message/create-new-message.component';
import { ChatMessageComponent } from './chat-message/chat-message.component';
import { MessagesListComponent } from './messages-list/messages-list.component';
import { ChatService } from '../services/chat.service';
import { SharedModule } from '../shared/shared.module';
import { SendMessageComponent } from './send-message/send-message.component';
import { ViewImageComponent } from './view-image/view-image.component';
import { ThreadMessagesListComponent } from './thread-messages-list/thread-messages-list.component';

@NgModule({
  declarations: [
    MessageCenterChatComponent,
    CreateNewMessageComponent,
    ChatMessageComponent,
    MessagesListComponent,
    SendMessageComponent,
    ViewImageComponent,
    ThreadMessagesListComponent
  ],
  imports: [
    SharedModule
  ],
  providers: [
    ChatService
  ],
  exports: [
    MessageCenterChatComponent
  ]
})
export class MessageCenterChatModule {
}
