import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { PipesModule } from '@app/shared/pipes/pipes.module';

import { MultiSelectComponent } from './multiselect/multiselect.component';
import { ParametricSearchComponent } from './parametric-search/parametric-search.component';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { IMaskModule } from 'angular-imask';

import { PaginatorComponent } from './pagination/paginator.component';
import { NavigateButtonsComponent } from './navigate-buttons/navigate-buttons.component';
import { BreadcrumbsComponent } from './breadcrumbs/breadcrumbs.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { LoadingModule } from '@app/shared/components/loading/loading.module';


@NgModule({
  declarations: [
    MultiSelectComponent,
    ParametricSearchComponent,
    PaginatorComponent,
    NavigateButtonsComponent,
    BreadcrumbsComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    PipesModule,
    NgbModule,
    RouterModule,
    IMaskModule,
    TranslateModule,
    MatTooltipModule,
    LoadingModule,
  ],
  exports: [
    MultiSelectComponent,
    ParametricSearchComponent,
    PaginatorComponent,
    NavigateButtonsComponent,
    BreadcrumbsComponent,
    LoadingModule,
  ]
})
export class ElementsModule {}
