import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';

import { ChatSendingMessage, LoadedMessageFile } from '../../models/message.model';

@Component({
  selector: 'app-send-message',
  templateUrl: './send-message.component.html',
  styleUrls: ['./send-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SendMessageComponent implements OnInit, OnChanges {
  @Input() setInputFocus: boolean;
  @ViewChild('textInput', { static: true }) textInput: ElementRef;
  @Output() messageChanged: EventEmitter<ChatSendingMessage> = new EventEmitter();
  @Output() inputClickedEmitter: EventEmitter<void> = new EventEmitter();
  messageText: string = '';
  loadedFiles: LoadedMessageFile[] = [];
  clearFiles: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

  sendMessage(): void {
    const message = this.messageText.trim();
    if (message || this.loadedFiles.length) {
      this.messageChanged.emit({
        message,
        files: this.loadedFiles
      });
      this.messageText = '';
      this.loadedFiles = [];
      this.clearFiles = true;
    }
  }

  changeLoadedFiles(event: LoadedMessageFile[]): void {
    this.loadedFiles = event;
    this.sendMessage();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.setInputFocus?.currentValue) {
      this.textInput.nativeElement.focus();
    }
  }

  isInputClicked(): void {
    this.inputClickedEmitter.emit();
  }
}
