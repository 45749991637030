import { OptionsListItemIfc } from '@app/shared/interfaces/options-list-item.ifc';
import { PageSize } from '@app/shared/helper';

export interface PaginationParams {
  offset?: number;
  limit?: number;
}

export class PaginationIfc {
  limit: number;
  offset: number;
  total: number;
  pageNumberDetector: boolean;

  constructor(limit = PageSize,
              offset = 0,
              total = 0) {
    this.limit = limit;
    this.offset = offset;
    this.total = total;
    this.pageNumberDetector = false;
  }

  countChanged(count: number): void {
    this.total = count;
  }

  limitChanged(newLimit: number): void {
    this.limit = newLimit;
  }

  offsetChanged(newOffset: number = 0): void {
    this.offset = newOffset;
    if (!newOffset) {
      this.pageNumberDetector = !this.pageNumberDetector;
    }
  }

  getPaginationParameters(): string {
    return `limit=${ this.limit }&offset=${ this.offset }`;
  }

  getParams(): PaginationParams {
    return {
      offset: this.offset,
      limit: this.limit,
    };
  }

  getFilterParameters(key: string, optionsArray: OptionsListItemIfc[]): string {
    return optionsArray ? `${ key }=${ optionsArray.filter((item) => item.checked).map((item) => item.value).join(',') }` : '';
  }
}
