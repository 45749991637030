<div
  class="field {{ size }}"
  [ngClass]="{
     disabled: isDisabled,
     invalid: invalid,
     active: active,
     readonly: readonly
   }">

  <fieldset
    class="field-label"
    [ngClass]="{
      'label-top': labelTop,
      'label-hidden': labelHidden
    }">
  </fieldset>

  <div class="field-text">
    <input
      #searchInput
      ngx-google-places-autocomplete
      autocomplete="false"
      type="text"
      autocapitalize="off"
      [readonly]="readonly"
      [ngModel]="value"
      [maxlength]="maxlength"
      [placeholder]="placeholder"
      (ngModelChange)="modelChange($event)"
      (focus)="focusIn()"
      (focusout)="focusOut()"
      (keydown.enter)="$event.preventDefault()"
      (onAddressChange)="handleAddressChange($event)">

    <div
      class="icon delete-cross-icon"
      [hidden]="!value"
      (click)="deleteAddress()">
    </div>
  </div>
</div>
