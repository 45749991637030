import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationComponent implements OnInit {

  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public readonly data: { type: string, text: string },
    private snackBarRef: MatSnackBarRef<NotificationComponent>
  ) { }

  ngOnInit(): void {
  }

  close(): void {
    this.snackBarRef.dismiss();
  }

}
