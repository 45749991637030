import { Directive, ElementRef, Output, OnInit, EventEmitter, OnDestroy } from '@angular/core';

@Directive({
  selector: '[passiveTouchStartListener]',
})
export class PassiveTouchStartDirective implements OnInit, OnDestroy {
  @Output() passiveTouchstart = new EventEmitter<TouchEvent>();

  constructor(private elementRef: ElementRef) {
  }

  ngOnInit(): void {
    this.elementRef.nativeElement.addEventListener('touchstart', this.onTouch.bind(this), { passive: true });
  }

  private onTouch(event: TouchEvent): void {
    this.passiveTouchstart.emit(event);
  }

  ngOnDestroy(): void {
    this.elementRef?.nativeElement?.removeEventListener('touchstart', this.onTouch.bind(this));
  }
}
