import { DaysWeek } from '@app/shared/days-week';
import { OfficeWidget, PayerWidget, UserWidget } from '@app/models/widgets.model';
import { PaginationParams } from "@app/shared/interfaces/pagination.class";

export interface DialogData {
  mode: 'create' | 'edit';
  title: string;
  buttonLabel: string;
  patient: number;
  authList: AuthDialogData[];
  data: AuthDialogData;
}

export interface AuthDialogData {
  auth_code: string;
  auth_file: null;
  auth_id: number;
  available: string;
  details: string;
  end_date: string;
  notes: string;
  payer_id: number;
  payer_name: string;
  master_week: number;
  period: string;
  start_date: string;
  status: number;
  status_value: string;
  sub_authorizations_details: Array<SubAuthorizationDetails>;
}

export interface AuthorizationDetails {
  auth_file?: string;
  code: string;
  end_date: string;
  id: number;
  notes: string;
  payer_details: PayerWidget;
  poc_details?: any;
  start_date: string;
  status: number;
  status_value: string;
  sub_authorizations_details: SubAuthorizationDetails[];
  master_week: number;
  allowed_days?: number[];
}

export interface BlastingAuthorization {
  assign_status: AssignStatus;
  assign_status_value: string;
  code: string;
  start_date: string;
  end_date: string;
  id: number;
  masterweek_id: number;
  new_offers_count: number;
  payer: number;
  payer_name: string;
  services: ServiceDetail[];
  status_value: string;
  total_visits_count: number;
  unassigned_visits_count: number;
}

export interface AuthorizationRequestParams extends PaginationParams {
  patient_id?: number;
  used_by_master_week?: boolean;
  blasting_info?: boolean;
}

export enum AssignStatus {
  Open,
  Closed
}

export interface SubAuthorizationDetails {
  allowed_days: number[];
  available_time_left?: number;
  available_time_per_week: number;
  available_time_total: number;
  id: number;
  per_week: number;
  pricing_service: number;
  pricing_service_is_nurse: boolean;
  pricing_service_name: string;
  total: number;
  service_detail?: { is_nurse: boolean };
  weeks_count?: number;
  conn_authorization: AvailableNurseAuth;
  has_connected_authorization: boolean;
  measurement_type: MeasurementType;
  measurement_type_value?: string;
  available_time_per_week_total?: number;
  available_time_total_total?: number;
}

export interface AuthorizationService {
  allowedDays: number[];
  hasConnectedAuth: boolean;
  perWeek: number;
  service: AuthorizationServiceData;
  serviceId: number;
  showDays: boolean;
  total?: number;
  totalUnits?: number;
  totalVisits?: number;
  rnAuth: number;
  sub_auth: number;
  is_nurse: boolean;
  measurement_type: MeasurementType;
}

export interface AvailableNurseAuth {
  id: number;
  code: string;
  start_date: string;
  end_date: string;
  sub_auth: number;
  sub_auth_service_name: string;
  sub_auth_total: number;
  sub_auth_available_total_left: number;
}

export interface AuthorizationServiceData {
  id: number;
  name: string;
  per_week: number;
  service_detail: { is_nurse: boolean };
  total?: number;
  totalUnits: number;
  totalVisits: boolean;
}

export interface ServiceCalculatedHours {
  start_date: string;
  end_date: string;
  measurement_type?: MeasurementType;
  only_available_time?: boolean;
  per_week?: number;
  total?: number;
  required_days?: number;
  payer: number;
  pricing_service?: number;
}

export interface ServiceItemCalculation {
  total: number;
  per_week: number;
  available_time_total?: string;
  available_time_per_week?: string;
  end_date?: string;
  payer?: number;
  pricing_service?: number;
  start_date: string;
  has_connected_authorization?: boolean;
}

export interface ServiceListItem extends ServiceItemCalculation {
  id: number;
  name: string;
  service_detail: { is_nurse: boolean };
  daysWeek?: DaysWeek;
}

export interface ServiceDiscipline {
  id: number;
  name: string;
  service: number;
  service_detail: ServiceFullDetail;
  service_code: string;
  rate_type: number;
  rate_type_value: string;
  regular_rate: string;
  holiday_rate: string;
  round_on: number;
  round_on_value: string;
  option: number;
  option_value: string;
  modifier: string;
  payer: number;
  nurse_service_required: boolean;
  nurse_service: number;
  nurse_service_detail: NurseServiceDetail;
  disabled?: boolean;
}

interface ServiceDetail {
  id: number;
  name: string;
  is_nurse: boolean;
}

export enum MeasurementType {
  Units,
  Hours,
  Visits
}

interface NurseServiceDetail {
  id: number;
  name: string;
}

export interface NurseVisitShouldBeCreated {
  should_be_created: boolean;
}

export interface NurseServiceShouldBeCreated {
  id: number;
  start_date: string;
  end_date: string;
  should_be_added?: boolean;
  patient: number;
}

export interface PatientAvailableAuth {
  id: number;
  code: string;
  status: number;
  status_value: string;
  is_nurse: boolean;
  available_time_left: number;
  available_total_left: number;
}

export enum AuthorizationStatus {
  active = 0,
  inactive = 1,
  expired = 2
}

export interface PatientAuthorization {
  id: number;
  is_authorization: boolean;
  is_used_by_master_week: boolean;
  is_nurse_service: boolean;
  authorization_code: string;
  payer_name: string;
  service: number;
  service_name: string;
  service_name_detail: ServiceFullDetail;
  start_date: string;
  end_date: string;
  hours: string;
  units: number;
  visits: number;
  notes: string;
  status: AuthorizationStatus;
  status_value: string;
}

export interface PatientAuthorizationRequestParams {
  start_date?: string;
  end_date?: string;
  payers?: number[];
  services?: number[];
  auth_statuses?: AuthorizationStatus[];
  without_auth_status?: boolean;
}

interface ServiceNameDetail {
  id: number;
  name: string;
  service_code: string;
  service: number;
  service_detail: ServiceFullDetail;
}

export interface ServiceFullDetail {
  id: number;
  name: string;
  is_nurse: boolean;
  basic_discipline: number;
  basic_discipline_detail: BasicDisciplineDetail;
}

export interface BasicDisciplineDetail {
  id: number;
  code: string;
}

export interface BasicDisciplineParams {
  search?: string;
  widget?: string;
  limit?: number;
}

export interface PatientCalendarVisit {
  id: number;
  is_unreal: boolean;
  master_week: number;
  mw_visit_creation_chain: any;
  nurse_visit_creation_chain: any;
  status: number;
  status_value: string;
  visit_type: number;
  visit_type_value: string;
  service_type: number;
  service_type_value: string;
  start_date_time: string;
  end_date_time: string;
  fact_start_date_time: string;
  fact_end_date_time: string;
  caregiver: number;
  caregiver_detail: UserWidget;
  service_name: number;
  service_name_detail: ServiceNameDetail;
  duration: number;
  has_overtime: boolean;
}

interface ServiceNameDetail {
  id: number;
  name: string;
  service_code: string;
  service: number;
  service_detail: ServiceFullDetail;
}

interface ServiceDetail {
  id: number;
  name: string;
  is_nurse: boolean;
  basic_discipline: number;
  basic_discipline_detail: BasicDisciplineDetail;
}

export interface AuthStatus {
  status: number;
  status_value: string;
}

export interface AuthPayer {
  id: number;
  avatar: string;
  name: string;
  effective_date: string;
  expiration_date: string;
  status: number;
  status_value: string;
  offices_details: OfficeWidget[];
}
