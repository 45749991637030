import { HC_CONFIG, MC_CONFIG } from '../config';

// message center
export const MC_HOST = `${ MC_CONFIG.baseProtocol }${ MC_CONFIG.baseHost }:${ MC_CONFIG.basePort }${ MC_CONFIG.basePath }`;
const MC_WS_HOST = `${ MC_CONFIG.baseWsProtocol }${ MC_CONFIG.baseHost }:${ MC_CONFIG.baseWsPort }`;

// home care
const HC_HOST = `${ HC_CONFIG.baseProtocol }${ HC_CONFIG.baseHost }:${ HC_CONFIG.basePort }${ HC_CONFIG.basePath }`;

export const MESSAGE_CENTER_URLS = {
  socket:             MC_WS_HOST + '/frontend/',

  user:               MC_HOST + 'applications/user/',

  user_account:       MC_HOST + 'accounts/user/',

  external_link:      HC_HOST + 'communication/external_link/',

  user_chat:          MC_HOST + 'conversations/chat/',
  chat_message:       MC_HOST + 'conversations/message/',
  create_direct_chat: MC_HOST + 'conversations/chat/create_direct_chat/',
  create_group_chat:  MC_HOST + 'conversations/chat/create_group_chat/',
};
