import { Component, OnInit } from '@angular/core';

import { LanguageService } from '@app/core/services';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
  constructor(
    private languageService: LanguageService,
  ) {}

  ngOnInit() {
    this.languageService.init();
  }
}
