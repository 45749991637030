import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';
import { NgSelectComponent } from '@ng-select/ng-select';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';

import { selectNotFoundText } from '@app/shared/constants';
import { CaregiverSelectOption } from '@app/models/dispatch-screen/dispatch-screen.model';
import { DispatchScreenService } from '@app/core/services';
import { PaginatedResponse } from '@app/models/paginated-response.model';
import { UserWidget } from '@app/models/widgets.model';
import { VisitStatus } from '@app/models/visits/visits.model';

@UntilDestroy()
@Component({
  selector: 'app-caregiver-cell',
  templateUrl: './caregiver-cell.component.html',
  styleUrls: ['./caregiver-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CaregiverCellComponent implements OnInit, OnChanges {
  @Input() currentSelectValue: number;
  @Input() currentSelectTitle: string;
  @Input() visitId: number;
  @Input() pageVerticalScroll: number;
  @Input() tableHorizontalScroll: number;
  @Input() visitStatus: number;
  @Output() selectChanged = new EventEmitter<any>();
  @ViewChild('ngSelect') ngSelect: NgSelectComponent;

  readonly customNotFoundText = selectNotFoundText;
  readonly visitsStatuses = VisitStatus;
  caregiver: number = undefined;
  list: CaregiverSelectOption[];

  searchSubject = new Subject<string>();

  constructor(
    private dispatchScreenService: DispatchScreenService,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.currentSelectValue) {
      if (changes.currentSelectValue.currentValue !== changes.currentSelectValue.previousValue) {
        this.caregiver = changes.currentSelectValue.currentValue;
        this.list = [{ value: this.caregiver, label: changes.currentSelectTitle.currentValue }];
      }
    }
    if (changes.pageVerticalScroll) {
      if (changes.pageVerticalScroll.currentValue && !changes.pageVerticalScroll.firstChange) {
        this.ngSelect.close();
      }
    }
    if (changes.tableHorizontalScroll) {
      if (changes.tableHorizontalScroll.currentValue && !changes.tableHorizontalScroll.firstChange) {
        this.ngSelect.close();
      }
    }
  }

  ngOnInit(): void {
    if (this.currentSelectValue) {
      this.list = [{ value: this.currentSelectValue, label: this.currentSelectTitle }];
      this.caregiver = this.list[0].value;
      this.cdr.detectChanges();
    }

    this.searchSubject.pipe(
      filter(res => {
        return res?.length > 1;
      }),
      debounceTime(300),
      distinctUntilChanged(),
      untilDestroyed(this)
    ).subscribe((response: string) => {
      this.loadAvailableCaregiversByVisitId(100, response);
    });
  }

  openInput(): void {
    if (!this.currentSelectValue) {
      this.list = [{ value: 0, label: 'loading...', disabled: true }];
      this.loadAvailableCaregiversByVisitId(10);
    }
  }

  selectChange(event: CaregiverSelectOption): void {
    if (event) {
      this.list = [event];
    } else {
      this.loadAvailableCaregiversByVisitId(100);
    }
    this.selectChanged.emit(event);
  }

  searchChange(event): void {
    this.searchSubject.next(event.term);
  }

  private loadAvailableCaregiversByVisitId(limit, search?: string): void {
    this.dispatchScreenService.getAvailableCaregiversByVisitId(this.visitId, search, limit)
    .subscribe((response: PaginatedResponse<UserWidget>) => {
      this.list = response.results.map(s => {
        return { value: s.id, label: s.full_name };
      });
      this.cdr.detectChanges();
    });
  }
}
