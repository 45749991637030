import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatDialogModule } from '@angular/material/dialog';
import { MatRippleModule } from '@angular/material/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatChipsModule } from '@angular/material/chips';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSnackBarModule } from '@angular/material/snack-bar';

const modules = [
  MatIconModule,
  MatButtonModule,
  MatSelectModule,
  MatProgressSpinnerModule,
  MatDialogModule,
  MatRippleModule,
  MatCheckboxModule,
  MatFormFieldModule,
  MatChipsModule,
  MatAutocompleteModule,
  MatSnackBarModule
];

@NgModule({
  imports: [...modules],
  exports: [...modules]
})

export class MaterialModule {
}
