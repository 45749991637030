import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { convertFrom12To24Format } from '@app/shared/helper';

@Component({
  selector: 'app-ampm-field-range',
  templateUrl: './ampm-field-range.component.html',
  styleUrls: ['./ampm-field-range.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AmpmFieldRangeComponent implements OnInit, OnChanges {
  @Input() initialRange: string;
  @Input() editTooltip: string;
  @Input() startTimeCanBeBigger: boolean = false;
  @Output() timeRangeChanged = new EventEmitter<string>();

  form: FormGroup;
  isShowForm: boolean = false;
  amPMList = [
    { value: 'AM', label: 'AM' },
    { value: 'PM', label: 'PM' }
  ];

  hoursInputStart: string;
  minutesInputStart: string;
  ampmInputStart: string;
  hoursInputEnd: string;
  minutesInputEnd: string;
  ampmInputEnd: string;
  endLessThanStart: boolean = false;

  get isIncorrectTimesDifference(): boolean {
    return this.endLessThanStart && !this.startTimeCanBeBigger;
  }

  constructor(
    private fb: FormBuilder
  ) {
  }

  get hoursStart(): FormControl {
    return this.form.get('hoursStart') as FormControl;
  }
  get minutesStart(): FormControl {
    return this.form.get('minutesStart') as FormControl;
  }
  get hoursEnd(): FormControl {
    return this.form.get('hoursEnd') as FormControl;
  }
  get minutesEnd(): FormControl {
    return this.form.get('minutesEnd') as FormControl;
  }

  ngOnInit(): void {
    this.initForm();
    this.setInitialTime();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.initialRange.firstChange && changes.initialRange.currentValue !== changes.initialRange.previousValue) {
      this.initialRange = changes.initialRange.currentValue;
      this.setInitialTime();
    }
  }

  private setInitialTime(): void {
    if (!this.initialRange) {
      return;
    }

    const splitRange = this.initialRange.split(' - ');

    const splitStart = splitRange[0].split(' ');
    this.ampmInputStart = splitStart[1];
    const splitStartHM = splitStart[0].split(':');
    this.hoursInputStart = splitStartHM[0];
    this.minutesInputStart = splitStartHM[1];

    const splitEnd = splitRange[1].split(' ');
    this.ampmInputEnd = splitEnd[1];
    const splitEndHM = splitEnd[0].split(':');
    this.hoursInputEnd = splitEndHM[0];
    this.minutesInputEnd = splitEndHM[1];

    this.form.setValue({
      hoursStart: splitStartHM[0],
      minutesStart: splitStartHM[1],
      ampmStart: splitStart[1],
      hoursEnd: splitEndHM[0],
      minutesEnd: splitEndHM[1],
      ampmEnd: splitEnd[1]
    });
  }

  private initForm(): void {
    this.form = this.fb.group({
      hoursStart: [null, Validators.required],
      minutesStart: [null, Validators.required],
      ampmStart: 'AM',
      hoursEnd: [null, Validators.required],
      minutesEnd: [null, Validators.required],
      ampmEnd: 'AM'
    });
  }

  hoursChanged(event, formControl: FormControl): void {
    this.endLessThanStart = false;
    let value = event.target.value;
    if (value) {
      value = value.replace(/[^0-9]*/g, '');
      formControl.setValue(value);
    }
    if (!value || +value > 12) {
      formControl.setErrors({ hours: true });
    } else {
      formControl.setErrors(null);
    }
  }

  minutesChanged(event, formControl: FormControl): void {
    this.endLessThanStart = false;
    let value = event.target.value;
    if (value) {
      value = value.replace(/[^0-9]*/g, '');
      formControl.setValue(value);
    }
    if (!value || +value > 59) {
      formControl.setErrors({ minutes: true });
    } else {
      formControl.setErrors(null);
    }
  }

  saveTime(): void {
    this.checkIsStartTimeBiggerThenEndTime();

    if (this.isIncorrectTimesDifference) {
      return;
    }

    const form = this.form.value;
    this.hoursInputStart = form.hoursStart;
    this.minutesInputStart = form.minutesStart;
    this.ampmInputStart = form.ampmStart;
    this.hoursInputEnd = form.hoursEnd;
    this.minutesInputEnd = form.minutesEnd;
    this.ampmInputEnd = form.ampmEnd;
    this.initialRange = `${ form.hoursStart }:${ form.minutesStart } ${ form.ampmStart } - ${ form.hoursEnd }:${ form.minutesEnd } ${ form.ampmEnd }`;
    this.timeRangeChanged.emit(this.initialRange);
    this.isShowForm = false;
  }

  private checkIsStartTimeBiggerThenEndTime(): void {
    const form = this.form.value;
    const start = convertFrom12To24Format(`${ form.hoursStart }:${ form.minutesStart } ${ form.ampmStart }`);
    const startSplit = start.split(':');
    const startMin = +startSplit[0] * 60 + +startSplit[1];

    const end = convertFrom12To24Format(`${ form.hoursEnd }:${ form.minutesEnd } ${ form.ampmEnd }`);
    const endSplit = end.split(':');
    const endMin = +endSplit[0] * 60 + +endSplit[1];

    this.endLessThanStart = startMin >= endMin;
  }

  ampmChanged(): void {
    this.endLessThanStart = false;
  }

  cancel(): void {
    this.endLessThanStart = false;
    this.setInitialTime();
    this.timeRangeChanged.emit(this.initialRange);
    this.isShowForm = false;
  }
}
