<div class="message-center">
  <div class="message-center__header">
    <h3 class="message-center__title">Message Center</h3>
    <span class="material-icons-two-tone group-icon" (click)="createNewGroup()">group_add</span>
    <button matRipple class="btn-close" (click)="close()">
      <span class="material-icons btn-close__icon">close</span>
    </button>
  </div>

  <div class="message-center__search">
    <app-form-field>
      <input
        class="mc-form-field-input"
        type="text"
        placeholder="Search"
        appTrimInput
        [formControl]="search"/>
    </app-form-field>

    <app-form-field>
      <mat-select
        class="mc-form-field-input"
        panelClass="mc-select-panel"
        placeholder="Select"
        [formControl]="type">
        <mat-option *ngFor="let type of usersTypes" value="{{ type.value }}">
          {{ type.title }}
        </mat-option>
      </mat-select>
    </app-form-field>
  </div>

  <div *ngIf="isFirstLoading" class="loading">
    <mat-spinner diameter="70"></mat-spinner>
  </div>

  <div *ngIf="usersList" class="message-center__list">
    <div
      *ngFor="let user of usersList"
      #unit
      class="user"
      (click)="openUserChat(user)">
      <ng-container *ngIf="user.conversation_type === conversationsTypes.direct; else groupChat">
        <app-user-avatar
          [name]="user.name"
          [avatar]="user.image"
        ></app-user-avatar>
        <div class="user__card">
          <div class="user__title">
            <span class="material-icons-outlined icon-type">group</span>
            <div class="user__name ellipsis">{{ user.name }}</div>
            <div
              *ngIf="!!user.direct_to.was_last_online"
              class="user__time"
            >{{ setTime(user.direct_to.was_last_online) }}</div>
            <span
              *ngIf="user.id"
              class="material-icons-outlined icon-delete"
              (click)="deleteChat($event, user)"
            >delete</span>
          </div>

          <div class="user__note" [class.unread]="!!user.unread_messages">
            <div class="user__message" *ngIf="user.last_message.text">{{ user.last_message.text }}</div>
            <ng-container *ngIf="!user.last_message.text && user.last_message.has_files">
              <span class="material-icons image-icon">image</span>
            </ng-container>
            <div class="user__unread">{{ user.unread_messages }}</div>
          </div>
        </div>
      </ng-container>

      <ng-template #groupChat>
        <app-user-avatar
          [name]="user.name"
          [avatar]="user.image"
        ></app-user-avatar>
        <div class="user__card">
          <div class="user__title">
            <span class="material-icons-outlined icon-type">groups</span>
            <div class="user__name ellipsis">{{ user.name }}</div>
            <div
              *ngIf="!!user.last_message.created_at"
              class="user__time"
            >{{ setTime(user.last_message.created_at) }}</div>
            <span
              *ngIf="user.id"
              class="material-icons-outlined icon-delete"
              (click)="deleteChat($event, user)"
            >delete</span>
          </div>

          <div class="user__note" [class.unread]="!!user.unread_messages">
            <div class="user__message group-chat" *ngIf="user.last_message.text">
              <strong>{{ user.last_message.sender.short_name }}:</strong>
              {{ user.last_message.text }}
            </div>
            <ng-container *ngIf="!user.last_message.text && user.last_message.has_files">
              <span class="material-icons image-icon">image</span>
            </ng-container>
            <div class="user__unread">{{ user.unread_messages }}</div>
          </div>
        </div>
      </ng-template>
    </div>

    <div class="loading-users-list">
      <app-loader *ngIf="isLoadingUsersList"></app-loader>
    </div>
  </div>
</div>
