import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { PaginatedResponse } from '../models/paginated-response.model';
import { Message, MessagePayload } from '../models/message.model';
import { MESSAGE_CENTER_URLS } from '../shared/MESSAGE_CENTER_URLS';
import { HttpService } from '@app/core/services';

@Injectable({
  providedIn: 'root'
})
export class ChatService {
  private messageSentThread = new BehaviorSubject<Message>(undefined);

  constructor(
    private http: HttpService
  ) { }

  set setMessageSentThread(message: Message) {
    this.messageSentThread.next(message);
  }

  get getMessageSentThread(): Observable<Message> {
    return this.messageSentThread.asObservable();
  }

  getChatMessages(params?: object): Observable<PaginatedResponse<Message>> {
    return <Observable<PaginatedResponse<Message>>>this.http.GET(MESSAGE_CENTER_URLS.chat_message, params);
  }

  sendMessage(body: MessagePayload): Observable<Message> {
    return <Observable<Message>>this.http.POST(MESSAGE_CENTER_URLS.chat_message, body);
  }

  deleteMessage(messageId: number): Observable<void> {
    return <Observable<void>>this.http.DELETE(`${ MESSAGE_CENTER_URLS.chat_message }${ messageId }/`);
  }

  resendMessage(messageId: number): Observable<void> {
    return <Observable<void>>this.http.POST(`${ MESSAGE_CENTER_URLS.chat_message }${ messageId }/send_message/`, {});
  }

  // getMessageById(messageId: number): Observable<any> {
  //   return <Observable<any>>this.http
  //   .GET(`${ URLS.chat_message }${ messageId }/`)
  //   .pipe(
  //     catchError((error: HttpErrorResponse) => observableThrowError(error))
  //   );
  // }

}
