<div class="paginator-wrapper">
	<div class="selector">
		<button matRipple class="selector-button selector-left"
			[disabled]="pageNumber === 1"
			(click)="decreasePage()">
			<span><i class="fa fa-angle-left"></i></span>
		</button>
		<button matRipple class="selector-button left1"
			*ngIf="paginationController.left1.value"
			(click)="selectPage(paginationController.left1)">
			<span>{{paginationController.left1.value}}</span>
		</button>
		<button matRipple class="selector-button left2"
			*ngIf="paginationController.left2.value"
			(click)="selectPage(paginationController.left2)">
			<span>{{paginationController.left2.value}}</span>
		</button>
		<div class="dots"
			*ngIf="paginationController.leftDots">
			<span>...</span>
		</div>
		<button matRipple class="selector-button center1"
			*ngIf="paginationController.center1.value"
			[ngClass]="{'active': paginationController.center1.active}"
			(click)="selectPage(paginationController.center1)">
			<span>{{paginationController.center1.value}}</span>
		</button>
		<button matRipple class="selector-button center2"
			*ngIf="paginationController.center2.value"
			[ngClass]="{'active': paginationController.center2.active}"
			(click)="selectPage(paginationController.center2)">
			<span>{{paginationController.center2.value}}</span></button>
		<button matRipple class="selector-button center3"
			*ngIf="paginationController.center3.value"
			[ngClass]="{'active': paginationController.center3.active}"
			(click)="selectPage(paginationController.center3)">
			<span>{{paginationController.center3.value}}</span>
		</button>
		<button matRipple class="selector-button center4"
			*ngIf="paginationController.center4.value"
			[ngClass]="{'active': paginationController.center4.active}"
			(click)="selectPage(paginationController.center4)">
			<span>{{paginationController.center4.value}}</span>
		</button>
		<button matRipple class="selector-button center5"
			*ngIf="paginationController.center5.value"
			[ngClass]="{'active': paginationController.center5.active}"
			(click)="selectPage(paginationController.center5)">
			<span>{{paginationController.center5.value}}</span>
		</button>
		<div class="dots"
			*ngIf="paginationController.rightDots">
			<span>...</span>
		</div>
		<button matRipple class="selector-button right1"
			*ngIf="paginationController.right1.value"
			(click)="selectPage(paginationController.right1)">
			<span>{{paginationController.right1.value}}</span>
		</button>
		<button matRipple class="selector-button right2"
			*ngIf="paginationController.right2.value"
			(click)="selectPage(paginationController.right2)">
			<span>{{paginationController.right2.value}}</span>
		</button>
		<button matRipple class="selector-button selector-right"
			[disabled]="pageNumber >= pages"
			(click)="increasePage()">
			<span><i class="fa fa-angle-right"></i></span>
		</button>
	</div>

	<div class="right">
		<div class="per-page-selector" #paginatorSelector>
			<div class="per-page-display" (click)="perPageOptionsOpened = !perPageOptionsOpened">
				<p class="text-center mb0 value">{{perPage}}</p>
				<div class="arrow">
					<img [ngClass]="{'rotated': perPageOptionsOpened}"
						src="/assets/images/arrow-drop-down-24px.svg"
						alt="">
				</div>
			</div>
			<div class="per-page-options" [ngClass]="{'active': perPageOptionsOpened}">
				<div class="per-page-item"
					(click)="onLimitChanged(i.value)"
					[ngClass]="{'active': i.value === perPage}"
					*ngFor="let i of pagesCountOptions">
					<p class="text-center mb0 value">{{i.label}}</p>
				</div>
			</div>
		</div>

		<div class="total">{{ 'paginator.totalPages_title' | translate: {
      from: showingFrom,
      to: showingTo,
      total: total
    } }}</div>
	</div>
</div>
