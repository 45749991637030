import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { NgSelectComponent } from '@ng-select/ng-select';
import { TranslateService } from '@ngx-translate/core';

import { selectNotFoundText, VISIT_STATUS_CHOICES } from '@app/shared/constants';
import { VisitStatus } from '@app/models/visits/visits.model';

@Component({
  selector: 'app-status-cell',
  templateUrl: './status-cell.component.html',
  styleUrls: ['./status-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StatusCellComponent implements OnInit, OnChanges {
  @Input() startDateTime: any;
  @Input() currentStatus: number;
  @Input() isColored: boolean = false;
  @Input() visitId: number;
  @Input() pageVerticalScroll: number;
  @Input() tableHorizontalScroll: number;
  @Output() statusChanged = new EventEmitter<number>();
  @ViewChild('ngSelect') ngSelect: NgSelectComponent;

  readonly customNotFoundText = selectNotFoundText;
  readonly visitsStatuses = VisitStatus;
  status = null;
  list = [];

  constructor(
    private translate: TranslateService,
  ) { }

  ngOnInit(): void {
    this.setList(this.currentStatus);
  }

  setList(status: number): void {
    const visitStatuses = VISIT_STATUS_CHOICES.map(status => {
      return {
        value: status.value,
        label: this.translate.instant(status.label),
        checked: status.checked
      };
    });

    this.list = [];
    const incomingStatus = visitStatuses.find(s => s.value === this.currentStatus);
    this.list.push(incomingStatus);
    this.status = this.currentStatus;

    switch (status) {
      case VisitStatus.available:
      case VisitStatus.offered:
      case VisitStatus.requested:
        visitStatuses.forEach(status => {
          if (
            status.value === VisitStatus.canceled
            || status.value === VisitStatus.missed
          ) {
            this.list.push(status);
          }
        });
        break;
      case VisitStatus.scheduled:
        visitStatuses.forEach(status => {
          if (
            status.value === VisitStatus.enRoute
            || status.value === VisitStatus.inProgress
            || status.value === VisitStatus.completed
            || status.value === VisitStatus.canceled
            || status.value === VisitStatus.missed
          ) {
            this.list.push(status);
          }
        });
        break;
      case VisitStatus.enRoute:
        visitStatuses.forEach(status => {
          if (
            status.value === VisitStatus.scheduled
            || status.value === VisitStatus.inProgress
            || status.value === VisitStatus.completed
            || status.value === VisitStatus.canceled
            || status.value === VisitStatus.missed
          ) {
            this.list.push(status);
          }
        });
        break;
      case VisitStatus.inProgress:
        visitStatuses.forEach(status => {
          if (
            status.value === VisitStatus.enRoute
            || status.value === VisitStatus.completed
            || status.value === VisitStatus.canceled
            || status.value === VisitStatus.missed
          ) {
            this.list.push(status);
          }
        });
        break;
      case VisitStatus.completed:
        visitStatuses.forEach(status => {
          if (
            status.value === VisitStatus.scheduled
            || status.value === VisitStatus.enRoute
            || status.value === VisitStatus.inProgress
            || status.value === VisitStatus.canceled
            || status.value === VisitStatus.missed
          ) {
            this.list.push(status);
          }
        });
        break;
      case VisitStatus.canceled:
        visitStatuses.forEach(status => {
          if (
            status.value === VisitStatus.available
            || status.value === VisitStatus.scheduled
          ) {
            this.list.push(status);
          }
        });
        break;
      case VisitStatus.missed:
        visitStatuses.forEach(status => {
          if (
            status.value === VisitStatus.available
            || status.value === VisitStatus.canceled
          ) {
            this.list.push(status);
          }
        });
        break;
    }

    if (new Date(this.startDateTime) > new Date()) {
      this.list = this.list.filter(status => status.value !== VisitStatus.missed);
    }
  }

  changeStatus(value: number): void {
    this.currentStatus = value;
    this.statusChanged.emit(value);
    this.setList(value);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.currentStatus) {
      if (!changes.currentStatus.firstChange) {
        this.currentStatus = changes.currentStatus.currentValue;
        this.setList(this.currentStatus);
      }
    }
    if (changes.pageVerticalScroll) {
      if (changes.pageVerticalScroll.currentValue && !changes.pageVerticalScroll.firstChange) {
        this.ngSelect.close();
      }
    }
    if (changes.tableHorizontalScroll) {
      if (changes.tableHorizontalScroll.currentValue && !changes.tableHorizontalScroll.firstChange) {
        this.ngSelect.close();
      }
    }
  }

}
