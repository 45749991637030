import { ValidatorFn } from '@angular/forms';

import { megabytesToBytes } from '@app/shared/helper';
import { TranslationData } from '@app/core/services';

const MAX_FILE_SIZE = 5;
export function maxFileSizeValidator(mb = MAX_FILE_SIZE): ValidatorFn {
  return (control) => {
    const isExceedingFileExistent = control.value?.some(({ size }) => size > megabytesToBytes(mb));

    if (control.value && isExceedingFileExistent) {
      return {
        maxFileSize: new TranslationData('commonErrors.file_maxSize', { size: mb }),
      };
    }
    return null;
  };
}
