import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { PaginationParams } from '@app/shared/interfaces/pagination.class';
import { HttpService } from '@app/core/services';
import { URLS } from '@app/shared/urls';
import { VisitDetailsHeader } from '@app/models/patient/visit-details/visit-details.model';
import {
  VisitDetailsDictionary,
  VisitDetailsInfoUpdate,
  VisitDetailsVisitInfo
} from '@app/models/patient/visit-details/visit.model';
import { VisitDetailsSchedule, VisitDetailsScheduleUpdate } from '@app/models/patient/visit-details/schedule.model';
import { VisitDetailBillingUpdate, VisitDetailsBilling } from '@app/models/patient/visit-details/billing.model';
import { VisitDetailPayroll, VisitDetailPayrollUpdate } from '@app/models/patient/visit-details/payroll.model';
import { PaginatedResponse } from '@app/models/paginated-response.model';
import {
  VisitDetailsBlasting,
  VisitDetailsBlastingCreation,
  VisitDetailsNestedBlasting
} from '@app/models/patient/visit-details/blasting.model';
import { ResponseResult, ResponseSuccess } from '@app/models/response.model';
import { VisitHistory } from '@app/models/patient/visit-details/history.model';

@Injectable({
  providedIn: 'root'
})
export class VisitDetailsService {
  private refreshCurrentTab = new BehaviorSubject<boolean>(false);
  private visitSchedule = new BehaviorSubject<VisitDetailsSchedule>(undefined);
  private visitData = new BehaviorSubject<VisitDetailsVisitInfo>(undefined);

  constructor(
    private http: HttpService
  ) {
  }

  get getRefreshCurrentTab(): Observable<boolean> {
    return this.refreshCurrentTab.asObservable();
  }

  set setRefreshCurrentTab(value: boolean) {
    this.refreshCurrentTab.next(value);
  }

  get getUpdatedScheduleTab(): Observable<VisitDetailsSchedule> {
    return this.visitSchedule.asObservable();
  }

  set updateScheduleTab(value: VisitDetailsSchedule) {
    this.visitSchedule.next(value);
  }

  get getUpdatedVisitTab(): Observable<VisitDetailsVisitInfo> {
    return this.visitData.asObservable();
  }

  set updateVisitTab(value: VisitDetailsVisitInfo) {
    this.visitData.next(value);
  }

  getVisitHeader(id: number): Observable<VisitDetailsHeader> {
    return this.http.GET<VisitDetailsHeader>(`${ URLS.visit_detailed_info }${ id }/header_info/`);
  }

  // schedule tab
  getVisitSchedule(id: number): Observable<VisitDetailsSchedule> {
    return <Observable<VisitDetailsSchedule>>this.http
      .GET(`${ URLS.visit_detailed_info }${ id }/schedule/`);
  }

  updateVisitSchedule(visitId: number, payload: VisitDetailsScheduleUpdate): Observable<VisitDetailsSchedule> {
    return <Observable<VisitDetailsSchedule>>this.http
      .PUT(`${ URLS.visit_detailed_info }${ visitId }/schedule/`, payload);
  }

  // visit tab
  getVisitInfo(visitId: number): Observable<VisitDetailsVisitInfo> {
    return <Observable<VisitDetailsVisitInfo>>this.http
      .GET(`${ URLS.visit_detailed_info }${ visitId }/visit_info/`);
  }

  getDictionary(key: string): Observable<VisitDetailsDictionary> {
    return <Observable<VisitDetailsDictionary>>this.http
      .GET(`${ URLS.dictionary }?dictionary=${ key }`);
  }

  updateVisitInfo(visitId: number, payload: VisitDetailsInfoUpdate): Observable<VisitDetailsVisitInfo> {
    return <Observable<VisitDetailsVisitInfo>>this.http
      .PUT(`${ URLS.visit_detailed_info }${ visitId }/visit_info/`, payload);
  }

  // billing tab
  getBilling(visitId: number): Observable<VisitDetailsBilling> {
    return <Observable<VisitDetailsBilling>>this.http
      .GET(`${ URLS.visit_detailed_info }${ visitId }/bill_info/`);
  }

  updateBilling(visitId: number, payload: VisitDetailBillingUpdate): Observable<VisitDetailsBilling> {
    return <Observable<VisitDetailsBilling>>this.http
      .PUT(`${ URLS.visit_detailed_info }${ visitId }/bill_info/`, payload);
  }

  // payroll tab
  getPayroll(visitId: number): Observable<VisitDetailPayroll> {
    return <Observable<VisitDetailPayroll>>this.http
      .GET(`${ URLS.visit_detailed_info }${ visitId }/payroll_info/`);

  }

  updatePayroll(visitId: number, payload: VisitDetailPayrollUpdate): Observable<VisitDetailPayroll> {
    return this.http.PUT<VisitDetailPayroll>(`${ URLS.visit_detailed_info }${ visitId }/payroll_info/`, payload);
  }

  // blasting tab
  getVisitBlasting(queryParams: PaginationParams & { visit?: number }): Observable<PaginatedResponse<VisitDetailsBlasting>> {
    return this.http.GET<PaginatedResponse<VisitDetailsBlasting>>(URLS.visit_blasting, queryParams);
  }

  createVisitBlasting(payload: VisitDetailsBlastingCreation): Observable<ResponseResult> {
    return this.http.POST<ResponseResult>(URLS.visit_blasting, payload);
  }

  getVisitBlastingNestedOffers(blastId: number, params?: object): Observable<VisitDetailsNestedBlasting[]> {
    return this.http.GET<VisitDetailsNestedBlasting[]>(`${ URLS.visit_blasting }${ blastId }/offers/`, params);
  }

  changeVisitDetailsBlastingStatus(visitId: number, payload: { status: number }): Observable<{ status: number, status_value: string }> {
    return this.http.PUT(`${ URLS.visit_blasting }${ visitId }/`, payload);
  }

  visitApproveOffer(visitId: number, payload: { assign: number }): Observable<ResponseSuccess> {
    return <Observable<ResponseSuccess>>this.http
      .POST(`${ URLS.visit_blasting }${ visitId }/approve_offer/`, payload);
  }

  visitRejectOffer(visitId: number, payload: { assign: number }): Observable<ResponseSuccess> {
    return <Observable<ResponseSuccess>>this.http
      .POST(`${ URLS.visit_blasting }${ visitId }/reject_offer/`, payload);
  }

  // history tab
  getVisitHistory(visitId: number, query: string): Observable<PaginatedResponse<VisitHistory>> {
    return <Observable<PaginatedResponse<VisitHistory>>>this.http
      .GET(`${ URLS.history }visit/${ visitId }/?${ query }`);
  }

  getVisitBlastingAttentions(visitId: number): Observable<{ count: number }> {
    return this.http.GET(`${ URLS.patients_visit }${ visitId }/blasting_attentions/`);
  }
}
