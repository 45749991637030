/* tslint:disable */
import { Phone } from "@app/models/phone.model";
import { FullAddressIfc } from '@app/shared/interfaces/full-address.ifc';

export class EmergencyContactsInfo {
  id: number;
  name: string;
  relationship: number;
  relationship_value: string;
  phone_numbers: Phone[];
  address_data: FullAddressIfc;
  address_detail: FullAddressIfc;
  lives_with_patient: boolean;
  has_keys: boolean;

  constructor(newEci: any) {
    this.id = newEci.id;
    this.name = newEci.name || '';
    this.relationship = newEci.relationship;
    this.relationship_value = newEci.relationship_value || '';
    this.address_data = new FullAddressIfc(newEci.address_detail || {});
    this.address_detail = new FullAddressIfc(newEci.address_detail || {});
    this.lives_with_patient = newEci.lives_with_patient || false;
    this.has_keys = newEci.has_keys || false;
    this.phone_numbers = newEci.phone_numbers;
  }
}
