import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { Language } from '@app/models/language.model';

const languages = [
  { name: 'languages.en_us', langCode: 'en-us' },
  { name: 'languages.en_gb', langCode: 'en-gb' },
  { name: 'languages.es', langCode: 'es' }
];

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  private readonly STORAGE_KEY = 'hc.language';
  private readonly DEFAULT_LANGUAGE_CODE = 'en-us';

  private isInited: boolean = false;
  private languagesList = languages || [];
  private defaultLang: Language = { langCode: this.DEFAULT_LANGUAGE_CODE, name: '' };
  currentLang: Language = new Language();

  constructor(
    private translate: TranslateService
  ) { }

  init(): void {
    if (this.isInited) {
      return;
    }
    this.isInited = true;
    this.initLanguagesList();
    this.setDefaultLang();
    this.getSavedLanguage();
  }

  switchLanguage(lang: Language): void {
    if (this.currentLang.langCode === lang.langCode) {
      return;
    }
    this.translate.use(lang.langCode);
    this.currentLang = lang;
    localStorage.setItem(this.STORAGE_KEY, JSON.stringify(this.currentLang));
  }

  getLanguages(): Language[] {
    return languages;
  }

  private setDefaultLang(): void {
    this.defaultLang = this.languagesList.find(lang => lang.langCode === this.DEFAULT_LANGUAGE_CODE);
    this.translate.setDefaultLang(this.defaultLang.langCode);
  }

  private initLanguagesList(): void {
    const languagesList = this.languagesList.map(lang => lang.langCode);

    this.translate.addLangs(languagesList);
  }

  private getSavedLanguage(): void {
    let languageKey = JSON.parse(localStorage.getItem(this.STORAGE_KEY));
    if (!languageKey || !languageKey.langCode) {
      const browserLang = this.translate.getBrowserCultureLang().toLowerCase();
      languageKey = this.languagesList.find(language => language.langCode === browserLang) || this.defaultLang;
    }
    if (languageKey) {
      this.switchLanguage(languageKey);
    }
  }
}
