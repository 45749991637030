import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'defaultIfEmpty'})
export class DefaultIfEmpty implements PipeTransform {

  transform(value: any, args?: any): any {
    return value || '&ndash;';
  }

}
