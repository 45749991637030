import { ControlValueAccessor } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';

const noop = () => {};


export class NgModelBase<T> implements ControlValueAccessor {

  // The internal data model
  innerValue: T;
  innerDisabled$ = new BehaviorSubject<boolean>(false);
  innerPositiveValuesCounter = 0;

  // Placeholders for the callbacks which are later provided
  // by the Control Value Accessor
  protected onTouchedCallback: () => void = noop;
  private onChangeCallback: (_: any) => void = noop;

  // get accessor
  get value(): any {
    return this.innerValue;
  }

  // set accessor including call the onchange callback
  set value(v: any) {
    if (v !== this.innerValue) {
      this.innerValue = v;
      this.onChangeCallback(v);
    }
  }

  // Set touched on blur
  onBlur() {
    this.onTouchedCallback();
  }

  // From ControlValueAccessor interface
  writeValue(value: any) {
    if (value !== this.innerValue) {
      this.innerValue = value;
      this.innerPositiveValuesCounter = 0;

      if (this.value && this.value instanceof Array) {
        this.value.forEach((el: any) => {
          if (el.value === true) {
            this.innerPositiveValuesCounter = this.innerPositiveValuesCounter + 1;
          }
        });
      }
    }
  }

  // From ControlValueAccessor interface
  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  // From ControlValueAccessor interface
  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.innerDisabled$.next(isDisabled);
  }

}
