import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { HttpErrorResponse } from '@angular/common/http';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { finalize } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';

import { VisitsService } from '@app/core/services/visits/visits.service';
import { EditVisitData } from '@app/models/visits/edit-visit-data.model';
import { VisitDetailsComponent } from '@app/pages/visit-details/visit-details.component';
import { FormService, VisitDetailsService } from '@app/core/services';
import { ResponseResult } from '@app/models/response.model';

@UntilDestroy()
@Component({
  selector: 'app-visit-notifications',
  templateUrl: './visit-notifications.component.html',
  styleUrls: ['./visit-notifications.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VisitNotificationsComponent implements OnInit {

  @Input() visits: EditVisitData[] = [];

  constructor(
    private dialog: MatDialog,
    private visitsService: VisitsService,
    private visitDetailsService: VisitDetailsService,
    private toastr: ToastrService,
    private formService: FormService,
    private translate: TranslateService,
    private cd: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.visitsService.recentlyCreatedVisits
      .pipe(untilDestroyed(this))
      .subscribe((value: EditVisitData) => {
        this.visits.push(value);
        this.visits = this.visits.sort((a: EditVisitData, b: EditVisitData) => a.id > b.id ? -1 : 1);
        if (this.visits.length > 5) {
          this.visits = this.visits.filter((visit: EditVisitData, index: number) => index !== this.visits.length - 1);
        }
        this.cd.detectChanges();
    });
  }

  openVisit(visitId: number): void {
    this.visits = this.visits.filter(visit => visit.id !== visitId);

    this.dialog.open(VisitDetailsComponent, {
      width: '740px',
      autoFocus: false,
      data: { visitId }
    });
  }

  blastVisit(visit: EditVisitData): void {
    this.visitDetailsService.createVisitBlasting({ visit: visit.id, caregivers: [] })
      .pipe(
        finalize(() => this.cd.detectChanges()),
        untilDestroyed(this),
      ).subscribe((response: ResponseResult) => {
        this.visits = this.visits.filter(i => i.id !== visit.id);
        this.toastr.success(
          this.translate.instant('visitNotifications.toastr_visitWasBlasted', { visitId: visit.number }),
          this.translate.instant('commonNotifications.success'),
          { positionClass: 'toast-top-center' }
        );
      }, (error: HttpErrorResponse) => this.formService.nonFieldErrors(error));
  }

  getVisitTime(startTime: string, endTime: string): string {
    const start = moment(startTime);
    const end = moment(endTime);

    return `${ start.format('hh:mm A') } - ${ end.format('hh:mm A') }`;
  }

  close(visitId: number): void {
    this.visits = this.visits.filter(visit => visit.id !== visitId);
  }
}
