import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { HttpService } from '@app/core/services';
import { URLS } from '@app/shared/urls';
import { CaregiverPreferences, CaregiverPreferencesUpdate, PinCode } from '@app/models/users/caregiver.model';
import { PaginatedResponse } from '@app/models/paginated-response.model';
import { CaregiverRate } from '@app/models/users/caregiver-rates.model';
import { CaregiverExpense, CaregiverExpenseCreateUpdate } from '@app/models/users/caregiver-expense.model';
import { CaregiverEducation, CaregiverEducationCreateUpdate } from '@app/models/users/caregiver-education.model';
import { CaregiverBatch, CaregiverPaycheck, CaregiverPaycheckDownloadParams, CaregiverPaycheckDownloadResponse } from '@app/models/users/caregiver-paycheck.model';
import {
  AvailabilityExclusion,
  AvailabilityExclusionCreateUpdate,
  CaregiverAvailability,
  CaregiverAvailabilityCalendar,
  CaregiverAvailabilityUpdate
} from '@app/models/users/caregiver-availability.model';
import {
  CaregiverPayrollTemplate,
  CaregiverPayrollTemplateCreate,
  CaregiverPayrollTemplateCreateResponse,
  CaregiverPayrollTemplateDetail,
  CaregiverPayrollTemplateUpdate,
  CaregiverPayrollTemplateUpdateResponse
} from '@app/models/users/caregiver-payroll.model';
import { CaregiverCalendarVisit } from "@app/models/users/caregiver-visit.model";
import {
  Certificate,
  ComplianceHistoryGroup,
  GeneralCompliance,
  GetComplianceFieldHistory, HistoryChangeEntry, InstitutionDictionary, NewCertificate, NewGeneralCompliance
} from '@app/models/users/caregiver-compliance.model';

@Injectable({
  providedIn: 'root'
})
export class CaregiverService {

  constructor(
    private http: HttpService
  ) {}

  // PREFERENCES
  getPreferences(id: number): Observable<CaregiverPreferences> {
    return this.http.GET<CaregiverPreferences>(`${ URLS.caregiver_preferences }${ id }/`);
  }

  updatePreferences(id: number, data: CaregiverPreferencesUpdate): Observable<CaregiverPreferences> {
    return this.http.PUT<CaregiverPreferences>(`${ URLS.caregiver_preferences }${ id }/`, data);
  }

  // RATES
  getRates(id: number, query: string): Observable<PaginatedResponse<CaregiverRate>> {
    return this.http.GET<PaginatedResponse<CaregiverRate>>(`${ URLS.profile }${ id }/caregiver_rates/?${ query }`);
  }

  // EXPENSES
  getExpenses(query: string): Observable<PaginatedResponse<CaregiverExpense>> {
    return this.http.GET<PaginatedResponse<CaregiverExpense>>(`${ URLS.caregiver_expense }?${ query }`);
  }

  createExpense(data: CaregiverExpenseCreateUpdate): Observable<CaregiverExpense> {
    return this.http.POST<CaregiverExpense>(`${ URLS.caregiver_expense }`, data);
  }

  editExpense(id: string, data: CaregiverExpenseCreateUpdate): Observable<CaregiverExpense> {
    return this.http.PUT<CaregiverExpense>(`${ URLS.caregiver_expense }${ id }/`, data);
  }

  deleteExpense(id: string): Observable<void> {
    return this.http.DELETE<void>(`${ URLS.caregiver_expense }${ id }/`);
  }

  // EDUCATIONS
  getEducations(query: string): Observable<PaginatedResponse<CaregiverEducation>> {
    return this.http.GET<PaginatedResponse<CaregiverEducation>>(`${ URLS.caregiver_education }?${ query }`);
  }

  createEducation(data: CaregiverEducationCreateUpdate): Observable<CaregiverEducation> {
    return this.http.POST<CaregiverEducation>(`${ URLS.caregiver_education }`, data);
  }

  editEducation(id: string, data: CaregiverEducationCreateUpdate): Observable<CaregiverEducation> {
    return this.http.PUT<CaregiverEducation>(`${ URLS.caregiver_education }${ id }/`, data);
  }

  deleteEducation(id: string): Observable<void> {
    return this.http.DELETE<void>(`${ URLS.caregiver_education }${ id }/`);
  }

  // PAYCHECK
  getBatches(id: number): Observable<CaregiverBatch[]> {
    return this.http.GET<CaregiverBatch[]>(`${ URLS.caregiver_invoice_batch }caregiver_batches/?user_id=${ id }`);
  }

  getPaycheck(id: number, query: string): Observable<CaregiverPaycheck> {
    return this.http.GET<CaregiverPaycheck>(`${ URLS.profile }${ id }/caregiver_pay_check/?${ query }`);
  }

  downloadPaycheck(params: CaregiverPaycheckDownloadParams): Observable<CaregiverPaycheckDownloadResponse> {
    return this.http.GET<CaregiverPaycheckDownloadResponse>(`${ URLS.paycheck }download/`, params);
  }

  // AVAILABILITY
  getAvailability(id: number, query: string): Observable<CaregiverAvailability> {
    return this.http.GET<CaregiverAvailability>(`${ URLS.profile }${ id }/availability/?${ query }`);
  }

  editAvailability(id: number, data: CaregiverAvailabilityUpdate): Observable<CaregiverAvailability> {
    return this.http.PUT<CaregiverAvailability>(`${ URLS.profile }${ id }/availability/?utc_offset=${ new Date().getTimezoneOffset() }`, data);
  }

  // CALENDAR
  getCalendar(id: number, query: string): Observable<CaregiverAvailabilityCalendar[]> {
    return this.http.GET<CaregiverAvailabilityCalendar[]>(`${ URLS.profile }${ id }/availability_calendar/?${ query }`);
  }

  // EXCLUSIONS
  getAvailabilityExclusions(query: string): Observable<PaginatedResponse<AvailabilityExclusion>> {
    return this.http.GET<PaginatedResponse<AvailabilityExclusion>>(`${ URLS.availability_exclusions }?${ query }`);
  }

  createExclusion(data: AvailabilityExclusionCreateUpdate): Observable<AvailabilityExclusion> {
    return this.http.POST<AvailabilityExclusion>(`${ URLS.availability_exclusions }?utc_offset=${ new Date().getTimezoneOffset() }`, data);
  }

  editExclusion(id: number, data: AvailabilityExclusionCreateUpdate): Observable<AvailabilityExclusion> {
    return this.http.PUT<AvailabilityExclusion>(`${ URLS.availability_exclusions }${ id }/?utc_offset=${ new Date().getTimezoneOffset() }`, data);
  }

  deleteAvailabilityExclusion(id: number): Observable<void> {
    return this.http.DELETE<void>(`${ URLS.availability_exclusions }${ id }/`);
  }

  // PAYROLL
  getPayrollTemplates(query: string): Observable<PaginatedResponse<CaregiverPayrollTemplate>> {
    return this.http.GET<PaginatedResponse<CaregiverPayrollTemplate>>(`${ URLS.caregiver_payroll }?${ query }`);
  }

  getPayrollTemplateById(id: number): Observable<CaregiverPayrollTemplateDetail> {
    return this.http.GET<CaregiverPayrollTemplateDetail>(`${ URLS.caregiver_payroll }${ id }/`);
  }

  createPayrollTemplate(data: CaregiverPayrollTemplateCreate): Observable<CaregiverPayrollTemplateCreateResponse> {
    return this.http.POST<CaregiverPayrollTemplateCreateResponse>(`${ URLS.caregiver_payroll }`, data);
  }

  editPayrollTemplate(id: number, data: CaregiverPayrollTemplateUpdate): Observable<CaregiverPayrollTemplateUpdateResponse> {
    return this.http.PUT(`${ URLS.caregiver_payroll }${ id }/?utc_offset=${ new Date().getTimezoneOffset() }`, data);
  }

  deletePayrollTemplate(id: number): Observable<void> {
    return this.http.DELETE<void>(`${ URLS.caregiver_payroll }${ id }/`);
  }

  generatePinCode(userId: number): Observable<PinCode> {
    return this.http.POST<PinCode>(`${ URLS.profile }${ userId }/generate_caregiver_pin_code/`, {});
  }

  getCalendarVisits(userId: number, params: { start_date: string, end_date: string }): Observable<CaregiverCalendarVisit[]> {
    return this.http.GET<CaregiverCalendarVisit[]>(`${ URLS.profile }${ userId }/caregiver_calendar/`, params);
  }

  getGeneralCompliance(id: number): Observable<GeneralCompliance> {
    return this.http.GET<GeneralCompliance>(`${ URLS.profile }${ id }/compliance/general/`);
  }

  putGeneralCompliance(id: number, data: NewGeneralCompliance): Observable<GeneralCompliance> {
    return this.http.PUT<GeneralCompliance>(`${ URLS.profile }${ id }/compliance/general/`, data);
  }

  createCertificate(data: NewCertificate): Observable<Certificate> {
    return this.http.POST<Certificate>(`${ URLS.training_school_info }`, data);
  }

  deleteCertificate(id: number): Observable<void> {
    return this.http.DELETE(`${ URLS.training_school_info }${ id }/`);
  }

  getEduInstitutions(): Observable<InstitutionDictionary[]> {
    return this.http.GET<InstitutionDictionary[]>(`${ URLS.dictionary }`, {
      dictionary: 'training_school'
    });
  }

  getGroupHistory({ fields, id, groupName = ComplianceHistoryGroup.Compliance }: GetComplianceFieldHistory): Observable<PaginatedResponse<HistoryChangeEntry[]>> {
    return this.http.GET<PaginatedResponse<HistoryChangeEntry[]>>(`${ URLS.history }${ groupName }/${ id }/`, {
      fields: fields.toString()
    });
  }
}
