import { CurrencyPipe } from '@angular/common';
import { AbstractControl } from '@angular/forms';

export const formatCurrency = (value: any, currencyPipe: CurrencyPipe) => {
    const temp = `${value}`.replace(/\,/g, '');
    return currencyPipe.transform(temp)?.replace('$', '');
};

export const transformCurrency = (control: AbstractControl, currencyPipe: CurrencyPipe) => {
    const value = control.value;
    control.setValue(
      formatCurrency(String(value).replace(/[^\d\.\,]/g, ''), currencyPipe),
      { emitEvent: false }
    );
};
