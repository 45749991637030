import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  forwardRef,
  Input,
  ViewChild,
  ElementRef
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { pad } from '@app/shared/helper';

@Component({
  selector: 'app-time-duration-field',
  templateUrl: './time-duration-field.component.html',
  styleUrls: ['./time-duration-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TimeDurationFieldComponent),
      multi: true,
    }
  ]
})
export class TimeDurationFieldComponent implements ControlValueAccessor {
  @Input() disabled = false;
  @Input() maxHours: number = Infinity;
  @Input() allowMinutesWhenMaxHours = false;

  @ViewChild('hoursInput') hoursInput: ElementRef<HTMLInputElement>;
  @ViewChild('minutesInput') minutesInput: ElementRef<HTMLInputElement>;

  hours: string;
  minutes: string;

  private onChangeCallback: any = () => null;
  private onTouchedCallback: any = () => null;

  constructor(private cdr: ChangeDetectorRef) {
  }

  onHoursChanged(hours: string): void {
    const normalizedHours = this.getNormalizedHours(Number(hours));
    this.hours = normalizedHours;
    this.hoursInput.nativeElement.value = normalizedHours;
    this.minutes = this.getNormalizedMinutes(Number(this.minutes));
    this.onChange();
    this.cdr.markForCheck();
  }

  onMinutesChanged(minutes: string): void {
    const normalizedMinutes = this.getNormalizedMinutes(Number(minutes));
    this.minutes = normalizedMinutes;
    this.minutesInput.nativeElement.value = normalizedMinutes;
    this.onChange();
    this.cdr.markForCheck();
  }

  onInputBlur(): void {
    this.onTouchedCallback();
    this.cdr.markForCheck();
  }

  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
    this.cdr.markForCheck();
  }

  writeValue(minutes: number): void {
    this.hours = String(Math.floor(minutes / 60));
    this.minutes = pad(minutes % 60);
    this.cdr.markForCheck();
  }

  private getNormalizedHours(hours: number): string {
    if (hours > this.maxHours) {
      hours = this.maxHours;
    }

    return hours > 9 ? String(hours) : pad(hours);
  }

  private getNormalizedMinutes(minutes: number): string {
    const maxMinutes = (!this.allowMinutesWhenMaxHours && Number(this.hours) === this.maxHours) ? 0 : 59;
    minutes = Number(minutes) > maxMinutes ? maxMinutes : minutes;

    return minutes > 9 ? String(minutes) : pad(minutes);
  }

  private onChange(): void {
    this.onChangeCallback((Number(this.hours || 0) * 60) + Number(this.minutes || 0));
  }
}
