<div
  class="field"
  [ngClass]="{'disabled': isDisabled, 'active': active}"
>
  <fieldset
    class="field-label"
    [ngClass]="{
      'label-top': labelTop,
      'invalid': invalid,
      'label-hidden': labelHidden
    }"
  >
    <legend [style.width.px]="legendWidth"></legend>
    <div class="error-message"><span>{{errorMessage}}</span></div>
  </fieldset>
  <div class="field-text">
    <textarea
        #input
        [maxlength]="maxlength"
        [style.height]="height"
        [(ngModel)]="value"
        [placeholder]="placeholder"
        [ngClass]="{'text-uppercase': isUppercase}"
        (focus)="focusInput()"
        (focusout)="focusOutInput($event)"
        [disabled]="isDisabled"></textarea>
  </div>
</div>
