import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { HttpService } from '@app/core/services';
import { URLS } from '@app/shared/urls';
import { PaginatedResponse } from '@app/models/paginated-response.model';
import { Office } from '@app/models/settings/offices/office.model';
import { BillingSetup } from '@app/models/setup/setup.model';
import { ResponseSuccess } from '@app/models/response.model';
import { EdiPartner, EdiPartnerParams, EdiProvider, EdiProviderParams } from '@app/models/settings/billing/edi-provider.model';

@Injectable({
  providedIn: 'root'
})
export class BillingService {

  constructor(
    private http: HttpService
  ) { }

  getOfficesList(params: object): Observable<PaginatedResponse<Office>> {
    return this.http.GET<PaginatedResponse<Office>>(`${ URLS.office }`, params);
  }

  getBillingSetup(): Observable<BillingSetup> {
    return this.http.GET<BillingSetup>(URLS.billing_setup);
  }

  updateBillingSetup(payload: BillingSetup): Observable<ResponseSuccess> {
    return this.http.PUT<ResponseSuccess>(URLS.billing_setup, payload);
  }

  getEdiProviders(params: EdiProviderParams): Observable<EdiProvider[]> {
    return this.http.GET<EdiProvider[]>(`${ URLS.edi_provider }`, params);
  }

  createEdiProvider(data: EdiProvider): Observable<EdiProvider> {
    return this.http.POST<EdiProvider>(`${ URLS.edi_provider }`, data);
  }

  editEdiProvider(id: number, data: EdiProvider): Observable<EdiProvider> {
    return this.http.PUT<EdiProvider>(`${ URLS.edi_provider }${ id }/`, data);
  }

  deleteEdiProvider(id: number): Observable<void> {
    return this.http.DELETE<void>(`${ URLS.edi_provider }${ id }/`);
  }

  getEdiPartners(params: EdiPartnerParams): Observable<EdiPartner[]> {
    return this.http.GET<EdiPartner[]>(`${ URLS.payer_edi_partner }`, params);
  }
}
