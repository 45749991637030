import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { ENTER } from '@angular/cdk/keycodes';
import { FormControl } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'app-select-chip-input',
  templateUrl: './select-chip-input.component.html',
  styleUrls: ['./select-chip-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectChipInputComponent implements OnInit {
  separatorKeysCodes: number[] = [ENTER];
  inputCtrl = new FormControl();
  filteredChips: Observable<string[]>;

  @Input() selectedChips: string[] = [];
  @Input() allChips: string[];
  @Input() isError: boolean = false;
  @Output() selectionChanged: EventEmitter<any> = new EventEmitter();

  @ViewChild('chipInput') chipInput: ElementRef<HTMLInputElement>;
  @ViewChild(MatAutocompleteTrigger) trigger;

  constructor(
  ) {
    this.filteredChips = this.inputCtrl.valueChanges.pipe(
      startWith(<string>null),
      map((chip: string | null) =>
        chip
          ? this.getUniqueList(this.filter(chip))
          : this.getUniqueList(this.allChips.slice())
      )
    );
  }

  getUniqueList(chipList: string[]): string[] {
    return chipList.filter(chip => this.selectedChips.indexOf(chip) === -1);
  }

  ngOnInit(): void {
  }

  onClick(): void {
    this.trigger._onChange('');
    this.trigger.openPanel();
  }

  onBlur(): void {
    this.chipInput.nativeElement.value = '';
  }

  add(event: MatChipInputEvent, input): void {
    const value = (event.value || '').trim();

    // Add a chip
    if ((value || '').trim()) {
      const filterList = this.getUniqueList(this.allChips);
      const index = filterList.indexOf(event.value);
      if (index > -1) {
        this.selectedChips.push(value.trim());
      }
    }

    // Clear the input value
    input.value = '';

    this.inputCtrl.setValue(null);
  }

  remove(chip: string): void {
    const index = this.selectedChips.indexOf(chip);

    if (index >= 0) {
      this.selectedChips.splice(index, 1);
      this.selectionChanged.emit(this.selectedChips);
    }
    this.inputCtrl.updateValueAndValidity();
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.selectedChips.push(event.option.viewValue);
    this.chipInput.nativeElement.value = '';
    this.inputCtrl.setValue(null);
    this.selectionChanged.emit(this.selectedChips);
  }

  private filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.allChips.filter(chip => chip.toLowerCase().includes(filterValue));
  }

}
