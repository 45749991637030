import { Injectable } from '@angular/core';
import { Observable, throwError as observableThrowError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { HttpService } from './http/http.service';
import { URLS } from '@app/shared/urls';
import { Vacancy } from '@app/models/hr/vacancy.model';

@Injectable({
  providedIn: 'root'
})
export class VacanciesService {

  constructor(
    private http: HttpService
  ) { }

  addVacancy(data: Vacancy): Observable<Vacancy> {
    return <Observable<Vacancy>>this.http
    .POST(`${ URLS.vacancy }`, data)
    .pipe(
      catchError((error: any) => observableThrowError(error))
    );
  }

  deleteVacancy(id: number): Observable<any> {
    return <Observable<any>>this.http
    .DELETE(`${ URLS.vacancy }${ id }/`)
    .pipe(
      catchError((error: any) => observableThrowError(error))
    );
  }

  editVacancy(vacancyId: number, vacancy: Vacancy): Observable<Vacancy> {
    return <Observable<Vacancy>>this.http
    .PUT(`${ URLS.vacancy }${ vacancyId }/`, vacancy)
    .pipe(
      catchError((error: any) => observableThrowError(error))
    );
  }

  getVacancyById(url: string): Observable<Vacancy> {
    return <Observable<Vacancy>>this.http
    .GET(url)
    .pipe(
      catchError((error: any) => observableThrowError(error))
    );
  }

}
