import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DispatchScreenService, FormService, NotificationsService, VisitDetailsService } from '@app/core/services';
import { getWsData } from '@app/core/services/websocket/rxjs-ws-operators';
import { WsConnectionModule, WsEvent } from '@app/core/services/websocket/ws.models';
import { VisitDetailsHeader } from '@app/models/patient/visit-details/visit-details.model';
import { Router } from '@angular/router';

import { menuTabsName } from '@app/shared/constants';
import { ConfirmationDialogComponent } from '@app/shared/popups/confirmation-dialog/confirmation-dialog.component';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { finalize } from 'rxjs/operators';
import { ModuleSubscription, RealtimeService } from '@app/core/services/websocket/realtime.service';
import { VisitDetailsTab, VisitDetailsTabName, VisitTabFormComponent } from '@app/pages/visit-details/models';
import { VisitStatus } from '@app/models/visits/visits.model';

export const VISIT_DETAIL_DIALOG_WIDTH = '740px';

@UntilDestroy()
@Component({
  selector: 'app-visit-details',
  templateUrl: './visit-details.component.html',
  styleUrls: ['./visit-details.component.scss']
})
export class VisitDetailsComponent implements OnInit, OnDestroy {
  @ViewChild('visitTabFormComponent') visitTabFormComponent: VisitTabFormComponent | undefined;

  readonly menuTabs = menuTabsName;
  readonly TabName = VisitDetailsTabName;
  readonly tabs: VisitDetailsTab[] = [
    { name: VisitDetailsTabName.visit, icon: 'event' },
    { name: VisitDetailsTabName.schedule, icon: 'calendar_month' },
    { name: VisitDetailsTabName.billing, icon: 'monetization_on' },
    { name: VisitDetailsTabName.payroll, icon: 'payments' },
    { name: VisitDetailsTabName.blasting, icon: 'assignment' },
    { name: VisitDetailsTabName.history, icon: 'history' }
  ];

  currentTab: VisitDetailsTabName = this.tabs[0].name;
  visit: VisitDetailsHeader;
  isLoading: boolean;

  private realtimeSubscription: ModuleSubscription;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { visitId: number },
    private dialogRef: MatDialogRef<VisitDetailsComponent>,
    private visitDetailsService: VisitDetailsService,
    private dispatchScreenService: DispatchScreenService,
    private formService: FormService,
    private router: Router,
    private dialog: MatDialog,
    private realtimeService: RealtimeService,
    private notificationsService: NotificationsService,
  ) {
  }

  ngOnInit(): void {
    this.initWebsocket();
    this.loadVisitDetailsHeader();
    this.subscribeOnDialogClose();
  }

  private initWebsocket(): void {
    this.realtimeSubscription = this.realtimeService.subscribeToModule({
      module: WsConnectionModule.visit_details,
      module_pk: this.data.visitId
    });

    this.realtimeSubscription.moduleMessages$.pipe(getWsData())
      .subscribe((data) => {
        const payload = data.payload;
        switch (data.event) {
          case WsEvent.dvi_header_info:
            this.visit = payload;
            break;
          case WsEvent.dvi_schedule_tab:
            this.visitDetailsService.updateScheduleTab = payload;
            break;
          case WsEvent.dvi_visit_tab:
            this.visitDetailsService.updateVisitTab = payload;
            break;
          case WsEvent.visit_blasting_attentions:
            this.updateBlastingAttentions(payload.count);
            break;
        }
      });
  }

  private loadVisitDetailsHeader(): void {
    this.isLoading = true;
    this.visitDetailsService.getVisitHeader(this.data.visitId)
      .pipe(finalize(() => this.isLoading = false), untilDestroyed(this))
      .subscribe((visit: VisitDetailsHeader) => {
        this.visit = visit;
        this.updateBlastingAttentions(visit.blasting_attentions_count);
      }, error => this.notificationsService.showError(error));
  }

  private subscribeOnDialogClose(): void {
    const close = this.dialogRef.close.bind(this.dialogRef);

    this.dialogRef.close = () => this.doSafeAction(close);
  }

  private updateBlastingAttentions(count: number): void {
    const blastingTabIndex = this.tabs.findIndex(i => i.name === VisitDetailsTabName.blasting);
    this.tabs[blastingTabIndex].attentions = count;
  }

  setNewStatus(status: VisitStatus) {
    this.visit.status = status;
    this.dispatchScreenService.changeStatus(this.data.visitId, { status })
      .subscribe((response) => {
          this.visitDetailsService.setRefreshCurrentTab = true;
          this.realtimeService.emitInternalEvent({ event: WsEvent.fe_internal_visit_status_update, payload: response });
        },
        (error: HttpErrorResponse) => {
          this.formService.nonFieldErrors(error);
        });
  }

  changeTab(tabName: VisitDetailsTabName): void {
    if (tabName !== this.currentTab) {
      this.doSafeAction(() => this.currentTab = tabName);
    }
  }

  private doSafeAction(action: () => void): void {
    const hasUnsavedChanges = this.visitTabFormComponent?.form.dirty;
    if (!hasUnsavedChanges) {
      action();
      return;
    }

    this.dialog.open(ConfirmationDialogComponent, {
      width: ConfirmationDialogComponent.DefaultDialogWidth,
      data: {
        text: 'visitDetails.saveChangesConfirmation',
        buttonLabel: 'buttons.save',
        buttonClass: 'app-button_blue'
      }
    }).afterClosed().pipe(untilDestroyed(this))
      .subscribe((save) => save ? this.visitTabFormComponent.save(action) : action());
  }

  tabHasAttentions(tab: VisitDetailsTab): boolean {
    return tab.attentions != null && tab.attentions > 0;
  }

  openProfilePage(page: string, userId: number, params): void {
    const url = this.router.createUrlTree(
      [`/${ page }/${ userId }/edit`],
      { queryParams: params });
    window.open(url.toString(), '_blank');
  }

  close(): void {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    this.realtimeSubscription.unsubscribe();
  }
}
