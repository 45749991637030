export interface Diagnosis {
  id: number;
  patient: number;
  diagnosis: number;
  diagnosis_detail: DiagnoseDetail;
  date_type: DiagnoseDateType;
  date_type_value: string;
  start_date: string;
  end_date: string;
  identified: DiagnoseIdentified;
  identified_value: string;
  primary: boolean;
}

export interface DiagnoseDetail {
  id: number;
  code: string;
  description: string;
}

export interface DiagnosisPayload {
  patient: number;
  diagnosis: number;
  date_type: DiagnoseDateType;
  start_date: string;
  end_date: string;
  identified: DiagnoseIdentified;
  primary: boolean;
}

export enum DiagnoseDateType {
  Oncet,
  Exacerbation
}

export enum DiagnoseIdentified {
  Referral,
  Assessment
}
