<app-loading *ngIf="isLoading" class="loading"></app-loading>

<div *ngIf="payrollInfo" class="visit-details">
  <form
    class="visit-details__form form"
    novalidate
    autocomplete="off"
    [formGroup]="form">

    <section>
      <div class="form__item">
        <span class="form__label">{{ "visitDetails.tabBilling_field_billType" | translate }}</span>
        <div class="form__value" [matTooltip]="'visitDetails.tabBilling_field_billType_tooltip' | translate">
          {{ payrollInfo.bill_type_value }}
        </div>
      </div>

      <div class="form__item">
        <span class="form__label">{{ "visitDetails.tabBilling_field_serviceCode" | translate }}</span>
        <div class="form__value" [matTooltip]="'visitDetails.tabBilling_field_serviceCode_tooltip' | translate">
          {{ payrollInfo.service_code }}
        </div>
      </div>

      <div class="form__item">
        <span class="form__label">{{ "visitDetails.tabBilling_field_totalHours" | translate }}</span>
        <div class="form__value" [matTooltip]="'visitDetails.tabPayroll_field_totalHours_tooltip' | translate">
          {{ payrollInfo.total_duration | duration }}
        </div>
      </div>

      <div class="form__item">
        <span class="form__label">{{ "visitDetails.tabBilling_field_overtimeHours" | translate }}</span>
        <div class="form__value" [class.editable-field]="isContentEditable">
          <app-editable-content #overtimeEditor formControlName="overtime_duration" [disabled]="!isContentEditable">
            <div editContent>
              <app-time-duration-field [formControl]="overtimeEditor.control"
                                       [maxHours]="24"
              ></app-time-duration-field>
            </div>

            <span viewContent [matTooltip]="'visitDetails.tabBilling_field_overtimeHours_tooltip' | translate">
              {{ overtimeEditor.control.value | duration }}
            </span>
          </app-editable-content>
        </div>
      </div>

      <div class="form__item">
        <span class="form__label">{{ "visitDetails.tabBilling_field_billableHours" | translate }}</span>
        <div class="form__value" [matTooltip]="'visitDetails.tabBilling_field_billableHours_tooltip' | translate">
          {{ payrollInfo.billable_duration | duration }}
        </div>
      </div>

      <div class="form__item">
        <span class="form__label">{{ "visitDetails.tabBilling_field_billableUnits" | translate }}</span>
        <div class="form__value" [matTooltip]="'visitDetails.tabBilling_field_billableUnits_tooltip' | translate">
          {{ payrollInfo.billable_units }}
        </div>
      </div>

      <div class="form__item">
        <span class="form__label">{{ "visitDetails.tabBilling_field_billRates" | translate }}</span>
        <div class="form__value" [class.editable-field]="isContentEditable">

          <app-editable-content #billRateEditor formControlName="bill_rate" [disabled]="!isContentEditable">
            <div editContent>
              $
              <app-number-field [formControl]="billRateEditor.control" [max]="999.99"></app-number-field>
            </div>

            <span viewContent [matTooltip]="'visitDetails.tabBilling_field_billRate_tooltip' | translate">
              {{ billRateEditor.control.value | currency }}
            </span>
          </app-editable-content>
        </div>
      </div>

      <div class="form__item amount">
        <span class="form__label">{{ "visitDetails.tabBilling_field_total" | translate }}</span>
        <div class="form__value" [matTooltip]="'visitDetails.tabBilling_field_total_tooltip' | translate">
          {{ payrollInfo.billable_sub_price | currency }}
        </div>
      </div>

      <div class="form__item">
        <span class="form__label">{{ "visitDetails.tabBilling_field_billed" | translate }}</span>
        <div class="form__value" [matTooltip]="'visitDetails.tabPayroll_field_billedStatus_tooltip' | translate">
          {{ getYesNo(payrollInfo.is_billed) }}
        </div>
      </div>

      <div class="form__item">
        <span class="form__label">{{ "visitDetails.tabPayroll_field_invoiceNumber" | translate }}</span>
        <div class="form__value" [matTooltip]="'visitDetails.tabPayroll_field_invoiceNumber_tooltip' | translate">
          {{ payrollInfo.claim_detail.number ?? '-' }}
        </div>
      </div>

      <div class="form__item">
        <span class="form__label">{{ "visitDetails.tabPayroll_field_payrollBatch" | translate }}</span>
        <div class="form__value" [matTooltip]="'visitDetails.tabPayroll_field_payrollBatchNumber_tooltip' | translate">
          {{ payrollInfo.claim_detail.batch.number ?? '-' }}
        </div>
      </div>
    </section>

    <section *ngIf="payrollInfo.mileage_included_access" class="secondary" formGroupName="mileage_included">
      <app-mileage-form [millageInfo]="payrollInfo.mileage_included" [disabled]="!isContentEditable"></app-mileage-form>
    </section>

    <section *ngIf="payrollInfo.travel_time_access" class="secondary" formGroupName="travel_time">
      <app-travel-time-form [travelInfo]="payrollInfo.travel_time" [disabled]="!isContentEditable"></app-travel-time-form>
    </section>

    <section class="total">
      <div class="form__item">
        <span class="form__label">{{ 'visitDetails.tabBilling_field_totalAmount' | translate }}</span>
        <div class="form__value" [matTooltip]="'visitDetails.tabBilling_field_totalAmount_tooltip' | translate">
          {{ payrollInfo.billable_price | currency }}
        </div>
      </div>
    </section>
  </form>

  <div class="visit-details__save">
    <button
      *ngIf="isContentEditable"
      matRipple
      class="app-button app-button_blue app-button_spinner"
      [disabled]="!form.valid || form.pristine || isSaving"
      (click)="save()">
      <mat-spinner *ngIf="isSaving" class="spinner" diameter="14" strokeWidth="1"></mat-spinner>
      {{ 'buttons.save' | translate }}
    </button>
  </div>
</div>
