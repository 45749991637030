export interface CaregiverExpense {
  id: number;
  expense_type: ExpenseType;
  expense_type_value: string;
  use_period_of_time: boolean;
  expense_date: string;
  period_from: string;
  period_to: string;
  quantity: number;
  description: string;
  is_billable: boolean;
  bill_rate: string;
  bill_total: string;
  is_payable: boolean;
  pay_rate: string;
  pay_total: string;
  user: number;
  created_at: string;
}

export interface CaregiverExpenseCreateUpdate {
  expense_type: ExpenseType;
  expense_date: string;
  quantity: number;
  description: string;
  is_billable: boolean;
  bill_rate: string;
  bill_total: string;
  is_payable: boolean;
  pay_rate: string;
  pay_total: string;
  user: number;
  use_period_of_time: boolean;
  period_from: string;
  period_to: string;
}

export interface ExpenseDialogData {
  mode: string;
  title: string;
  buttonLabel: string;
  userId: number;
  data?: CaregiverExpense;
}

export enum ExpenseType {
  TRAVEL_TIME,
  VACATION_PAY,
  COVID_PAY,
  TRAVELING_FOR_A_NEW_AID_CAREGIVER,
  SUPERVISOR_VISIT,
  SIGN_ON_BONUS,
  SEXUAL_HARASSMENT_TRAINING,
  REFERRAL,
  PTO_POLICY,
  PFML_COVID_VACCINE,
  PAY_RATE_MISTAKE,
  PAID_SICK_LEAVE,
  ON_CALL_BONUS,
  OFFICE_TIME,
  NURSING_OPEN,
  MILEAGE,
  INSERVICE,
  BUS_PASS_DEDUCTION,
  BTI_HOURS,
  BAD_WEATHER_MANDATION_BONUS,
  PTO_PERSONAL_TIME_OFF,
  WAIVER_TRAINING_EXPENSES,
  LOAN,
  HOLIDAY_PAY,
  RETRO_PAY
}
