import {
  ChangeDetectorRef,
  Component,
  DoCheck,
  ElementRef,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { NgModelBase } from '@app/shared/ng-model.base';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => ImageFieldComponent),
  multi: true
};

@Component({
  selector: 'app-image-field',
  templateUrl: './image-field.component.html',
  styleUrls: ['./image-field.component.scss'],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR],
  // changeDetection: ChangeDetectionStrategy.OnPush, // TODO: don't work with edit mode
})
export class ImageFieldComponent extends NgModelBase<string> implements OnInit, OnDestroy, DoCheck {
  @Input() placeholder: string;
  @Input() isDisabled = false;
  @ViewChild('input', {static: false})
  inputEl: ElementRef;

  constructor(
    private cdr: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit(): void { this.checkValue(); }
  ngDoCheck(): void { this.checkValue(); }
  ngOnDestroy(): void {}
  checkValue(): void { this.cdr.detectChanges(); }

  onFileChanged(event): void {
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();

      reader.readAsDataURL(event.target.files[0]); // read file as data url

      reader.onload = (imageData) => { // called once readAsDataURL is completed
        this.value = imageData.target.result;
        this.cdr.detectChanges();
      };
    }
  }
}

