import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

import { PaginatedResponse } from '../models/paginated-response.model';
import { DirectChatPayload, GroupChat, GroupChatPayload, UserChat } from '../models/chat.model';
import { MESSAGE_CENTER_URLS } from '../shared/MESSAGE_CENTER_URLS';
import { User, UserAccount } from '../models/user.model';
import { maximumOpenChats, userAccountStorageKey } from '../shared/constants';
import { UnreadMessages } from '../models/message.model';
import { CommunicationMode } from '@app/pages/message-center/models/communication.model';
import { HttpService } from '@app/core/services';

@Injectable({
  providedIn: 'root'
})
export class MessageCenterService {
  public messageCenterOpenedEmitter = new EventEmitter<boolean>();
  public threadClosedEmitter = new EventEmitter<number>();
  public openUserChatEmitter = new EventEmitter<UserChat>();
  public closeUserChatEmitter = new EventEmitter<number>();
  private addedUsersChats: UserChat[] = [];
  private readonly maximumOpenChats = maximumOpenChats;

  private updateUserChat = new BehaviorSubject<number>(null);
  addedUsersChatsSubject = new BehaviorSubject<UserChat[]>(this.addedUsersChats);

  newMessagesToCollapsedChats: { chatId: number, messages: number[] }[] = [];
  chatIdMessagesViewed = new Subject<number>();

  private unreadMessagesFromAllChatsSubject = new BehaviorSubject<number>(0);
  public unreadMessagesFromAllChats$ = this.unreadMessagesFromAllChatsSubject.asObservable();

  constructor(private http: HttpService) { }

  setUnreadMessagesHeader(count: number): void {
    this.unreadMessagesFromAllChatsSubject.next(count);
  }
  addUnreadMessageHeader(count: number): void {
    this.unreadMessagesFromAllChatsSubject.next(this.unreadMessagesFromAllChatsSubject.value + count);
  }
  removeUnreadMessageHeader(count: number): void {
    this.unreadMessagesFromAllChatsSubject.next(this.unreadMessagesFromAllChatsSubject.value - count);
  }

  get addedUsersChats$(): Observable<UserChat[]> {
    return this.addedUsersChatsSubject.asObservable();
  }

  get getUpdateUserChat(): Observable<number> {
    return this.updateUserChat.asObservable();
  }
  set setUpdateUserChat(value: number) {
    this.updateUserChat.next(value);
  }

  getUser(): UserAccount {
    return JSON.parse(localStorage.getItem(userAccountStorageKey));
  }

  updateAddedUserChat(chat: UserChat): void {
    let addedChat = this.addedUsersChats.find(addedChat => addedChat.id === chat.id);
    addedChat.image = chat.image;
    addedChat.name = chat.name;
    addedChat.access_type = chat.access_type;
    addedChat.access_type_value = chat.access_type_value;
    addedChat.members = chat.members;
    addedChat.unread_messages = chat.unread_messages;
    this.addedUsersChatsSubject.next(this.addedUsersChats);
  }

  openMessageCenter(): void {
    this.messageCenterOpenedEmitter.emit(true);
  }
  closeMessageCenter(): void {
    this.messageCenterOpenedEmitter.emit(false);
  }

  getAddedChats(): UserChat[] {
    return this.addedUsersChats;
  }

  openMessageThread(userId: number): void {
    this.addedUsersChats.find(user => user.id === userId).open_thread = true;
    this.addedUsersChatsSubject.next(this.addedUsersChats);
  }

  closeMessageThread(userId: number): void {
    this.addedUsersChats.find(user => user.id === userId).open_thread = false;
    this.addedUsersChatsSubject.next(this.addedUsersChats);
    this.threadClosedEmitter.emit(userId);
  }

  openUserChat(userChat: UserChat): void {
    const isExist = this.addedUsersChats.find(addedUser => addedUser.id === userChat.id);
    if (!isExist) {
      userChat.open_thread = false;

      this.addedUsersChats.forEach(chat => chat.isActive = false);
      userChat.isActive = true;

      this.addedUsersChats.push(userChat);
      this.openUserChatEmitter.emit(userChat);
    }

    if (this.addedUsersChats.length > this.maximumOpenChats) {
      this.closeUserChat(this.addedUsersChats[0].id);
    }
    this.addedUsersChatsSubject.next(this.addedUsersChats);
    if (userChat.unread_messages) {
      this.removeUnreadMessageHeader(userChat.unread_messages);
    }
  }

  openChatByUserId(userId: number): Observable<UserChat> {
    return this.getChatIdByUserId(userId).pipe(
      switchMap((res) => this.getUserChatById(res.chat)),
      tap(chat => this.openUserChat(chat))
    );
  }

  closeUserChat(chatId: number): void {
    let idx = this.addedUsersChats.findIndex(chat => chat.id === chatId);
    this.closeUserChatEmitter.emit(idx);
    this.removeUser(chatId);
  }

  clearAddedUsersChats(): void {
    this.addedUsersChats = [];
  }

  removeUser(userChatId: number): void {
    const idx = this.addedUsersChats.findIndex(user => user.id === userChatId);
    this.addedUsersChats.splice(idx, 1);
    this.addedUsersChatsSubject.next(this.addedUsersChats);

    this.newMessagesToCollapsedChats = this.newMessagesToCollapsedChats.filter(chat => chat.chatId !== userChatId);
  }

  getUsers(query: string = ''): Observable<PaginatedResponse<UserAccount>> {
    return <Observable<PaginatedResponse<UserAccount>>>this.http.GET(`${ MESSAGE_CENTER_URLS.user_account }${ query }`);
  }

  getUsersWidget(query: string = ''): Observable<PaginatedResponse<User>> {
    return <Observable<PaginatedResponse<User>>>this.http.GET(`${ MESSAGE_CENTER_URLS.user_account }${ query }`);
  }

  getUsersChatsList(query: string): Observable<PaginatedResponse<UserChat>> {
    return <Observable<PaginatedResponse<UserChat>>>this.http.GET(`${ MESSAGE_CENTER_URLS.user_chat }${ query }`);
  }

  getUserAccount(userId: number, query: string = ''): Observable<UserAccount> {
    return <Observable<UserAccount>>this.http.GET(`${ MESSAGE_CENTER_URLS.user_account }${ userId }/${ query }`);
  }

  createDirectChat(body: DirectChatPayload): Observable<UserChat> {
    return <Observable<UserChat>>this.http.POST(MESSAGE_CENTER_URLS.create_direct_chat, body);
  }

  createGroupChat(body: GroupChatPayload): Observable<GroupChat> {
    return <Observable<GroupChat>>this.http.POST(MESSAGE_CENTER_URLS.create_group_chat, body);
  }

  getUserChatById(chatId: number): Observable<UserChat> {
    return <Observable<UserChat>>this.http.GET(`${ MESSAGE_CENTER_URLS.user_chat }${ chatId }/`);
  }

  updateGroupChat(chatId: number, body: GroupChatPayload): Observable<GroupChat> {
    return <Observable<GroupChat>>this.http.PUT(`${ MESSAGE_CENTER_URLS.user_chat }${ chatId }/update_group_chat/`, body);
  }

  deleteUserChat(userChatId: number): Observable<any> {
    return <Observable<any>>this.http.DELETE(`${ MESSAGE_CENTER_URLS.user_chat }${ userChatId }/`);
  }

  getUnreadMessages(): Observable<UnreadMessages> {
    return <Observable<UnreadMessages>>this.http.GET(`${ MESSAGE_CENTER_URLS.user_chat }all_unread_messages/`);
  }

  private getChatIdByUserId(userId: number): Observable<{ chat: number }> {
    return this.http.POST(MESSAGE_CENTER_URLS.external_link, {
      communication_mode: CommunicationMode.Chat,
      user_to: userId
    });
  }
}
