import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError as observableThrowError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { HttpService } from '@app/core/services/http/http.service';
import { URLS } from '@app/shared/urls';
import { DatePeriod } from '@app/models/scheduling/date-period.model';
import { Scheduling } from '@app/models/scheduling/scheduling.model';
import { SchedulingUserFilter } from '@app/models/scheduling/scheduling-statistic.model';

@Injectable({
  providedIn: 'root'
})
export class SchedulingService {

  constructor(
    private http: HttpService
  ) { }

  getDatePeriods(query: string): Observable<DatePeriod[]> {
    return this.http.GET<DatePeriod[]>(`${ URLS.date_periods }?${ query }&utc_offset=${ new Date().getTimezoneOffset() }`);
  }

  getSchedulingStatistic(): Observable<SchedulingUserFilter> {
    return this.http.GET<SchedulingUserFilter>(`${ URLS.visit_weekly_scheduling }user_filter/`);
  }

  updateSchedulingStatistic(data: SchedulingUserFilter): Observable<SchedulingUserFilter> {
    return this.http.PUT<SchedulingUserFilter>(`${ URLS.visit_weekly_scheduling }user_filter/`, data);
  }

  getScheduling(params: object): Observable<Scheduling[]> {
    return this.http.GET<Scheduling[]>(`${ URLS.visit_weekly_scheduling }`, params);
  }
}
