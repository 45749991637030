import { Pipe, PipeTransform } from '@angular/core';

interface Office {
  name: string;
}

@Pipe({
  name: 'offices'
})
export class OfficesPipe implements PipeTransform {
  transform(offices: Array<Office>): any {
    return offices.map(item => item.name).join('/');
  }
}
