import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
  Renderer2,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { Message } from '../../models/message.model';
import { ISOTimeToDaysHoursMinutes } from '../../shared/helper';
import { ViewImageComponent } from '../view-image/view-image.component';
import { MessageCenterService } from '../../services';
import { cdkOverlayClass, ChatMessageStatus, MessageChannel, MessageStatus } from '../../shared/constants';

@Component({
  selector: 'app-chat-message',
  templateUrl: './chat-message.component.html',
  styleUrls: ['./chat-message.component.scss'],
})
export class ChatMessageComponent implements OnInit {
  @Input() message: Message;
  @Input() userChatId: number;
  @Input() userId: number;
  @Input() isThread: boolean = false;
  @Input() showDivider: boolean = false;
  @Output() openMessageThread = new EventEmitter<Message>();
  @Output() deleteMessageEmitter = new EventEmitter<number>();
  @Output() resendMessageEmitter = new EventEmitter<number>();
  @ViewChild('viewImage') viewImage: TemplateRef<any>;
  @HostBinding('class.mine') get isMine(): boolean {
    return this.message.sender.id === this.userId;
  }
  imageMaxHeight: number = 120;
  imageMargin: number = 10;
  readonly messagesStatuses = MessageStatus;
  readonly messagesChannels = MessageChannel;

  constructor(
    private dialog: MatDialog,
    private renderer: Renderer2,
    private messageCenterService: MessageCenterService
  ) { }

  ngOnInit(): void {
  }

  openImageView(id: number): void {
    this.renderer.addClass(document.body, cdkOverlayClass);
    const files = this.message.files.filter(file => this.isImage(file.content_type));
    this.dialog.open(ViewImageComponent, {
      data: { id, files }
    })
    .afterClosed()
    .subscribe(() => {
      this.renderer.removeClass(document.body, cdkOverlayClass);
    });
  }

  setFilesHeight(): number {
    let images = [];
    let docs = [];
    this.message.files.forEach(file => {
      if (this.isImage(file.content_type)) {
        images.push(file.file);
      }
      if (this.isDocument(file.content_type)) {
        docs.push(file.file);
      }
    });

    let height = 0;
    if (images.length) {
      height += images.length * this.imageMaxHeight + images.length * this.imageMargin;
    }
    if (docs.length) {
      height += 24 * docs.length + this.imageMargin * docs.length - 1;
    }

    return height;
  }

  answerToThread(message: Message): void {
    this.messageCenterService.openMessageThread(this.userChatId);
    this.openMessageThread.emit(message);
  }

  reply(): void {
    console.log('message:',this.message);
  }

  getMessageDeliveryStatus(): string {
    if (this.message.status === MessageStatus.sent) {
      if (!this.message.is_viewed) {
        return ChatMessageStatus.delivered;
      } else {
        return ChatMessageStatus.viewed;
      }
    }
    return '';
  }

  resendMessage(id: number): void {
    this.resendMessageEmitter.emit(id);
  }

  deleteMessage(messageId: number): void {
    this.deleteMessageEmitter.emit(messageId);
  }

  isImage(type: string): boolean {
    return type.startsWith('image/');
  }

  isDocument(type: string): boolean {
    return type.startsWith('application/') || type.startsWith('text/');
  }

  setTime(time: string): string {
    return ISOTimeToDaysHoursMinutes(time);
  }

}
