<div
  class="app-timepicker"
  [ngStyle]="{ height: height }"
  [class.active]="isSelectOpened">
  <mat-select
    class="app-timepicker__select"
    panelClass="app-timepicker-select-panel"
    placeholder="_"
    [(ngModel)]="hour"
    (openedChange)="onSelectOpen($event)"
    (ngModelChange)="emitTime()">
    <mat-option *ngFor="let hour of hours" [value]="hour">{{ hour }}</mat-option>
  </mat-select>

  <span class="app-timepicker__separator">:</span>

  <mat-select
    class="app-timepicker__select"
    panelClass="app-timepicker-select-panel"
    placeholder="_ _"
    [(ngModel)]="minute"
    (openedChange)="onSelectOpen($event)"
    (ngModelChange)="emitTime()">
    <mat-option *ngFor="let minute of minutes" [value]="minute">{{ minute }}</mat-option>
  </mat-select>

  <mat-select
    class="app-timepicker__select"
    panelClass="app-timepicker-select-panel"
    [(ngModel)]="period"
    (openedChange)="onSelectOpen($event)"
    (ngModelChange)="emitTime()">
    <mat-option *ngFor="let option of periodOptions" [value]="option.value">{{ option.label }}</mat-option>
  </mat-select>
</div>
