import { ConnectionPositionPair } from '@angular/cdk/overlay';

export const CONNECTION_POSITION: Record<string, ConnectionPositionPair> = {
  RIGHT: {
    offsetX: 4,
    offsetY: 14,
    originX: 'end',
    originY: 'bottom',
    overlayX: 'start',
    overlayY: 'bottom'
  },
  LEFT: {
    offsetX: -4,
    offsetY: 14,
    originX: 'start',
    originY: 'bottom',
    overlayX: 'end',
    overlayY: 'bottom'
  }
};
