<div *ngIf="visits.length > 0" class="notifications-container">
  <div *ngFor="let visit of visits" class="visit-notification">
    <div class="visit-notification__row">
      <div>{{ 'visitNotifications.text_youHaveCreatedVisit' | translate }} <span class="visit-id">#{{ visit.number }}</span></div>
      <mat-icon class="close-icon" (click)="close(visit.id)">close</mat-icon>
    </div>

    <div class="visit-notification__row">
      <div>
        {{ 'visitNotifications.text_scheduleTime' | translate: { date: visit.start_date_time | date: 'MM/dd/yyyy' } }}
        <span class="visit-time">{{ getVisitTime(visit.start_date_time, visit.end_date_time) }}</span>
      </div>
    </div>

    <div class="visit-notification__buttons">
      <button class="app-button app-button_blue" (click)="openVisit(visit.id)">
        {{ 'visitNotifications.button_openVisit' | translate }}
      </button>

      <button class="app-button app-button_green" (click)="blastVisit(visit)">
        {{ 'visitNotifications.button_blastVisit' | translate }}
      </button>
    </div>
  </div>
</div>
