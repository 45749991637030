<span *ngFor="let crumb of crumbs; let i = index; trackBy: trackByFn">
  <a
		class="crumb"
		[routerLinkActiveOptions]="{exact: true}"
		[routerLinkActive]="['active']"
		[routerLink]="crumb.url"
		[queryParams]="crumb.queryParams">
    <span class="name">{{ crumb.name | translate }}</span>
  </a>
  <span class="delimiter" [hidden]="(i + 1) === crumbs.length"> {{ delimiter }} </span>
</span>
