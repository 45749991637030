import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  QueryList,
  Renderer2,
  ViewChildren
} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { debounceTime, filter, finalize } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { MessageCenterService } from './services/message-center.service';
import { CreateGroupComponent } from './create-group/create-group.component';
import { PaginatedResponse } from './models/paginated-response.model';
import { UserChat } from './models/chat.model';
import { ISOTimeToDaysHoursMinutes } from './shared/helper';
import { cdkOverlayClass, ConversationType, userAccountStorageKey } from './shared/constants';
import { UserAccount } from './models/user.model';
import { ModuleSubscription, NotificationsService, RealtimeService } from '@app/core/services';
import { WsConnectionModule, WsEvent } from '@app/core/services/websocket/ws.models';
import { getWsData } from '@app/core/services/websocket/rxjs-ws-operators';

@UntilDestroy()
@Component({
  selector: 'app-message-center',
  templateUrl: './message-center.component.html',
  styleUrls: ['./message-center.component.scss']
})
export class MessageCenterComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChildren('unit', { read: ElementRef }) userRefs: QueryList<ElementRef>;

  isFirstLoading: boolean;
  usersList: UserChat[] = [];
  form: FormGroup;
  lastRequestTerm: string;
  isLoadingUsersList: boolean;

  usersTypes = [
    { value: 'all', title: 'All' },
    { value: 'staff', title: 'Office staff' },
    { value: 'caregiver', title: 'Caregivers' },
    { value: 'chats', title: 'Group Chats' }
  ];
  limitUsersList: number = 10;
  totalUsers: number;
  intObserver: IntersectionObserver;
  readonly conversationsTypes = ConversationType;

  private openedWsModule: ModuleSubscription;

  constructor(
    private dialogRef: MatDialogRef<MessageCenterComponent>,
    private messageCenterService: MessageCenterService,
    private dialog: MatDialog,
    private renderer: Renderer2,
    private fb: FormBuilder,
    private realtimeService: RealtimeService,
    private cdr: ChangeDetectorRef,
    private notificationsService: NotificationsService,
  ) {
  }

  get search(): FormControl {
    return this.form.get('search') as FormControl;
  }

  get type(): FormControl {
    return this.form.get('type') as FormControl;
  }

  ngOnInit(): void {
    this.initForm();

    this.loadUsersList(`?limit=${ this.limitUsersList }&lookup_field=${ this.type.value }`);
    this.initObserver();
    this.getUser();

    this.messageCenterService.chatIdMessagesViewed
      .pipe(untilDestroyed(this))
      .subscribe((chatId: number) => {
        if (chatId) {
          this.usersList.find(userChat => userChat.id === chatId).unread_messages = 0;
        }
      });

    this.form.valueChanges.pipe(
      debounceTime(500),
      filter(newValue => JSON.stringify(newValue) !== this.lastRequestTerm),
      untilDestroyed(this)
    ).subscribe(newValue => {
      this.usersList = [];
      this.lastRequestTerm = JSON.stringify(newValue);
      let query = `?limit=${ this.limitUsersList }&lookup_field=${ newValue.type }`;
      const search = newValue.search?.trim();
      if (search) {
        query += `&search=${ search }`;
      }
      this.loadUsersList(query);
    });
  }

  private getUser(): void {
    const user = this.messageCenterService.getUser();
    if (!user) {
      this.messageCenterService.getUserAccount(null, '?is_own=yes')
        .pipe(untilDestroyed(this))
        .subscribe((response: UserAccount) => {
          localStorage.setItem(userAccountStorageKey, JSON.stringify(response));
          this.initWebsocket();
        });
    } else {
      this.initWebsocket();
    }
  }

  private initWebsocket(): void {
    const user = this.messageCenterService.getUser();
    const addedUserChats = this.messageCenterService.getAddedChats();

    this.openedWsModule = this.realtimeService.subscribeToModule({
      user_id: user.id,
      module: WsConnectionModule.listOfChats
    });

    this.openedWsModule.moduleMessages$
      .pipe(getWsData(), untilDestroyed(this))
      .subscribe((data) => {
        const event = data.event;
        const payload = data.payload;

        switch (event) {
          case WsEvent.newMessage:
            const openedChat = addedUserChats.find(chat => chat.id === payload.chat);
            let user = this.usersList.find(userChat => userChat.id === payload.chat);
            if (user) {
              user.last_message.created_at = payload.created_at;
              user.last_message.has_files = payload.files.length;
              user.last_message.id = payload.id;
              user.last_message.is_viewed = payload.is_viewed;
              user.last_message.sender = payload.sender;
              user.last_message.text = payload.text;

              const addedChats = this.messageCenterService.getAddedChats();
              const currentChat = addedChats.find(chat => chat.id === payload.chat);

              if (openedChat && !currentChat.collapsed) {
                user.unread_messages = 0;
              } else {
                user.unread_messages += payload.is_new ? 1 : 0;
              }
              this.cdr.detectChanges();
            } else {
              this.messageCenterService.getUsersChatsList(`?pk=${ payload.chat }`)
                .subscribe((response: PaginatedResponse<UserChat>) => {
                  const newUser = response.results[0];
                  this.usersList = this.usersList.filter(user => user.name !== newUser.name);
                  this.usersList.unshift(newUser);
                  this.cdr.detectChanges();
                }, error => {
                  this.notificationsService.showError(error)
                });
            }
            break;
          case WsEvent.deleteChat:
            this.usersList = this.usersList.filter(user => user.id !== payload.id);
            this.cdr.detectChanges();
            break;
          case WsEvent.chatListUpdating:
            const idx = this.usersList.findIndex(chat => chat.id === payload.id);
            this.usersList[idx] = { ...this.usersList[idx], ...payload };
            this.cdr.detectChanges();
            break;
        }
      });
  }

  private initForm(): void {
    this.form = this.fb.group({
      search: null,
      type: this.usersTypes[0].value
    });
  }

  private loadUsersList(query: string): void {
    if (!this.usersList.length) {
      this.isFirstLoading = true;
    } else {
      this.isLoadingUsersList = true;
    }
    this.messageCenterService.getUsersChatsList(query).pipe(
      finalize(() => {
        this.isFirstLoading = false;
        this.isLoadingUsersList = false;
      }),
      untilDestroyed(this)
    ).subscribe((response: PaginatedResponse<UserChat>) => {
          response.results.forEach(user => {
            this.usersList.push(user);
          });
          this.usersList.forEach(user => {
            user.collapsed = false;
            user.open_thread = false;
          });
          this.totalUsers = response.count;
          this.cdr.detectChanges();
          this.messageCenterService.openMessageCenter();
        },
        (error) => {
          this.notificationsService.showError(error);
        });
  }

  ngAfterViewInit(): void {
    this.userRefs.changes.subscribe(user => {
      if (user.last) {
        this.intObserver.observe(user.last.nativeElement);
      }
    });
  }

  private initObserver(): void {
    this.intObserver = new IntersectionObserver((entries) => {
      this.handleIntersection(entries);
    }, {
      root: null,
      threshold: 0.5
    });
  }

  private handleIntersection(entries: IntersectionObserverEntry[]): void {
    if (entries[0].isIntersecting) {
      if (this.usersList.length < this.totalUsers) {
        let query = `?limit=${ this.limitUsersList }&offset=${ this.usersList.length }&lookup_field=${ this.type.value }`;
        const searchValue = this.search.value;
        if (searchValue) {
          query += `&search=${ searchValue }`;
        }
        this.loadUsersList(query);
      }
    }
  }

  openUserChat(userChat: UserChat): void {
    const addedChats = this.messageCenterService.getAddedChats();
    const currentChat = addedChats.find(chat => chat.id === userChat.id);
    if (currentChat) {
      currentChat.collapsed = false;
      this.messageCenterService.addedUsersChatsSubject.next(addedChats);
      this.usersList.find(user => user.id === userChat.id).unread_messages = 0;
    } else {
      if (userChat.conversation_type === ConversationType.direct && !userChat.id) {
        const payload = {
          direct_to: null,
          direct_to_meta: {}
        };
        if (userChat.direct_to.id) {
          payload.direct_to = userChat.direct_to.id;
        } else {
          payload.direct_to_meta = userChat.direct_to.meta;
        }
        this.messageCenterService.createDirectChat(payload)
          .pipe(untilDestroyed(this))
          .subscribe((response: UserChat) => {
            if (response) {
              this.openReceivedChat(response);
            }
          });
      } else {
        this.messageCenterService.getUserChatById(userChat.id)
          .pipe(untilDestroyed(this))
          .subscribe((response: UserChat) => {
            if (response) {
              this.openReceivedChat(response);
            }
          });
      }
    }
  }

  openReceivedChat(chat: UserChat): void {
    chat.collapsed = false;
    chat.isActive = false;
    this.messageCenterService.openUserChat(chat);
    this.cdr.detectChanges();

    if (chat.unread_messages) {
      this.usersList.find(user => user.id === chat.id).unread_messages = 0;
    }
  }

  deleteChat(event: MouseEvent, userChat: UserChat): void {
    event.stopPropagation();

    this.messageCenterService.closeUserChat(userChat.id);
    this.messageCenterService.deleteUserChat(userChat.id).pipe(untilDestroyed(this))
      .subscribe({
        next: () => {
          if (userChat.unread_messages) {
            this.messageCenterService.removeUnreadMessageHeader(userChat.unread_messages);
          }
        },
        error: (error) => this.notificationsService.showError(error)
      });
  }

  close(): void {
    this.dialogRef.close();
    this.messageCenterService.closeMessageCenter();
  }

  createNewGroup(): void {
    this.renderer.addClass(document.body, cdkOverlayClass);
    this.dialog.open(CreateGroupComponent, {
      width: '100%',
      maxWidth: '596px',
      maxHeight: '90vh',
      // hasBackdrop: true,
      // disableClose: true,
      restoreFocus: false,
      autoFocus: false,
      // backdropClass: 'message-center-create-group-backdrop',
      // panelClass: 'message-center-create-group-panel',
      data: {}
    })
      .afterClosed()
      .subscribe((response: boolean) => {
        if (response) {
          this.renderer.removeClass(document.body, cdkOverlayClass);
          this.reloadUsersList();
        }
      });
  }

  private reloadUsersList(): void {
    this.usersList = [];
    let query = `?limit=${ this.limitUsersList }&lookup_field=${ this.type.value }`;
    const search = this.search.value?.trim();
    if (search) {
      query += `&search=${ search }`;
    }
    this.loadUsersList(query);
  }

  setTime(time: string): string {
    return ISOTimeToDaysHoursMinutes(time);
  }

  ngOnDestroy(): void {
    this.openedWsModule.unsubscribe();
  }
}
