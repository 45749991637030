import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { HttpService } from '@app/core/services';
import { URLS } from '@app/shared/urls';
import { Physician } from '@app/models/physician/physician.model';
import { PaginatedResponse } from '@app/models/paginated-response.model';

@Injectable({
  providedIn: 'root'
})
export class PhysicianService {
  constructor(
    private http: HttpService
  ) { }

  getPhysicians(query: string): Observable<PaginatedResponse<Physician>> {
    return this.http.GET<PaginatedResponse<Physician>>(`${ URLS.physician }?${ query }`);
  }

  getPhysicianById(physicianId: number): Observable<Physician> {
    return this.http.GET<Physician>(`${ URLS.physician }${ physicianId }/`);
  }

  updatePhysician(physicianId: number, data: Physician): Observable<Physician> {
    return this.http.PUT<Physician>(`${ URLS.physician }${ physicianId }/`, data);
  }

  createPhysician(physician): Observable<Physician> {
    return this.http.POST(URLS.physician, physician);
  }
}
