<ng-template [ngIf]="!isEditing">
  <ng-content select="[viewContent]"></ng-content>

  <button *ngIf="!disabled"
          class="edit-btn"
          [matTooltip]="editTooltip | translate"
          (click)="startEdit()">
    <mat-icon class="material-icons-two-tone icon-gray icon-edit">edit</mat-icon>
  </button>
</ng-template>

<ng-template [ngIf]="isEditing">
  <ng-content select="[editContent]"></ng-content>

  <button matRipple type="button" (click)="finishEdit()">
    <mat-icon class="material-icons-two-tone icon-green">done</mat-icon>
  </button>

  <button matRipple type="button" (click)="cancelEdit()">
    <mat-icon class="material-icons-two-tone icon-red">close</mat-icon>
  </button>
</ng-template>
