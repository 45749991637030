import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DoCheck,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  forwardRef
} from '@angular/core';
import {NG_VALUE_ACCESSOR} from '@angular/forms';

import {NgModelBase} from '@app/shared/ng-model.base';

const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => CheckboxV2FieldComponent),
  multi: true
};

@Component({
  selector: 'app-checkbox-v2-field',
  templateUrl: './checkbox-v2-field.component.html',
  styleUrls: ['./checkbox-v2-field.component.scss'],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckboxV2FieldComponent extends NgModelBase<boolean> implements OnInit, DoCheck {
  active = false;

  @Input() isDisabled = false;
  @Input() placeholder = '';
  @Input() size: 'large' | 'medium' | 'small' = 'medium';

  @Output() changedEmitter = new EventEmitter<boolean>();

  @ViewChild('checkbox', {static: false}) checkboxEl: ElementRef;

  constructor(private cdr: ChangeDetectorRef) {
    super();
  }

  ngOnInit() {}

  ngDoCheck() {
    this.cdr.detectChanges();
  }

  switch(event) {
    this.value = !this.value;
    this.changedEmitter.emit(this.value);
    event.stopPropagation();
    event.preventDefault();
    return false;
  }

  focusIn() {
    this.active = true;
  }

  focusOut() {
    this.active = false;
    this.onBlur();
  }
}
