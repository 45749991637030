import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  Output
} from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-editable-content',
  templateUrl: 'editable-content.component.html',
  styleUrls: ['editable-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EditableContentComponent),
      multi: true,
    }
  ]
})
export class EditableContentComponent implements ControlValueAccessor {
  @Input() disabled = false;
  @Input() editTooltip: string;

  @Output() save = new EventEmitter<void>();

  control = new FormControl();
  isEditing = false;

  private controlValueWhenEditStart: any;
  private onChangeCallback: any = () => null;
  private onTouchedCallback: any = () => null;

  constructor(private cdr: ChangeDetectorRef) {
  }

  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }

  writeValue(obj: any): void {
    this.control.setValue(obj);
    this.cdr.markForCheck();
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.cdr.markForCheck();
  }

  startEdit(): void {
    this.isEditing = true;
    this.controlValueWhenEditStart = this.control.value;
    this.cdr.detectChanges();
  }

  finishEdit(): void {
    this.isEditing = false;
    this.onChangeCallback(this.control.value);
    this.onTouchedCallback();
    this.save.emit();
  }

  cancelEdit(): void {
    this.isEditing = false;
    this.control.setValue(this.controlValueWhenEditStart);
    this.onTouchedCallback();
  }
}
