import {
  Component,
  Input,
  Output,
  OnInit,
  OnChanges,
  EventEmitter,
  HostListener,
  ViewChild,
  ElementRef,
} from '@angular/core';

import { OptionsListItemIfc } from '@app/shared/interfaces/options-list-item.ifc';
import { PageSize, PageSizeOptions } from '@app/shared/helper';


interface PaginationControllerItem {
  active: boolean;
  value: number | null;
}

interface PaginationController {
  left1: PaginationControllerItem;
  left2: PaginationControllerItem;
  leftDots: boolean;
  center1: PaginationControllerItem;
  center2: PaginationControllerItem;
  center3: PaginationControllerItem;
  center4: PaginationControllerItem;
  center5: PaginationControllerItem;
  rightDots: boolean;
  right1: PaginationControllerItem;
  right2: PaginationControllerItem;
}

@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss']
})
export class PaginatorComponent implements OnInit, OnChanges {
  readonly pagesCountOptions: OptionsListItemIfc[];

  paginationController: PaginationController;
  pageNumber = 1;
  pages = 1;
  perPageOptionsOpened = false;

  @Input() total: number;
  @Input() perPage = PageSize;

  @Input() set setPageNumber(pageDetector: boolean) {
    this.selectPage({ active: true, value: 1 }, false);
  }

  @Output() paginatorConfigChanged = new EventEmitter();
  @Output() offsetWasChanged = new EventEmitter<number>();
  @Output() limitWasChanged = new EventEmitter<number>();

  @ViewChild('optionsListWrapper', {static: false})
  optionsListWrapper: ElementRef;

  @ViewChild('paginatorSelector', {static: false})
  paginatorSelector: ElementRef;


  @HostListener('document:click', ['$event.target'])
  clickOut(target) {
    if (!this.paginatorSelector.nativeElement.contains(target)) {
      this.perPageOptionsOpened = false;
    }
  }

  get showingFrom(): number {
    const from = this.pageNumber * this.perPage - this.perPage;

    return from > this.total ? this.total : from;
  }

  get showingTo(): number {
    const to = this.pageNumber * this.perPage;

    return to > this.total ? this.total : to;
  }

  constructor(private eRef: ElementRef) {
    this.pagesCountOptions = PageSizeOptions.map((item) => new OptionsListItemIfc(item, item.toString()));
  }

  ngOnInit(): void {
    this.initializePagination();
  }

  ngOnChanges(): void {
    this.initializePagination();
  }

  private initializePagination(): void {
    this.pages = Math.ceil(this.total / this.perPage);
    this.paginationController = this.controlPagination();
  }

  onLimitChanged(newLimit: number): void {
    this.perPage = newLimit;
    this.pageNumber = 1;
    this.perPageOptionsOpened = false;
    this.offsetWasChanged.emit(0);
    this.limitWasChanged.emit(newLimit);
    this.initializePagination();
    this.paginatorConfigChanged.emit();
  }

  increasePage(): void {
    if (this.pageNumber < this.pages) { this.pageNumber++; }
    this.offsetWasChanged.emit((this.pageNumber - 1) * this.perPage);
    this.initializePagination();
    this.paginatorConfigChanged.emit();
  }

  decreasePage(): void {
    if (this.pageNumber > 1) { this.pageNumber--; }
    this.offsetWasChanged.emit((this.pageNumber - 1) * this.perPage);
    this.initializePagination();
    this.paginatorConfigChanged.emit();
  }

  selectPage(page: PaginationControllerItem, emitEvents = true): void {
    if (page.active && typeof page.value !== 'number') { return; }
    this.pageNumber = page.value;
    this.initializePagination();
    if (emitEvents) {
      this.offsetWasChanged.emit((this.pageNumber - 1) * this.perPage);
      this.paginatorConfigChanged.emit();
    }
  }

  private controlPagination(): PaginationController {
    const pc: PaginationController = {
      left1: { active: false, value: null },
      left2: { active: false, value: null },
      leftDots: false,
      center1: { active: false, value: null },
      center2: { active: false, value: null },
      center3: { active: false, value: null },
      center4: { active: false, value: null },
      center5: { active: false, value: null },
      rightDots: false,
      right1: { active: false, value: null },
      right2: { active: false, value: null }
    };
    let centered = true;

    switch (true) {
      case(this.pageNumber === 2):
        centered = false;
        pc.center1.value = this.pageNumber - 1;
        pc.center2.value = this.pageNumber;
        pc.center2.active = true;
        pc.center3.value = this.pageNumber + 1 <= this.pages ? this.pageNumber + 1 : null;
        pc.center4.value = this.pageNumber + 2 <= this.pages ? this.pageNumber + 2 : null;
        pc.center5.value = this.pageNumber + 3 <= this.pages ? this.pageNumber + 3 : null;
        pc.rightDots = this.pageNumber + 6 <= this.pages;
        pc.right1.value = this.pageNumber + 5 <= this.pages ? this.pages - 1 : null;
        pc.right2.value = this.pageNumber + 4 <= this.pages ? this.pages : null;
        break;
      case(this.pageNumber === 1):
        centered = false;
        pc.center1.value = this.pageNumber;
        pc.center1.active = true;
        pc.center2.value = this.pageNumber + 1 <= this.pages ? this.pageNumber + 1 : null;
        pc.center3.value = this.pageNumber + 2 <= this.pages ? this.pageNumber + 2 : null;
        pc.center4.value = this.pageNumber + 3 <= this.pages ? this.pageNumber + 3 : null;
        pc.center5.value = this.pageNumber + 4 <= this.pages ? this.pageNumber + 4 : null;
        pc.rightDots = this.pageNumber + 7 <= this.pages;
        pc.right1.value = this.pageNumber + 6 <= this.pages ? this.pages - 1 : null;
        pc.right2.value = this.pageNumber + 5 <= this.pages ? this.pages : null;
        break;
      case(this.pages - this.pageNumber === 1):
        centered = false;

        if (this.pageNumber > 6) {
          pc.left1.value = 1;
          pc.left2.value = 2;
        } else {
          pc.left1.value = this.pageNumber - 4 > 0 ? 1 : null;
          pc.left2.value = this.pageNumber - 5 > 0 ? 2 : null;
        }

        pc.leftDots = this.pageNumber - 6 > 0;
        pc.center1.value = this.pageNumber - 3 > 0 ? this.pageNumber - 3 : null;
        pc.center2.value = this.pageNumber - 2 > 0 ? this.pageNumber - 2 : null;
        pc.center3.value = this.pageNumber - 1 > 0 ? this.pageNumber - 1 : null;
        pc.center4.value = this.pageNumber;
        pc.center4.active = true;
        pc.center5.value = this.pages;
        break;
      case(this.pages === this.pageNumber):
        centered = false;

        if (this.pageNumber > 6) {
          pc.left1.value = 1;
          pc.left2.value = 2;
        } else {
          pc.left1.value = this.pageNumber - 5 > 0 ? 1 : null;
          pc.left2.value = this.pageNumber - 6 > 0 ? 2 : null;
        }

        pc.leftDots = this.pageNumber - 7 > 0;
        pc.center1.value = this.pageNumber - 4 > 0 ? this.pageNumber - 4 : null;
        pc.center2.value = this.pageNumber - 3 > 0 ? this.pageNumber - 3 : null;
        pc.center3.value = this.pageNumber - 2 > 0 ? this.pageNumber - 2 : null;
        pc.center4.value = this.pageNumber - 1 > 0 ? this.pageNumber - 1 : null;
        pc.center5.value = this.pageNumber;
        pc.center5.active = true;
    }

    if (centered) {
      pc.left1.value = this.pageNumber - 3 > 0 ? 1 : null;
      pc.left2.value = this.pageNumber - 4 > 0 ? 2 : null;
      pc.leftDots = this.pageNumber - 5 > 0;
      pc.center1.value = this.pageNumber - 2 > 0 ? this.pageNumber - 2 : null;
      pc.center2.value = this.pageNumber - 1 > 0 ? this.pageNumber - 1 : null;
      pc.center3.value = this.pageNumber;
      pc.center3.active = true;
      pc.center4.value = this.pageNumber + 1 <= this.pages ? this.pageNumber + 1 : null;
      pc.center5.value = this.pageNumber + 2 <= this.pages ? this.pageNumber + 2 : null;
      pc.rightDots = this.pageNumber + 5 <= this.pages;
      pc.right1.value = this.pageNumber + 4 <= this.pages ? this.pages - 1 : null;
      pc.right2.value = this.pageNumber + 3 <= this.pages ? this.pages : null;
    }
    return pc;
  }
}
