import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { URLS } from '@app/shared/urls';
import { HttpService } from '@app/core/services';
import { Office } from '@app/models/settings/offices/office.model';
import { PaginatedResponse } from '@app/models/paginated-response.model';
import { OfficeWidget } from '@app/models/widgets.model';

@Injectable({
  providedIn: 'root'
})
export class OfficesService {

  constructor(
    private http: HttpService
  ) { }

  getOffices(params: object): Observable<PaginatedResponse<Office> | OfficeWidget[]> {
    return this.http.GET<PaginatedResponse<Office> | OfficeWidget[]>(`${ URLS.office }`, params);
  }

  getOfficeById(officeId: number): Observable<Office> {
    return this.http.GET<Office>(`${ URLS.office }${ officeId }/`);
  }

  deleteOffice(id: number): Observable<void> {
    return this.http.DELETE<void>(`${ URLS.office }${ id }/`);
  }
}
