import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
    selector: '[onlyNumber]',
})
export class OnlyNumberDirective {
    @Input() allowDecimal = true;

    constructor(private el: ElementRef) {
    }

    @HostListener('paste', ['$event'])
    public onPaste(event: ClipboardEvent) {
        const pasteText = event.clipboardData.getData('text/plain');

        if (isNaN(+pasteText)) {
            event.preventDefault();
        }
    }

    @HostListener('keydown', ['$event']) onKeyDown(event) {
        const e = <KeyboardEvent>event;

        if (this.allowDecimal && e.key === '.' && e.target['value'].includes('.')) {
            e.preventDefault();
        }

        if ([46, 8, 9, 27, 13, 110].indexOf(e.keyCode) !== -1 ||
            (e.key === '.' && this.allowDecimal) ||
            (e.keyCode === 65 && (e.ctrlKey || e.metaKey)) || // Allow: Ctrl+A
            (e.keyCode === 67 && (e.ctrlKey || e.metaKey)) || // Allow: Ctrl+C
            (e.keyCode === 86 && (e.ctrlKey || e.metaKey)) || // Allow: Ctrl+V
            (e.keyCode === 88 && (e.ctrlKey || e.metaKey)) || // Allow: Ctrl+X
            (e.keyCode === 90 && (e.ctrlKey || e.metaKey)) || // Allow: Ctrl+Z
            (e.keyCode >= 35 && e.keyCode <= 39)) { // Allow: home, end, left, right
            // let it happen, don't do anything
            return;
        }

        // Ensure that it is a number and stop the keypress
        if ((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) && (e.keyCode < 96 || e.keyCode > 105)) {
            e.preventDefault();
        }
    }
}
