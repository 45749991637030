import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DurationPipe } from '@app/shared/pipes/duration.pipe';
import { PhonePipe } from '@app/shared/pipes/phone.pipe';

import { SplitByDotPipe } from '@app/shared/pipes/split-by-dot.pipe';
import { ArrayFilterPipe } from '@app/shared/pipes/array-filter.pipe';
import { ArrayJoinPipe } from '@app/shared/pipes/array-join.pipe';
import { DefaultIfEmpty } from '@app/shared/pipes/default-if-empty.pipe';
import { OfficesPipe } from '@app/shared/pipes/offices.pipe';
import { FirstLetterOfNamePipe } from '@app/shared/pipes/first-letter-of-name.pipe';
import { MultipleStringDataPipe } from '@app/shared/pipes/multiple-string-data.pipe';
import { TruncatePipe } from './truncate.pipe';

const pipes = [
  SplitByDotPipe,
  ArrayFilterPipe,
  ArrayJoinPipe,
  DefaultIfEmpty,
  OfficesPipe,
  FirstLetterOfNamePipe,
  MultipleStringDataPipe,
  TruncatePipe,
  DurationPipe,
  PhonePipe
];

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    ...pipes,
  ],
  exports: [
    ...pipes
  ]
})
export class PipesModule { }
