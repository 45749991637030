import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';

import { DragAndDropFileUploadingComponent } from './drag-and-drop-file-uploading.component';
import { DragAndDropFileDirective } from './drag-and-drop-file.directive';
import { TrimInputModule } from '@app/shared/directives/trim-input/trim-input.module';

@NgModule({
  declarations: [
    DragAndDropFileUploadingComponent,
    DragAndDropFileDirective
  ],
  exports: [
    DragAndDropFileUploadingComponent,
    DragAndDropFileDirective
  ],
  imports: [
    CommonModule,
    MatIconModule,
    TranslateModule,
    FormsModule,
    TrimInputModule,
  ]
})
export class DragAndDropFileUploadingModule {
}
