import { Pipe, PipeTransform } from '@angular/core';

import { JoinObjIfc } from '@app/shared/interfaces/pipes.ifc';

@Pipe({
  name: 'arrayJoin'
})
export class ArrayJoinPipe implements PipeTransform {
  readonly defaultDelimiter: string = ', ';

  transform(items: any[], joinObj?: JoinObjIfc): string {
    if (!items) {
      return '';
    }

    if (!joinObj?.field) {
      return items.join(joinObj?.delimiter ?? this.defaultDelimiter);
    }

    return items.map((item) => item[joinObj.field]).join(joinObj.delimiter ?? this.defaultDelimiter);
  }
}
