export interface AssignBlastingSetting {
  id: number;
  enabled: boolean;
  radius_for_assignment: number;
  available_transportation: TransportationType[];
  allowed_overtime: boolean;
  allowed_overlapping: boolean;
  notice_time_for_available_cases: NoticeTime[];
  downtime_interval_between_cases: DowntimeInterval[];
}

export enum TransportationType {
  CarTransport,
  PublicTransport,
  Walking,
  Other,
}

export enum NoticeTime {
  OnCall,
  Hour1,
  Hours2,
  Hours3,
  Hours6
}

export enum DowntimeInterval {
  NoDowntime,
  Min15,
  Min30,
  Hour1,
  MoreThenHour,
}
