<div
  class="field {{ size }}"
  tabindex="0"
  [ngClass]="{
    active: optionsAreOpened,
    disabled: isDisabled || !options?.length || !optionsAreLoaded,
    invalid: invalid
  }"
  (focusin)="openOptions()"
  (focusout)="closeOptions()">

  <fieldset
    class="field-label"
    [ngClass]="{
      'label-top': legendWidth !== 0,
      'label-hidden': labelHidden
    }">
    <div class="label" *ngIf="!isDisplayByCount && selectedLabel === ''">
       {{ (optionsAreLoaded && !options?.length && noItemsPlaceholder) ? noItemsPlaceholder : placeholder }}
    </div>
  </fieldset>

  <div class="field-text" (click)="toggleOpenOptions()">
    <div *ngIf="!isDisplayByCount" class="value">{{ selectedLabel }}</div>
    <div *ngIf="isDisplayByCount" class="value">
      <span class="value-title">{{ title }}:</span>
      <span *ngIf="options">{{ checkedCount }} / </span>
      <span *ngIf="options">{{ options.length }}</span>
    </div>
    <div class="icon">
      <img
        src="/assets/images/arrow-drop-down-24px.svg"
        alt=""
        [ngClass]="{ rotated: optionsAreOpened }"
      />
    </div>
  </div>

  <div
    *ngIf="optionsAreLoaded"
    #optionsListEl
    class="field-options"
    [ngStyle]="{
      'max-height': maxOptionsListHeight ? maxOptionsListHeight + 'px' : 'auto',
      top: optionsPosition === 'center' && maxOptionsListHeight ? 'calc(50% - ' + maxOptionsListHeight / 2 + 'px)' : 'auto'
    }"
    [ngClass]="{
      open: optionsAreOpened,
      top: optionsPosition === 'top',
      bottom: optionsPosition === 'bottom'
    }">

    <div
      *ngIf="isDisplayByCount && allCheckboxTitle"
      class="option {{ size }}"
      (click)="switchAllCheckbox()">
      <div class="checkbox-icon">
        <i class="fa fa-check" [hidden]="!allCheckbox"></i>
      </div>
      <div class="checkbox-label">{{ allCheckboxTitle }}</div>
    </div>

    <div
      *ngFor="let option of options; let i = index"
      class="option {{ size }}"
      (click)="optionWasClicked(i)">

      <div class="checkbox-icon">
        <i class="fa fa-check" [hidden]="!option.checked"></i>
      </div>
      <div class="checkbox-label">{{ option.label | translate }}</div>
    </div>
  </div>
</div>
