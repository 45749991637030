import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

import { CommunicationMessagesService } from '@app/core/services';
import {
  AvailableApplication,
  AvailableOffice,
  AvailableOfficesAndApplications
} from '@app/models/hr/available-offices-and-applications.model';
import { CustomSelectOptions } from '@app/models/custom-select-options.model';
import { InterviewLink } from '@app/models/hr/interview.model';

@Component({
  selector: 'app-generate-link-popup',
  templateUrl: './generate-link-popup.component.html',
  styleUrls: ['./generate-link-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GenerateLinkPopupComponent implements OnInit {
  isLoading: boolean;
  isSent: boolean;
  form: FormGroup;
  applicationList: CustomSelectOptions[] = [];
  officeList: CustomSelectOptions[] = [];

  constructor(
    private dialogRef: MatDialogRef<GenerateLinkPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { applicant: number },
    private fb: FormBuilder,
    private communicationMessagesService: CommunicationMessagesService,
    private toastr: ToastrService,
    private cdr: ChangeDetectorRef
  ) { }

  get application(): FormControl {
    return this.form.get('application') as FormControl;
  }
  get office(): FormControl {
    return this.form.get('office') as FormControl;
  }

  ngOnInit(): void {
    this.loadAvailableOfficesAndApplications();
    this.initForm();
  }

  loadAvailableOfficesAndApplications(): void {
    this.isLoading = true;
    this.communicationMessagesService.getAvailableOfficesAndApplications({
      applicant: this.data.applicant
    })
    .subscribe((response: AvailableOfficesAndApplications) => {
      this.isLoading = false;
      if (response) {
        this.applicationList = response.available_applications.map((application: AvailableApplication) => {
          return {
            value: application.id,
            label: application.vacancy_details.title,
            checked: false
          };
        });
        if (this.applicationList.length === 1) {
          this.application.setValue(this.applicationList[0].value);
        }

        this.officeList = response.available_offices.map((office: AvailableOffice) => {
          return {
            value: office.id,
            label: office.name,
            checked: false
          };
        });

        this.cdr.detectChanges();
      }
    },
    () => {
      this.isLoading = false;
      this.cdr.detectChanges();
    });
  }

  initForm(): void {
    this.form = this.fb.group({
      application: ['', Validators.required],
      office: ['']
    });
  }

  generate(): void {
    this.isSent = true;
    const value = this.form.value;
    const data = {
      applicant: this.data.applicant,
      application: value.application,
      office: value.office
    };
    this.communicationMessagesService.generateLink(data)
    .subscribe((response: InterviewLink) => {
      this.isSent = false;
      this.dialogRef.close(response);
    },
    error => {
      this.isSent = false;
      this.toastr.error(
        error.error,
        error.statusText,
        { positionClass: 'toast-top-center' }
      );
    });
  }

  close(): void {
    this.dialogRef.close();
  }

}
