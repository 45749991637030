import { ChangeDetectionStrategy, Component } from '@angular/core';

import { selectNotFoundText } from '@app/shared/constants';

@Component({
  selector: 'app-no-results',
  templateUrl: './no-results.component.html',
  styleUrls: ['./no-results.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NoResultsComponent {
  readonly notFoundText = selectNotFoundText;

  constructor() { }
  
}
