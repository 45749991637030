<input
  #input
  autocomplete="off"
  onlyNumber
  class="form-control"
  [value]="inputText"
  [placeholder]="placeholder"
  [class.is-invalid]="invalid"
  [readonly]="disabled"
  (focus)="onInputFocus(input)"
  (input)="onInputChange($event.target.value, input)"
  (blur)="onInputBlur()"
/>
