import { Injectable } from '@angular/core';
import { Observable, throwError as observableThrowError } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { catchError } from 'rxjs/operators';

import { HttpService } from '@app/core/services';
import { URLS } from '@app/shared/urls';
import { PaginatedResponse } from '@app/models/paginated-response.model';
import { PatientAppointment, PatientAppointmentCreateUpdate } from '@app/models/patient/appointments.model';

@Injectable({
  providedIn: 'root'
})
export class AppointmentsService {

  constructor(
    private http: HttpService
  ) { }

  getAppointments(query: string): Observable<PaginatedResponse<PatientAppointment>> {
    return <Observable<PaginatedResponse<PatientAppointment>>>this.http
    .GET(`${ URLS.patient_medical_appointment }?${ query }`)
    .pipe(
      catchError((error: HttpErrorResponse) => observableThrowError(error))
    );
  }

  addAppointment(data: PatientAppointmentCreateUpdate): Observable<PatientAppointment> {
    return <Observable<PatientAppointment>>this.http
    .POST(`${ URLS.patient_medical_appointment }`, data)
    .pipe(
      catchError((error: HttpErrorResponse) => observableThrowError(error))
    );
  }

  editAppointment(id: string, data: PatientAppointmentCreateUpdate): Observable<PatientAppointment> {
    return <Observable<PatientAppointment>>this.http
    .PUT(`${ URLS.patient_medical_appointment }${ id }/`, data)
    .pipe(
      catchError((error: HttpErrorResponse) => observableThrowError(error))
    );
  }

  deleteAppointment(id: string): Observable<void> {
    return <Observable<void>>this.http
    .DELETE(`${ URLS.patient_medical_appointment }${ id }/`)
    .pipe(
      catchError((error: HttpErrorResponse) => observableThrowError(error))
    );
  }

  getMedicineFormsAndRoutes(): Observable<any> {
    return this.http.GET(`${ URLS.dictionary }?dictionary=medicine_form,medicine_route`);
  }
}
