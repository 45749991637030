import { Component, Input } from '@angular/core';
import { NavigateTopTabIfc } from '@app/models/navigate-top-tab.ifc';

@Component({
  selector: 'app-navigate-buttons',
  templateUrl: './navigate-buttons.component.html',
  styleUrls: ['./navigate-buttons.component.scss']
})
export class NavigateButtonsComponent {
  @Input() mainLink = '';
  @Input() currentUrl = '';
  @Input() previousPageUrl: string;
  @Input() previousPageLabel: string;
  @Input() currentPageLabel: string;
  @Input() tabs: NavigateTopTabIfc[];
  constructor() {}
}
