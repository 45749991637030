import { Component, OnDestroy, OnInit, Renderer2, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';

import { CreateNewMessageComponent } from '../create-new-message/create-new-message.component';
import { UserChat } from '../models/chat.model';
import { ChatSendingMessage } from '../models/message.model';
import { ChatService, MessageCenterAuthService, MessageCenterService } from '../services';
import { UserAccount } from '../models/user.model';
import {
  cdkOverlayClass,
  ConversationType,
  MessageChannel,
  MessageStatus,
  userAccountStorageKey
} from '../shared/constants';
import { CreateGroupComponent } from '../create-group/create-group.component';
import { unsubscribe } from '../shared/helper';

@Component({
  selector: 'app-message-center-chat',
  templateUrl: './message-center-chat.component.html',
  styleUrls: ['./message-center-chat.component.scss']
})
export class MessageCenterChatComponent implements OnInit, OnDestroy {
  @ViewChild('container', { read: ViewContainerRef }) container: ViewContainerRef;
  @ViewChild('userTemplate', { read: TemplateRef }) userTemplate: TemplateRef<any>;

  threadMessageId: number;
  readonly conversationsTypes = ConversationType;
  addedUsersChats: UserChat[];

  private subscriptions: Subscription[] = [];

  constructor(
    private messageCenterService: MessageCenterService,
    private dialog: MatDialog,
    private renderer: Renderer2,
    private chatService: ChatService,
    private connectService: MessageCenterAuthService
  ) { }

  ngOnInit(): void {
    this.getUser();

    this.messageCenterService.openUserChatEmitter
    .subscribe((userChat: UserChat) => {
      this.container.createEmbeddedView(this.userTemplate, { $implicit: userChat });
    });

    this.messageCenterService.closeUserChatEmitter
    .subscribe((chatId: number) => {
      if (chatId !== -1) {
        this.container.remove(chatId);
      }
    });

    this.subscriptions.push(
      this.messageCenterService.getUpdateUserChat
      .subscribe((chatId: number) => {
        if (chatId) {
          this.updateAddedUserChat(chatId);
        }
      }),

      this.messageCenterService.addedUsersChats$
      .subscribe((chats: UserChat[]) => {
        this.addedUsersChats = chats;
      })
    );
  }

  getUnreadMessages(chatId: number): number {
    const collapsedChats = this.messageCenterService.newMessagesToCollapsedChats;
    const idx = collapsedChats.findIndex(chat => chat.chatId === chatId);
    if (idx !== -1) {
      return this.messageCenterService.newMessagesToCollapsedChats[idx].messages.length;
    }
    return 0;
  }

  updateAddedUserChat(chatId: number): void {
    this.messageCenterService.getUserChatById(chatId)
    .subscribe((chat: UserChat) => {
      if (chat) {
        this.messageCenterService.updateAddedUserChat(chat);
      }
    });
  }

  private getUser(): void {
    const user = this.messageCenterService.getUser();
    if (!user) {
      this.messageCenterService.getUserAccount(null, '?is_own=yes')
      .subscribe((response: UserAccount) => {
        localStorage.setItem(userAccountStorageKey, JSON.stringify(response));
      });
    }
  }

  sendMessage(event: ChatSendingMessage, userChat: UserChat): void {
    const payload = {
      chat: userChat.id,
      text: event.message,
      channel: MessageChannel.internal,
      status: MessageStatus.created,
      files_data: event.files?.map(file => {
        return {
          file: file.file
        };
      })
    };
    if (userChat.open_thread) {
      payload['reply_to'] = this.threadMessageId;
    }
    this.chatService.sendMessage(payload).subscribe();
  }

  selectedForThreadMessageId(messageId: number): void {
    this.threadMessageId = messageId;
  }

  closeUserChat(userChatId: number): void {
    const addedChats = this.messageCenterService.getAddedChats();
    const user = addedChats.find(chat => chat.id === userChatId);
    if (user) {
      user.collapsed = false;
    }
    const idx = addedChats.findIndex(chat => chat.id === userChatId);
    if (idx !== -1) {
      this.container.remove(idx);
      this.messageCenterService.removeUser(userChatId);
    }
  }

  closeThread(userId: number): void {
    this.messageCenterService.closeMessageThread(userId);
  }

  openNewMessagePopup(user: UserChat): void {
    this.renderer.addClass(document.body, cdkOverlayClass);

    this.dialog.open(CreateNewMessageComponent, {
      width: '80vw',
      maxWidth: '1222px',
      autoFocus: false,
      data: { ...user }
    })
    .afterClosed()
    .subscribe(() => {
      this.renderer.removeClass(document.body, cdkOverlayClass);
    });
  }

  editGroupChat(chatId: number): void {
    this.renderer.addClass(document.body, cdkOverlayClass);
    this.dialog.open(CreateGroupComponent, {
      width: '100%',
      maxWidth: '596px',
      maxHeight: '90vh',
      restoreFocus: false,
      autoFocus: false,
      data: { chatId }
    })
      .afterClosed()
      .subscribe(() => {
        this.renderer.removeClass(document.body, cdkOverlayClass);
      });
  }

  collapseChat(chatId: number): void {
    const addedChats = this.messageCenterService.getAddedChats();
    addedChats.find(chat => chat.id === chatId).collapsed = true;
    this.messageCenterService.addedUsersChatsSubject.next(addedChats);
  }

  expandChat(chatId: number): void {
    const addedChats = this.messageCenterService.getAddedChats();
    addedChats.find(chat => chat.id === chatId).collapsed = false;
    this.messageCenterService.addedUsersChatsSubject.next(addedChats);

    this.messageCenterService.newMessagesToCollapsedChats =
      this.messageCenterService.newMessagesToCollapsedChats.filter(chat => chat.chatId !== chatId);

    this.messageCenterService.chatIdMessagesViewed.next(chatId);
  }

  setInputOnFocus(chatId: number): void {
    const addedChats = this.messageCenterService.getAddedChats();
    addedChats.forEach(chat => chat.isActive = false);
    addedChats.find(chat => chat.id === chatId).isActive = true;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription =>  unsubscribe(subscription));
    this.container.clear();
    this.messageCenterService.clearAddedUsersChats();
  }
}
