import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Location } from '@angular/common';

import { BreadcrumbIfc } from '@app/shared/interfaces/breadcrumb.ifc';

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BreadcrumbsComponent {
  @Input() crumbs: BreadcrumbIfc[] = [];
  @Input() delimiter = '·';

  constructor(
    private location: Location
  ) { }

  onBackClicked() {
    this.location.back();
  }

  trackByFn(_, item: BreadcrumbIfc) {
    return item.name;
  }
}
