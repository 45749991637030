import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

import { fromUTCtoDateTime } from '@app/shared/helper';
import { OptionsListItemIfc } from '@app/shared/interfaces/options-list-item.ifc';
import { VisitDetailsService } from '@app/core/services';
import { PaginatedResponse } from '@app/models/paginated-response.model';
import { VisitHistory } from '@app/models/patient/visit-details/history.model';
import { PaginationIfc } from '@app/shared/interfaces/pagination.class';

@Component({
  selector: 'app-history-tab',
  templateUrl: './history-tab.component.html',
  styleUrls: ['./history-tab.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HistoryTabComponent implements OnInit {
  @Input() visitId: number;

  isLoading: boolean;
  historyList: VisitHistory[] = [];
  displayedColumns = ['changes', 'description', 'date', 'author'];
  pagination: PaginationIfc = new PaginationIfc();

  rangeDate = { start: '', end: '' };
  startDatePicker = new FormControl();
  endDatePicker = new FormControl();

  typesOptions: OptionsListItemIfc[] = [
    { value: 0, label: 'visitDetails.tabHistory_select_type_fieldChanged', checked: true },
    { value: 1, label: 'visitDetails.tabHistory_select_type_statusChanged', checked: true },
    { value: 2, label: 'visitDetails.tabHistory_select_type_added', checked: true },
    { value: 3, label: 'visitDetails.tabHistory_select_type_deleted', checked: true },
    { value: 4, label: 'visitDetails.tabHistory_select_type_visitAssign', checked: true },
    { value: 5, label: 'visitDetails.tabHistory_select_type_visitBlasting', checked: true }
  ];
  selectedTypes = this.typesOptions.map(type => type.value).join(',');
  directionSelectOptionList: 'bottom' | 'top' = 'bottom';

  constructor(
    private visitDetailsService: VisitDetailsService,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.loadHistory();

    this.visitDetailsService.getRefreshCurrentTab
    .subscribe((response: boolean) => {
      if (response) {
        this.isLoading = true;
        this.historyList = [];
        this.cdr.detectChanges();
        this.loadHistory();
      }
    });
  }

  loadHistory(): void {
    this.isLoading = true;
    this.historyList = [];

    let requestKwargs = [
      this.pagination.getPaginationParameters(),
      `types=${ this.selectedTypes }`
    ];
    if (this.rangeDate.start && this.rangeDate.end) {
      requestKwargs.push(
        `start=${ this.rangeDate.start }`,
        `end=${ this.rangeDate.end }`
      );
    }

    this.visitDetailsService.getVisitHistory(this.visitId, requestKwargs.join('&'))
    .subscribe((response: PaginatedResponse<VisitHistory>) => {
      this.isLoading = false;
      this.historyList = response.results;
      this.historyList.length < 2 ? this.directionSelectOptionList = 'top' : this.directionSelectOptionList = 'bottom';
      this.pagination.countChanged(response.count);
      this.cdr.detectChanges();
    },
      () => {
        this.isLoading = false;
        this.cdr.detectChanges();
      });
  }

  convertDateTime(date: string): string {
    return fromUTCtoDateTime(date);
  }

  dateRangeChange(dateRangeStart: HTMLInputElement, dateRangeEnd: HTMLInputElement): void {
    const start = dateRangeStart.value;
    let end = dateRangeEnd.value;
    if (!end) {
      return;
    }
    this.rangeDate.start = start;
    this.rangeDate.end = end;

    this.pagination.offsetChanged();
    this.loadHistory();
  }

  resetTimeRange(): void {
    this.startDatePicker.setValue(null);
    this.endDatePicker.setValue(null);

    this.rangeDate.start = '';
    this.rangeDate.end = '';

    this.pagination.offsetChanged();
    this.loadHistory();
  }

  changeTypes(newTypes: OptionsListItemIfc[]): void {
    const selectedIds = newTypes.reduce((ids, type) => {
      if (type.checked) {
        ids.push(type.value);
      }
      return ids;
    }, []);
    this.selectedTypes = selectedIds.join(',');
    this.pagination.offsetChanged();
    this.loadHistory();
  }

}
