export interface VisitTimeLog {
  available_timestamp: string;
  canceled_timestamp: string;
  confirmation_timestamp: string;
  offered_timestamp: string;
  requested_timestamp: string;
  scheduled_timestamp: string;
  missed_timestamp: string;
  en_route_timestamp: string;
  no_show_timestamp: string;
  in_progress_timestamp: string;
  completed_timestamp: string;
}

export enum VisitStatus {
  available = 0,
  canceled = 1,
  offered = 2,
  requested = 3,
  scheduled = 4,
  missed = 5,
  enRoute = 6,
  noShow = 7,
  inProgress = 8,
  completed = 9
}