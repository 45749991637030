import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { HttpService } from '@app/core/services';
import { CommunicationMode, ExternalLink } from '../models/communication.model';
import { MESSAGE_CENTER_URLS } from '../shared/MESSAGE_CENTER_URLS';

@Injectable({
  providedIn: 'root'
})
export class MessageCenterAuthService {
  private readonly accessTokenStorageKey = 'message-center.access_token';

  constructor(private http: HttpService) {
  }

  authorize(): Observable<any> {
    const hasToken = !!this.getToken();

    if (hasToken) {
      return of(null);
    }

    return this.loadToken().pipe(
      tap((response) => this.saveToken(response.jwt_token))
    );
  }

  getToken(): string {
    return localStorage.getItem(this.accessTokenStorageKey);
  }

  private saveToken(token: string): void {
    localStorage.setItem(this.accessTokenStorageKey, this.encodeJWT(token));
  }

  private encodeJWT(jwt: string): string {
    let token: string;

    try {
      const base64Url = jwt.split('.')[1];
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const jsonPayload = decodeURIComponent(window.atob(base64).split('').map((c) => {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));
      token = JSON.parse(jsonPayload).token;
    } catch (e) {
      console.error('Failed to encode JWT: ', e);
    }

    return token;
  }

  private loadToken(): Observable<ExternalLink> {
    return this.http.POST<ExternalLink>(MESSAGE_CENTER_URLS.external_link, {
      communication_mode: CommunicationMode.JwtToken,
      user_to: null
    });
  }
}
