import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { groupsTypes } from '../shared/constants';
import { GroupChat, UserChat } from '../models/chat.model';
import { ErrorService, MessageCenterService } from '../services';
import { PaginatedResponse } from '../models/paginated-response.model';
import { User } from '../models/user.model';
import { LoadedMessageFile } from '../models/message.model';

@Component({
  selector: 'app-create-group',
  templateUrl: './create-group.component.html',
  styleUrls: ['./create-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CreateGroupComponent implements OnInit {
  form: FormGroup;
  isLoading: boolean;
  isSending: boolean;

  readonly groupsTypes = groupsTypes;
  usersList: User[];
  selectedUsers: User[] = [];
  chatId: number;
  loadedAvatar: string;

  allChipsUsers: string[] = [];
  selectedChipsUsers: string[] = [];

  constructor(
    private dialogRef: MatDialogRef<CreateGroupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private messageCenterService: MessageCenterService,
    private errorService: ErrorService,
    private cdr: ChangeDetectorRef
  ) { }

  get avatar(): FormControl {
    return this.form.get('avatar') as FormControl;
  }
  get name(): FormControl {
    return this.form.get('name') as FormControl;
  }
  get type(): FormControl {
    return this.form.get('type') as FormControl;
  }

  ngOnInit(): void {
    this.initForm();

    this.chatId = this.data?.chatId;
    if (this.chatId) {
      this.loadExistingGroup(this.chatId);
    } else {
      this.loadUsers();
    }
  }

  private initForm(): void {
    this.form = this.fb.group({
      avatar: null,
      name: [null, [Validators.required, Validators.maxLength(24)]],
      type: [null, Validators.required]
    });
  }

  changeChipsUsers(event): void {
    const user = this.usersList.find(user => user.short_name === event);
    this.selectedUsers.push(user);
  }

  searchUsers(search: string): void {
    if (search && search.length < 2) {
      return;
    }
    this.allChipsUsers = [];
    this.usersList = [];
    this.cdr.detectChanges();
    this.loadUsers(search);
  }

  loadUsers(search?: string): void {
    let query = '?is_widget=true&exclude_yourself=yes';
    if (search) {
      query += `&search=${ search }&lookup_field=full_name&limit=100`;
    } else {
      query += '&limit=15';
    }
    this.messageCenterService.getUsersWidget(query)
    .subscribe((response: PaginatedResponse<User>) => {
      this.usersList = response.results;
      this.allChipsUsers = response.results.map(user => user.short_name);
      this.cdr.detectChanges();
    },
      (error: HttpErrorResponse) => {
      });
  }

  private loadExistingGroup(chatId: number): void {
    this.isLoading = true;
    this.messageCenterService.getUserChatById(chatId)
      .subscribe((response: UserChat) => {
        this.isLoading = false;
        this.loadedAvatar = response.image;
        this.form.patchValue({
          avatar: response.image,
          name: response.name,
          type: this.groupsTypes.find(type => type.value === response.access_type).value
        });
        this.selectedUsers = response.members;
        this.selectedChipsUsers = response.members.map(user => user.short_name);
        this.cdr.detectChanges();
        this.loadUsers();
      },
        () => {
          this.isLoading = false;
          this.cdr.detectChanges();
        });
  }

  removeChip(event: string): void {
    const idx = this.selectedUsers.findIndex(user => user.short_name === event);
    this.selectedUsers.splice(idx, 1);
  }

  createGroup(): void {
    this.isSending = true;
    const form = this.form.value;
    form.users = [];
    let newMembers = [];
    this.selectedUsers.forEach(user => {
      if (user?.id) {
        form.users.push(user.id);
      } else {
        newMembers.push(user?.meta);
      }
    });

    const payload = {
      image: form.avatar,
      name: form.name,
      access_type: +form.type,
      members: form.users,
      to_create_members: newMembers
    };
    if (this.chatId) {
      if (payload.image === this.loadedAvatar) {
        delete payload.image;
      }
      this.messageCenterService.updateGroupChat(this.chatId, payload)
        .subscribe((response: GroupChat) => {
          if (response) {
            this.messageCenterService.setUpdateUserChat = this.chatId;
            this.dialogRef.close(true);
          }
      },
        error => {
          this.isSending = false;
          this.cdr.detectChanges();
          this.errorHandler(error);
        });
    } else {
      this.messageCenterService.createGroupChat(payload)
      .subscribe((response: GroupChat) => {
        if (response) {
          this.dialogRef.close(true);
        }
      },
      error => {
        this.isSending = false;
        this.cdr.detectChanges();
        this.errorHandler(error);
      });
    }
  }

  private errorHandler(error): void {
    if (error.status === 400 && error.error.non_field_errors) {
      this.name.setErrors({ uniqueGroupName: error.error.non_field_errors });
      this.cdr.detectChanges();
    }
    this.errorService.nonFieldErrors(error);
  }

  addAvatar(files: LoadedMessageFile[]): void {
    let avatar = null;
    if (files.length) {
      avatar = files[0].file;
    }
    this.form.patchValue({ avatar });
  }

  close(): void {
    this.dialogRef.close();
  }
}
