<div class="editor-panel">
  <div class="editor-panel__item top-info" *ngIf="identifier">
    <app-chip
      *ngIf="identifier"
      [value]="identifier.value"
      [status]="identifier.status">
    </app-chip>
  </div>

  <div class="editor-panel__item title" *ngIf="titleWithoutCard">
    {{ titleWithoutCard }}
  </div>

  <div class="editor-panel__item user-card" *ngIf="editorInfoCard">
    <app-editor-info-card [editorInfoCard]="editorInfoCard"
                          [idTitle]="idTitle">
    </app-editor-info-card>
  </div>
  <app-editor-menu
    class="editor-panel__item"
    [menuNodes]="menuNodes"
    [nodesWithNotifications]="menuNodesWithNotifications"
    [invalidMenuNodes]="invalidMenuNodes"
    [activeNode]="activeNode"
    (activeNodeChanged)="changeActiveNode($event)">
  </app-editor-menu>
</div>
