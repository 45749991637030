import {
  Component,
  ElementRef,
  ViewChild,
  Input,
  Output,
  EventEmitter,
  OnInit,
  forwardRef,
  ChangeDetectionStrategy,
  ChangeDetectorRef, OnChanges, DoCheck, SimpleChanges
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import {NgModelBase} from '@app/shared/ng-model.base';
import { OptionsListItemIfc } from '@app/shared/interfaces/options-list-item.ifc';
import { FormService } from '@app/core/services';


const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => MaskFieldComponent),
  multi: true
};


@Component({
  selector: 'app-mask-field',
  templateUrl: './mask-field.component.html',
  styleUrls: ['./mask-field.component.scss'],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MaskFieldComponent extends NgModelBase<string | number> implements OnInit, OnChanges, DoCheck {
  active = false;
  labelTop = false;
  legendWidth = 0;

  @Input() invalid = false;
  @Input() isDisabled = false;
  @Input() labelHidden = false;
  @Input() isUppercase = false;
  @Input() placeholder = '';
  @Input() buttonOne = '';
  @Input() buttonTwo = '';

  @Input() mask = '';
  @Input() size: 'small' | 'medium' | 'large' = 'medium';

  @Output() buttonOneClick = new EventEmitter();
  @Output() buttonTwoClick = new EventEmitter();
  @Output() focusOut = new EventEmitter();
  @Output() inputChange = new EventEmitter<OptionsListItemIfc>();
  @Output() inputKeyUp = new EventEmitter<string | number>();

  @ViewChild('input', {static: false}) inputEl: ElementRef;
  @ViewChild('label', {static: false}) label: ElementRef;

  constructor(private eRef: ElementRef,
              private formService: FormService,
              private cdr: ChangeDetectorRef) {
    super();
  }
  ngOnInit() { this.checkValue(); }
  ngDoCheck() {
    this.cdr.detectChanges();
    this.checkValue();
  }
  ngOnChanges(event: SimpleChanges) { this.cdr.detectChanges(); }

  focusInput() {
    this.active = true;
    // this.legendWidth = this.label.nativeElement.clientWidth + 6;
    this.labelTop = true;
    setTimeout(() => {
      this.cdr.detectChanges();
    }, 50);
  }

  focusOutInput(event: any) {
    // this.value = event.target.value as string;
    setTimeout(() => {
      this.active = false;
      this.focusOut.emit();
      this.checkValue();
      this.onBlur();
    }, 100);
  }

  changeValue(newValue) {
    this.value = newValue;
    this.checkValue();
  }

  checkValue(): void {
    if ((typeof this.value !== 'string' && typeof this.value !== 'number') || (this.value as string).length === 0) {
      if (!this.active) {
        this.labelTop = false;
        this.legendWidth = 0;
      }
    } else {
      this.labelTop = true;
      // this.legendWidth = this.label.nativeElement.clientWidth + 6;
    }
    this.cdr.detectChanges();
  }

  buttonOneClicked(): void { this.buttonOneClick.emit(); }
  buttonTwoClicked(): void { this.buttonTwoClick.emit(); }
}
