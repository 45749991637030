import { interval, Observable, Subject } from 'rxjs';
import { takeUntil, takeWhile } from 'rxjs/operators';
import { WsAction, WsConnectionModule } from './ws.models';
import { JwtGenerator } from '@app/core/services/websocket/jwt-generator';
import { environment } from '../../../../environments/environment';

export interface WsConnectionParams {
  module: WsConnectionModule;
  company_id: number;
  user_id?: number;
  module_pk?: string | number;

  token: string;
  secret: string;
  url: string;
}

export class Websocket {
  private reconnectInterval: number = 10000;
  private reconnectAttempts: number = 3;
  private isConnected: boolean;
  private reconnection$: Observable<number>;
  private socket: WebSocket;
  private newMessageSubject$: Subject<MessageEvent> = new Subject<MessageEvent>();

  public disconnect$: Subject<void> = new Subject<void>();
  public message$: Observable<MessageEvent>;

  constructor(private connectionParams: WsConnectionParams) {
    this.message$ = this.newMessageSubject$.asObservable().pipe(takeUntil(this.disconnect$));
    this.connect();
  }

  async connect(): Promise<void> {
    const jwt = await JwtGenerator.generateJWT(this.connectionParams.token, this.connectionParams.secret);
    this.socket = new WebSocket(`${ this.connectionParams.url }?token=${ jwt }`);

    this.socket.onopen = this.onConnectionOpen.bind(this);
    this.socket.onmessage = this.onReceivedMessage.bind(this);
    this.socket.onerror = this.onError.bind(this);
    this.socket.onclose = this.onClose.bind(this);
  }

  private reconnect(): void {
    if (this.reconnection$) {
      return;
    }

    this.reconnection$ = interval(this.reconnectInterval).pipe(
      takeWhile((value, index) => index < this.reconnectAttempts && !this.isConnected),
      takeUntil(this.disconnect$)
    );

    this.reconnection$.subscribe({
      next: () => this.connect(),
      error: () => null,
      complete: () => {
        this.reconnection$ = null;

        if (this.socket.readyState !== WebSocket.OPEN) {
          this.log('Failed to reconnect Websocket. Max reconnection attempts reached.', this.connectionParams);
          this.disconnect();
        }
      }
    });
  }

  private onConnectionOpen(data: Event): void {
    if (this.socket.readyState === WebSocket.OPEN) {
      this.isConnected = true;
      this.sendMessage({
        action: WsAction.init,
        payload: this.connectionParams
      });
      this.log(`Websocket ${ this.reconnection$ ? 'reconnected' : 'connected' }`, this.connectionParams);
    }
  }

  private onReceivedMessage(message: MessageEvent): void {
    this.newMessageSubject$.next(message);
  }

  private onError(error: Event): void {
    console.error('Error in websocket module: ', this.connectionParams.module, error);
  }

  private onClose(event: CloseEvent): void {
    this.isConnected = false;
    if (!event.wasClean || event.code === 1011) { // 1011 code occurs when the client lost internet connection for some time
      this.log('Websocket connection lost. Trying to reconnect', this.connectionParams);
      this.reconnect();
    }
  }

  sendMessage(message: any): void {
    this.socket.send(JSON.stringify(message));
  }

  disconnect(): void {
    if (this.socket) {
      this.socket.close(1000, 'Closing a socket');
      this.log('Websocket Disconnected', this.connectionParams);
    }

    this.disconnect$.next();
    this.disconnect$.complete();
  }

  private log(...args: any[]): void {
    if (!environment.production) {
      console.log(...args);
    }
  }
}
