import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';

import { VisitDetailsService, FormService } from '@app/core/services';
import { VisitDetailsNestedBlasting } from '@app/models/patient/visit-details/blasting.model';
import { ItemsComponent } from '@app/core/components/items/items.component';
import { WsConnectionModule, WsEvent } from '@app/core/services/websocket/ws.models';
import { NestedBlastingStatus, NestedBlastedVisit } from '@app/models/blasting/blasting.model';
import { TranslateService } from '@ngx-translate/core';

@UntilDestroy()
@Component({
  selector: 'app-nested-blasting-table',
  templateUrl: './nested-blasting-table.component.html',
  styleUrls: ['./nested-blasting-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NestedBlastingTableComponent extends ItemsComponent<VisitDetailsNestedBlasting> {
  @Input() blastingId: number;
  @Input() offerStatus: number;

  readonly displayedColumns = ['isNew', 'caregiver', 'service', 'distance', 'status', 'action'];

  protected realtimeModules = [{ module: WsConnectionModule.active_blasting }];
  protected updateItemRealtimeEvents = [
    {
      name: WsEvent.visit_blasting_offer,
      filter: (message => message.payload.blasting === this.blastingId)
    }
  ];
  protected createItemRealtimeEvents = this.updateItemRealtimeEvents;

  constructor(
    private visitDetailsService: VisitDetailsService,
    private formService: FormService,
    private translateService: TranslateService,
  ) {
    super();
  }

  protected getItems(): Observable<VisitDetailsNestedBlasting[]> {
    return this.visitDetailsService.getVisitBlastingNestedOffers(this.blastingId, {
      ...this.pagination.getParams(),
      no_limits: 'no'
    });
  }

  protected sortItems(items: VisitDetailsNestedBlasting[]): VisitDetailsNestedBlasting[] {
    const groupedByStatus: { [key in NestedBlastingStatus]?: VisitDetailsNestedBlasting[] } = {};

    items.forEach((item) => groupedByStatus[item.status] = [...(groupedByStatus[item.status] ?? []), item]);

    return [
      ...(groupedByStatus[NestedBlastingStatus.Coordinated] ?? []),
      ...(groupedByStatus[NestedBlastingStatus.Accepted] ?? [])
        .sort((a, b) => +a.is_new < +b.is_new ? 1 : -1),
      ...(groupedByStatus[NestedBlastingStatus.Offered] ?? []),
      ...(groupedByStatus[NestedBlastingStatus.Declined] ?? [])
        .sort((a, b) => a.id < b.id ? 1 : -1),
      ...(groupedByStatus[NestedBlastingStatus.Missed] ?? []),
      ...(groupedByStatus[NestedBlastingStatus.Canceled] ?? []),
    ];
  }

  rejectOffer(nestedVisitId: number): void {
    this.visitDetailsService.visitRejectOffer(this.blastingId, { assign: nestedVisitId })
      .pipe(untilDestroyed(this))
      .subscribe({
        next: () => this.updateItem({
          id: nestedVisitId,
          status: NestedBlastingStatus.Declined,
          status_value: this.translateService.instant('blastingOfferStatus.declined')
        }),
        error: (error) => this.formService.nonFieldErrors(error)
      });
  }

  approveOffer(nestedVisitId: number): void {
    this.visitDetailsService.visitApproveOffer(this.blastingId, { assign: nestedVisitId })
      .pipe(untilDestroyed(this))
      .subscribe({
        next: () => this.updateItem({
          id: nestedVisitId,
          status: NestedBlastingStatus.Coordinated,
          status_value: this.translateService.instant('blastingOfferStatus.coordinated')
        }),
        error: (error) => this.formService.nonFieldErrors(error)
      });
  }
}
