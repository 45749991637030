import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import * as moment from 'moment';

import { InterviewService } from '@app/core/services';
import { VacancyApplication } from '@app/models/hr/vacancy.model';
import { Interview, ScheduledInterviewResponse } from '@app/models/hr/interview.model';
import { setInterviewStyleClass } from '../../helper';

@Component({
  selector: 'app-scheduled-interviews',
  templateUrl: './scheduled-interviews.component.html',
  styleUrls: ['./scheduled-interviews.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ScheduledInterviewsComponent implements OnInit {
  isLoading: boolean;
  interviewsDataSource: MatTableDataSource<ScheduledInterviewResponse>;
  displayedColumns: string[] = ['position', 'type', 'interviewers', 'notes', 'date', 'status'];

  constructor(
    private dialogRef: MatDialogRef<ScheduledInterviewsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: VacancyApplication,
    private interviewService: InterviewService,
    private cdr: ChangeDetectorRef,
    private router: Router) {
  }

  ngOnInit(): void {
    this.loadScheduledInterviews();
  }

  private loadScheduledInterviews(): void {
    this.isLoading = true;
    this.interviewService.getScheduledInterviews(this.data.vacancy, this.data.applicant)
    .subscribe((response: Interview[]) => {
      this.isLoading = false;

      const orderStatuses = [
        1, // Scheduled
        4, // Completed
        3, // No Show
        2 // Canceled
      ];
      response.sort((a, b) => orderStatuses.indexOf(a.status) - orderStatuses.indexOf(b.status));
      let dataSource = response.map((interview: Interview) => {
        let customInterviewers = [];
        interview.interviewers_details.forEach(el => {
          customInterviewers.push(el.full_name);
        });

        return {
          applicationId: interview.id,
          position: interview.application_details.vacancy_details.title,
          type: 'Interview',
          interviewers: customInterviewers,
          notes: interview.notes,
          date: moment(interview.scheduled_from).format('MM/DD/YYYY h:mm A'),
          status: interview.status_value,
          vacancyId: interview.application_details.vacancy
        };
      });
      this.interviewsDataSource = new MatTableDataSource(dataSource);
      this.cdr.detectChanges();
    }, () => {
      this.isLoading = false;
      this.cdr.detectChanges();
    });
  }

  rowClick(row: ScheduledInterviewResponse) {
    const url = this.router.createUrlTree(
      [`/calendar/`],
      { queryParams: { position: row.applicationId } }
    );
    window.open(url.toString(), '_blank');
  }

  setColor(status: string): string {
    return setInterviewStyleClass(status);
  }

  close(): void {
    this.dialogRef.close();
  }

}
