<div class="popup">
	<div class="popup__header">
		<h3 class="popup__title">{{ "applicantDetails.communication_popup_generateLinkForInterview" | translate }}</h3>
		<button mat-icon-button class="close-icon" (click)="close()">
			<mat-icon>close</mat-icon>
		</button>
	</div>

  <app-loading *ngIf="isLoading" class="loading"></app-loading>

	<div matDialogContent [hidden]="isLoading">
		<form
			class="popup__form"
			novalidate
			autocomplete="off"
			[formGroup]="form">

			<div class="fields-input">
				<div class="fields-input__action">
					<label class="fields-input__label">{{ "applicantDetails.communication_popup_field_application" | translate }}*</label>
					<app-fake-select-field
						placeholder="{{ 'placeholders.select' | translate }}"
						formControlName="application"
						[options]="applicationList"
						[updateValueSet]="application.value"
						[invalid]="application.touched && application.invalid"
					></app-fake-select-field>
				</div>

				<div class="popup__errors">
					<mat-error *ngIf="application.touched && application.invalid">
						{{ "commonErrors.field_required" | translate }}
					</mat-error>
				</div>
			</div>

			<div class="fields-input">
				<div class="fields-input__action">
					<label class="fields-input__label">{{ "applicantDetails.communication_popup_field_office" | translate }}</label>
					<app-fake-select-field
						placeholder="{{ 'placeholders.select' | translate }}"
						formControlName="office"
						[options]="officeList"
						[updateValueSet]="office.value"
					></app-fake-select-field>
				</div>
			</div>
		</form>
	</div>

	<div class="popup__buttons" [hidden]="isLoading">
		<button
			matRipple
			class="app-button app-button_green app-button_spinner"
			[disabled]="!form.valid || isSent"
			(click)="generate()">
			<mat-spinner
				*ngIf="isSent"
				class="spinner"
				diameter="16"
				strokeWidth="2">
			</mat-spinner>
			{{ "buttons.generate" | translate }}
		</button>
	</div>
</div>
