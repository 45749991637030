import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError as observableThrowError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { HttpService } from './http/http.service';
import { URLS } from '@app/shared/urls';
import { Resume } from '@app/models/hr/resume.model';
import { PaginatedResponse } from '@app/models/paginated-response.model';

@Injectable({
  providedIn: 'root'
})
export class ResumeService {

  constructor(
    private http: HttpService
  ) { }

  getResume(request): Observable<PaginatedResponse> {
    return <Observable<PaginatedResponse>>this.http
    .GET(request)
    .pipe(
      catchError((error: HttpErrorResponse) => observableThrowError(error))
    );
  }

  uploadResume(data): Observable<Resume> {
    return <Observable<Resume>>this.http
    .POST(`${ URLS.resume }`, data)
    .pipe(
      catchError((error: any) => observableThrowError(error))
    );
  }

  downloadResumeById(id: number): Observable<Resume> {
    return <Observable<Resume>>this.http
    .GET(`${ URLS.resume }${ id }/`)
    .pipe(
      catchError((error: HttpErrorResponse) => observableThrowError(error))
    );
  }
}
