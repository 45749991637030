<div class="billing-btns">
  <ng-container *ngFor="let button of buttonLinks">
    <button
      matRipple
      class="app-button app-button_blue"
      [class.active]="button.link === placementPageLink"
      [disabled]="isLoading"
      (click)="goToBillingPage(button.link)">
      <span class="material-icons-outlined">{{ button.icon }}</span>
      {{ button.label | translate }}
    </button>
  </ng-container>
</div>
