<div *ngIf="index > 0" class="panel panel_prev" (click)="prev()">
  <div class="panel__icon">
    <span class="material-icons arrow-icon">arrow_back_ios_new</span>
  </div>
</div>

<img [src]="srcImg" alt="">

<div *ngIf="index < data.files.length - 1" class="panel panel_next" (click)="next()">
  <div class="panel__icon">
    <span class="material-icons arrow-icon">arrow_forward_ios</span>
  </div>
</div>
