import { Injectable } from '@angular/core';
import { Observable, throwError as observableThrowError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { URLS } from '@app/shared/urls';
import { HttpService } from './http/http.service';
import { Interview } from '@app/models/hr/interview.model';

@Injectable({
  providedIn: 'root'
})
export class InterviewService {

  constructor(
    private http: HttpService
  ) { }

  getScheduledInterviews(vacancyId: number, applicantId: number): Observable<Interview[]> {
    return <Observable<Interview[]>>this.http
    .GET(`${ URLS.scheduled_interviews }?vacancy=${ vacancyId }&applicant=${ applicantId }`)
    .pipe(
      catchError((error: any) => observableThrowError(error))
    );
  }
}
