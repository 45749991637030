import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError as observableThrowError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { HttpService } from './http/http.service';
import { URLS } from '@app/shared/urls';
import { Interview } from '@app/models/hr/interview.model';

@Injectable({
  providedIn: 'root'
})
export class CalendarService {

  constructor(
    private http: HttpService
  ) { }

  getEventsList(month: number, year: number): Observable<Interview[]> {
    return <Observable<Interview[]>>this.http
    .GET(`${ URLS.interview_calenar }?month=${ month }&year=${ year }`)
    .pipe(
      catchError((error: HttpErrorResponse) => observableThrowError(error))
    );
  }

  getEventById(id: number): Observable<Interview> {
    return <Observable<Interview>>this.http
    .GET(`${ URLS.interview }${ id }/`)
    .pipe(
      catchError((error: any) => observableThrowError(error))
    );
  }

  addEvent(data: Interview): Observable<Interview> {
    return <Observable<Interview>>this.http
    .POST(`${ URLS.interview }`, data)
    .pipe(
      catchError((error: HttpErrorResponse) => observableThrowError(error))
    );
  }

  updateEvent(interviewId: number, data: Interview): Observable<Interview> {
    return <Observable<Interview>>this.http
    .PUT(`${ URLS.interview }${ interviewId }/`, data)
    .pipe(
      catchError((error: HttpErrorResponse) => observableThrowError(error))
    );
  }

  deleteEvent(interviewId: number) {
    return this.http
    .DELETE(`${ URLS.interview }${ interviewId }/`)
    .pipe(
      catchError((error: HttpErrorResponse) => observableThrowError(error))
    );
  }

}
