import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { Observable, throwError as observableThrowError } from 'rxjs';

import { HttpService } from '@app/core/services';
import { URLS } from '@app/shared/urls';
import { CustomizationSetup } from '@app/models/customization/customization.model';

@Injectable({
  providedIn: 'root'
})
export class CustomizationService {

  constructor(
    private http: HttpService
  ) { }

  getCustomizationSetup(): Observable<CustomizationSetup> {
    return <Observable<CustomizationSetup>>this.http
    .GET(URLS.customization_setup)
    .pipe(
      catchError((error: HttpErrorResponse) => observableThrowError(error))
    );
  }
}
