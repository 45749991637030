<div class="chat-list">
  <ng-template #container></ng-template>
</div>

<ng-template #userTemplate let-userChat>
  <div
    class="chat-item"
    tabindex="1"
    [ngClass]="{
     collapsed: userChat.collapsed,
     active: userChat.isActive,
     'has-new-messages': getUnreadMessages(userChat.id)
    }"
    (focus)="setInputOnFocus(userChat.id)">
    <div class="chat-item__header header">
      <div class="header__title">
        <ng-container *ngIf="userChat.conversation_type === conversationsTypes.direct; else groupChat">
          <ng-container *ngIf="!userChat.open_thread; else isThread">
            <app-user-avatar
              iconSize="32px"
              [name]="userChat.name"
              [avatar]="userChat.image"
            ></app-user-avatar>
            <div class="header__person">
              <div class="header__name ellipsis">{{ userChat.name }}</div>
              <div class="header__post ellipsis">
                {{ userChat.direct_to.role_value }}
              </div>
            </div>
          </ng-container>

          <ng-template #isThread>
            <span class="material-icons back-icon" (click)="closeThread(userChat.id)">arrow_back</span>
            <div class="header__person">
              <div class="header__name ellipsis">{{ userChat.name }} (Coordinator)</div>
              <div class="header__post">Thread</div>
            </div>
          </ng-template>
        </ng-container>

        <ng-template #groupChat>
          <ng-container *ngIf="!userChat.open_thread; else isThread">
            <app-user-avatar
              iconSize="32px"
              [name]="userChat.name"
              [avatar]="userChat.image"
            ></app-user-avatar>
            <div class="header__person">
              <div class="header__name ellipsis group-chat-name" (click)="editGroupChat(userChat.id)">{{ userChat.name }}</div>
              <div class="header__post">Group Chat ({{ userChat.members.length }} users)</div>
            </div>
          </ng-container>

          <ng-template #isThread>
            <span class="material-icons back-icon" (click)="closeThread(userChat.id)">arrow_back</span>
            <div class="header__person">
              <div class="header__name ellipsis">{{ userChat.name }} (Coordinator)</div>
              <div class="header__post">Thread</div>
            </div>
          </ng-template>
        </ng-template>
      </div>

      <div class="header__actions">
        <span *ngIf="getUnreadMessages(userChat.id)" class="header__unread-messages">{{ getUnreadMessages(userChat.id) }}</span>
        <span class="material-icons icon-action" (click)="openNewMessagePopup(userChat)">open_in_new</span>
        <span *ngIf="!userChat.collapsed" class="material-icons icon-action" (click)="collapseChat(userChat.id)">remove</span>
        <span *ngIf="userChat.collapsed" class="material-icons icon-action" (click)="expandChat(userChat.id)">expand_less</span>
        <span class="material-icons icon-action" (click)="closeUserChat(userChat.id)">close</span>
      </div>
    </div>

    <div class="chat-item__body body" [class.collapse]="userChat?.collapsed">
      <app-messages-list
        [userChat]="userChat"
        (updateChat)="updateAddedUserChat($event.id)"
        (selectedForThreadMessageId)="selectedForThreadMessageId($event)"
      ></app-messages-list>
    </div>

    <div class="chat-item__footer footer" [class.collapse]="userChat?.collapsed">
      <app-send-message
        [setInputFocus]="userChat.isActive"
        (inputClickedEmitter)="setInputOnFocus(userChat.id)"
        (messageChanged)="sendMessage($event, userChat)"
      ></app-send-message>
    </div>
  </div>
</ng-template>
