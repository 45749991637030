import { ChangeDetectionStrategy, Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import { TimePeriod, TimepickerTime } from '@app/shared/components/timepicker-field/time.models';

@Component({
  selector: 'app-timepicker-field',
  templateUrl: './timepicker-field.component.html',
  styleUrls: ['./timepicker-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TimepickerFieldComponent),
      multi: true
    }
  ]
})
export class TimepickerFieldComponent implements ControlValueAccessor, OnInit {
  @Input() height: string = '42px';
  @Input() stepMinutes: number = 1;
  @Output() timeChanged = new EventEmitter<string>();

  hours: number[] = Array.from({ length: 12 }, (_, i) => i === 0 ? 12 : i);
  minutes: string[];
  hour: number;
  minute: string;
  period: TimePeriod = 'AM';
  periodOptions: { value: string, label: TimePeriod }[] = [
    { value: 'AM', label: 'AM' },
    { value: 'PM', label: 'PM' }
  ];
  isSelectOpened: boolean;

  private onChange: (value: string) => void;
  private onTouched: () => void;

  ngOnInit(): void {
    this.generateMinutesArray();
  }

  private generateMinutesArray(): void {
    const step = this.stepMinutes;
    const length = 60 / step;
    this.minutes = Array.from({ length }, (_, i) => {
      const minute = i * step;
      return minute < 10 ? '0' + minute : '' + minute;
    });
  }

  writeValue(value: string): void {
    if (value) {
      const timeParts = value.split(':');
      this.hour = parseInt(timeParts[0], 10);
      this.minute = timeParts[1].slice(0, 2);
      this.period = timeParts[1].slice(3) as TimePeriod;
    } else {
      this.hour = null;
      this.minute = null;
      this.period = 'AM';
    }
  }

  emitTime(): void {
    if (this.hour !== null && this.minute === null) {
      this.minute = '00';
    }
    if (this.hour === null && this.minute !== null) {
      this.hour = 0;
    }

    const time = `${ this.hour }:${ this.minute } ${ this.period }`;
    this.onChange(time);
    this.onTouched();
    this.timeChanged.emit(time);
  }

  onSelectOpen(event: boolean): void {
    this.isSelectOpened = event;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
}

export function parseTimepickerValue(time: string): TimepickerTime {
  const [hoursStr, minutesStr, period] = time.split(/:| /);

  return {
    hours: parseInt(hoursStr, 10),
    minutes: parseInt(minutesStr, 10),
    period: period as TimePeriod,
  };
}

export function convertTimepickerTimeToMinutes(time: TimepickerTime): number {
  let hours = getHourIn24Format(time.hours, time.period);

  return hours * 60 + time.minutes;
}

export function getHourIn24Format(hours: number, median: TimePeriod): number {
  if (hours === 12) {
    hours = 0;
  }

  return median === 'AM' ? hours : hours + 12;
}
