import { ChangeDetectionStrategy, Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';

import { Language } from '@app/models/language.model';
import { LanguageService } from '@app/core/services';

@Component({
  selector: 'app-language-switcher',
  templateUrl: './language-switcher.component.html',
  styleUrls: ['./language-switcher.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class LanguageSwitcherComponent implements OnInit {
  @ViewChild(MatMenuTrigger, { static: true, read: ElementRef })
  private menuRef: ElementRef<HTMLElement>;

  languages: Language[] = [];
  menuWidth: number;

  get menuData() {
    return this.menuWidth = this.menuRef.nativeElement.clientWidth;
  }

  constructor(
    public languageService: LanguageService
  ) {
  }

  ngOnInit() {
    this.languages = this.languageService.getLanguages();
  }

  changeLang(lang: Language) {
    this.languageService.switchLanguage(lang);
  }

}
