import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError as observableThrowError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { URLS } from '@app/shared/urls';
import { HttpService } from '../services/http/http.service';
import { MessageResponse } from '@app/models/hr/message-unit.model';
import { AvailableOfficesAndApplications } from '@app/models/hr/available-offices-and-applications.model';
import { InterviewLink } from '@app/models/hr/interview.model';
import { DataForSendMails, Mailing } from '@app/models/hr/mailing.model';

@Injectable({
  providedIn: 'root'
})
export class CommunicationMessagesService {

  constructor(
    private httpService: HttpService
  ) { }

  getMessages(uuid: string): Observable<MessageResponse> {
    return <Observable<MessageResponse>>this.httpService
    .GET(`${ URLS.messages }${ uuid }/user_to/`)
    .pipe(
      catchError((error: HttpErrorResponse) => observableThrowError(error))
    );
  }

  sendMessage<T>(uuid: string, body: string, externalType: number, interviewUuid: string): Observable<T> {
    const params = { uuid, body, external_type: externalType, interview_uuid: interviewUuid };
    return <Observable<T>>this.httpService
    .POST(`${ URLS.messages }`, params)
    .pipe(
      catchError((error: HttpErrorResponse) => observableThrowError(error))
    );
  }

  getAvailableOfficesAndApplications(data: { applicant: number }): Observable<AvailableOfficesAndApplications> {
    return <Observable<AvailableOfficesAndApplications>>this.httpService
    .POST(`${ URLS.pre_generate_interview_link }`, data)
    .pipe(
      catchError((error: HttpErrorResponse) => observableThrowError(error))
    );
  }

  generateLink(data: InterviewLink): Observable<InterviewLink> {
    return <Observable<InterviewLink>>this.httpService
    .POST(`${ URLS.generate_interview_link }`, data)
    .pipe(
      catchError((error: HttpErrorResponse) => observableThrowError(error))
    );
  }

  sendMailing(data: DataForSendMails): Observable<Mailing> {
    return <Observable<Mailing>>this.httpService
    .POST(`${ URLS.mailing }`, data)
    .pipe(
      catchError((error: HttpErrorResponse) => observableThrowError(error))
    );
  }

}
