import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input, OnChanges,
  Output, SimpleChanges,
} from '@angular/core';

import { OptionsListItemIfc } from '@app/shared/interfaces/options-list-item.ifc';

@Component({
  selector: 'app-multiselect',
  templateUrl: './multiselect.component.html',
  styleUrls: ['./multiselect.component.scss']
})
export class MultiSelectComponent implements OnChanges {
  @Input() title = '';
  @Input() allCheckboxTitle = 'All';
  @Input() size: 'small' | 'medium' | 'large' = 'medium';
  @Input() optionsPosition: 'bottom' | 'top' = 'bottom';
  @Input() closeForSelect: boolean = false;
  @Input() maxOptionsListHeight: number;
  @Input() reset: boolean;

  @Output() selectedEmitter = new EventEmitter<OptionsListItemIfc[]>();

  checkedCount = 0;
  allCheckbox = false;
  optionsAreOpened = false;
  options: OptionsListItemIfc[];

  constructor(private eRef: ElementRef) {}

  @Input()
  set initOptions(options: any[]) {
    if (Array.isArray(this.options)) {
      return;
    }
    this.options = options.map((item) => new OptionsListItemIfc(item.value, item.label, !!item.checked));
    this.recalculateCheckedCount();
  }

  @HostListener('document:click', ['$event.target'])
  clickOut(target): void {
    if (!this.eRef.nativeElement.contains(target)) {
      this.closeOptions();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.initOptions) {
      this.options = changes.initOptions.currentValue;
      this.recalculateCheckedCount();
    }
    if (changes.reset) {
      this.recalculateCheckedCount();
    }
  }

  switchAllCheckbox(): void {
    this.allCheckbox = !this.allCheckbox;
    this.options.forEach((item) => item.checked = this.allCheckbox);
    this.recalculateCheckedCount();
    this.selectedEmitter.emit(this.options);
  }

  clickOptions(): void {
    this.optionsAreOpened = !this.optionsAreOpened;
  }

  closeOptions(): void {
    this.optionsAreOpened = false;
  }

  optionWasSelected(index: number): void {
    this.options[index].checked = !this.options[index].checked;
    this.recalculateCheckedCount();
    this.selectedEmitter.emit(this.options);
  }

  recalculateCheckedCount(): void {
    this.checkedCount = this.options.filter((item) => item.checked).length;
    this.allCheckbox = this.options.every((item) => item.checked);
  }
}
