import { FormGroup } from '@angular/forms';

export interface VisitDetailsTab {
  name: VisitDetailsTabName;
  icon: string;
  attentions?: number;
}

export enum VisitDetailsTabName {
  visit = 'visitDetails.tab_visit',
  schedule = 'visitDetails.tab_schedule',
  billing = 'visitDetails.tab_billing',
  payroll = 'visitDetails.tab_payroll',
  blasting = 'visitDetails.tab_blasting',
  history = 'visitDetails.tab_history'
}

export interface VisitTabFormComponent {
  form: FormGroup;

  save(onSuccess?: () => void): void;
}
