<div class="message">
  <div class="message__header">
    <span class="message__title">New Message</span>
    <button matRipple class="btn-close" (click)="close()">
      <span class="material-icons btn-close__icon">close</span>
    </button>
  </div>

  <div matDialogContent>
    <form
      class="message__form"
      novalidate
      [formGroup]="form"
      autocomplete="off">

      <app-form-field label="Send To" fieldClasses="new-message send-to">
        <ng-container *ngIf="userChat.conversation_type === conversationsTypes.direct; else groupMessage">
          {{ userChat.name }}
        </ng-container>
        <ng-template #groupMessage>
          <div>{{ clients.length }} clients
            <span class="toggle-clients" (click)="toggleClients()">
              {{ isShowClients ? 'Hide' : 'Show' }} clients
            </span>
          </div>

          <div class="recipients" [@showHideClients]="isShowClients ? 'show' : 'hide'">
            <div class="clients">
              <mat-chip-list>
                <mat-chip
                  *ngFor="let client of clients"
                  class="member"
                  [removable]="true"
                  (removed)="removeClient(client.id)">
                  <span class="member__name">{{ client.short_name }}</span>
                  <mat-icon *ngIf="+type.value === messagesTypes.email" matChipRemove class="member__clear">clear</mat-icon>
                </mat-chip>
              </mat-chip-list>
            </div>
          </div>
        </ng-template>
      </app-form-field>

      <app-form-field label="Type*" fieldClasses="new-message type">
        <mat-select
          class="mc-form-field-input"
          panelClass="mc-select-panel"
          placeholder="Select"
          formControlName="type"
          (selectionChange)="changeType($event)">
          <mat-option *ngFor="let type of messagesTypesOptions" value="{{ type.value }}">
            {{ type.title }}
          </mat-option>
        </mat-select>
      </app-form-field>

      <app-form-field *ngIf="+type.value === messagesTypes.email" label="Theme*" fieldClasses="new-message">
        <input
          class="mc-form-field-input"
          type="text"
          appTrimInput
          placeholder="Theme"
          formControlName="theme"/>
      </app-form-field>

      <app-form-field label="Message*" fieldClasses="new-message textarea">
        <textarea
          class="mc-form-field-input"
          placeholder="Message"
          appTrimInput
          formControlName="message"
          autocomplete="off"
          maxlength="1000"
        ></textarea>
      </app-form-field>
    </form>
  </div>

  <div class="message__buttons">
    <div class="files">
      <div *ngFor="let file of loadedFiles" class="file">
        <span class="material-icons icon-close" (click)="removeAttachedFile(file.index)">close</span>
        <span class="file__name">{{ file.name }}</span>
      </div>
    </div>

    <div class="buttons">
      <button
        matRipple
        class="mc-button mc-button_red"
        (click)="close()">
        <span class="material-icons-two-tone btn-icon">delete</span>
        Delete
      </button>

      <app-file-upload (filesAdded)="addFiles($event)"></app-file-upload>

      <button
        matRipple
        class="mc-button mc-button_blue btn-send"
        [disabled]="!form.valid || isSending"
        (click)="send()">
        <span *ngIf="!isSending" class="material-icons-two-tone btn-icon">send</span>
        <mat-spinner
          *ngIf="isSending"
          class="spinner"
          diameter="16"
          strokeWidth="2"
        ></mat-spinner>
        Send
      </button>
    </div>
  </div>
</div>
