import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { throwError as observableThrowError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { HttpService } from '@app/core/services';
import { URLS } from '@app/shared/urls';
import { PaginatedResponse } from '@app/models/paginated-response.model';

import { UserType } from "@app/models/users/user-profile.model";

@Injectable({
  providedIn: 'root'
})
export class OpenPositionsService {

  constructor(
    private http: HttpService
  ) { }

  getVacancies(query: string): Observable<PaginatedResponse> {
    return <Observable<PaginatedResponse>>this.http
    .GET(`${ URLS.vacancy }?${ query }`)
    .pipe(
      catchError((error: HttpErrorResponse) => observableThrowError(error))
    );
  }

  getHRsList(): Observable<PaginatedResponse> {
    return <Observable<PaginatedResponse>>this.http
    .GET(`${ URLS.profile }?profile_types=${ UserType.HR }`)
    .pipe(
      catchError((error: HttpErrorResponse) => observableThrowError(error))
    );
  }

}
