import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError as observableThrowError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { HttpService } from '@app/core/services';
import { URLS } from '@app/shared/urls';
import { CommunicationSetup } from '@app/models/settings/communication.model';

@Injectable({
  providedIn: 'root'
})
export class CommunicationService {

  constructor(
    private http: HttpService
  ) { }

  getTwilio(): Observable<CommunicationSetup> {
    return <Observable<CommunicationSetup>> this.http
      .GET(`${ URLS.twilioSetup }`)
      .pipe(
        catchError((error: HttpErrorResponse) => observableThrowError(error))
      );
  }

  updateTwilio(data: CommunicationSetup): Observable<CommunicationSetup> {
    return <Observable<CommunicationSetup>> this.http
      .PUT(`${ URLS.twilioSetup }`, data)
      .pipe(
        catchError((error: HttpErrorResponse) => observableThrowError(error))
      );
  }
}
