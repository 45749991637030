import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError as observableThrowError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { HttpService } from './http/http.service';
import { URLS } from '@app/shared/urls';
import { PaginatedResponse } from '@app/models/paginated-response.model';
import { Allergy } from '@app/models/users/allergies.model';

@Injectable({
  providedIn: 'root'
})
export class AllergiesService {

  constructor(
    private http: HttpService
  ) { }

  getAllergies(query: string): Observable<PaginatedResponse> {
    return <Observable<PaginatedResponse>>this.http
    .GET(`${ URLS.allergy_info }${ query }`)
    .pipe(
      catchError((error: HttpErrorResponse) => observableThrowError(error))
    );
  }

  addAllergy(allergy: Allergy): Observable<Allergy> {
    return <Observable<Allergy>>this.http
    .POST(`${ URLS.allergy_info }`, allergy)
    .pipe(
      catchError((error: HttpErrorResponse) => observableThrowError(error))
    );
  }

  editAllergy(allergy: Allergy): Observable<Allergy> {
    return <Observable<Allergy>>this.http
    .PUT(`${ URLS.allergy_info }${ allergy.id }/`, allergy)
    .pipe(
      catchError((error: HttpErrorResponse) => observableThrowError(error))
    );
  }

  deleteAllergy(id: number): Observable<any> {
    return <Observable<any>>this.http
    .DELETE(`${ URLS.allergy_info }${ id }/`)
    .pipe(
      catchError((error: HttpErrorResponse) => observableThrowError(error))
    );
  }
}
