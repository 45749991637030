import { NgModule } from '@angular/core';
import { TrimInputDirective } from './trim-input.directive';

@NgModule({
  declarations: [
    TrimInputDirective,
  ],
  exports: [
    TrimInputDirective,
  ]
})
export class TrimInputModule {
}
