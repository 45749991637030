import { environment as env } from '../../environments/environment';

const host = `${ env.baseProtocol }${ env.baseHost }:${ env.basePort }${ env.basePath }`;
const wsHost = `${ env.baseWsProtocol }${ env.baseHost }:${ env.baseWsPort }`;

export const URLS = {
  socket: wsHost + '/frontend/',

  // ACCOUNTS
  login:                                       host + 'accounts/staff_login/',
  profile:                                     host + 'accounts/profile/',
  caregiver_preferences:                       host + 'accounts/caregiver_preferences/',
  caregiver_expense:                           host + 'accounts/caregiver_expense/',
  caregiver_education:                         host + 'accounts/caregiver_education/',
  caregiver_invoice_batch:                     host + 'accounts/caregiver_invoice_batch/',
  caregiver_invoice_batch_item:                host + 'accounts/caregiver_invoice_batch_item/',
  caregiver_payroll:                           host + 'accounts/caregiver_payroll/',
  availability_exclusions:                     host + 'accounts/availability_exclusion/',
  permissions:                                 host + 'accounts/permissions/',
  permission_template:                         host + 'accounts/permission_template/',
  training_school_info:                        host + 'accounts/training_school_info/',
  paycheck:                                    host + 'accounts/paycheck/',

  // COMMONS
  twilioSetup:                                 host + 'commons/twilio_setup/',
  attentions:                                  host + 'commons/attentions/',
  edi_provider:                                host + 'commons/edi_provider/',
  date_periods:                                host + 'commons/date_periods/',
  user_contact:                                host + 'commons/eci/',
  language:                                    host + 'commons/language/',
  basic_discipline:                            host + 'commons/basic_discipline/',
  billing_setup:                               host + 'commons/billing_setup/',
  discipline:                                  host + 'commons/discipline/',
  animal:                                      host + 'commons/animal/',
  diagnosis:                                   host + 'commons/diagnosis/',
  allergen:                                    host + 'commons/allergen/',
  allergy_info:                                host + 'commons/allergy_info/',
  reaction:                                    host + 'commons/reaction/',
  office:                                      host + 'commons/office/',
  company:                                     host + 'commons/company/',
  customization_setup:                         host + 'commons/customization_setup/',
  duty:                                        host + 'commons/duty/',
  duty_category:                               host + 'commons/duty_category/',
  document:                                    host + 'commons/document/',
  document_type:                               host + 'commons/doc_type/',
  upload_history:                              host + 'commons/upload_history/',
  payroll_setup:                               host + 'commons/payroll_setup/',
  payroll_template:                            host + 'commons/payroll_template/',
  assign_notification:                         host + 'commons/assign_notification/',
  patient_contact:                             host + 'commons/eci/',
  dictionary:                                  host + 'commons/dictionary/',
  history:                                     host + 'commons/history/',
  generic_file:                                host + 'commons/generic_file/',
  note:                                        host + 'commons/note/',
  settings:                                    host + 'commons/settings_section/',

  // PATIENTS
  patient:                                     host + 'patients/patient/',
  priority_code:                               host + 'patients/patient_pc/',
  evacuation_zone:                             host + 'patients/patient_ez/',
  mobility_status:                             host + 'patients/patient_ms/',
  evacuation_location:                         host + 'patients/patient_el/',
  electric_equipment_dependency:               host + 'patients/patient_eed/',
  patients_visit:                              host + 'patients/visit/',
  visit_precalculate_price:                    host + 'patients/visit_precalculate_price/',
  visit_weekly_scheduling:                     host + 'patients/visit_weekly_scheduling/',
  master_week:                                 host + 'patients/master_week/',
  master_week_assign:                          host + 'patients/master_week_assign/',
  master_week_blasting:                        host + 'patients/master_week_blasting/',
  master_week_available:                       host + 'patients/master_week_available/',
  dispatch_screen_visit:                       host + 'patients/dispatch_screen_visit/',
  employee:                                    host + 'patients/employee/',
  diagnosis_info:                              host + 'patients/diagnosis_info/',
  visit_detailed_info:                         host + 'patients/visit_detailed_info/',
  visit_blasting:                              host + 'patients/visit_blasting/',
  poc:                                         host + 'patients/poc/',
  poc_precalculate_duration:                   host + 'patients/poc_precalculate_duration/',
  patient_calendar:                            host + 'patients/patient_calendar/',
  patient_medical_appointment:                 host + 'patients/patient_medical_appointment/',

  // PAYERS
  payer:                                       host + 'payers/payer/',
  payer_pricing_discipline:                    host + 'payers/pricing_discipline/',
  payer_authorization:                         host + 'payers/authorization/',
  payer_service_calculate_hours:               host + 'payers/authorization/calculate_hours/',
  available_nurse_authorizations:              host + 'payers/authorization/available_nurse_authorizations/',
  payer_batch:                                 host + 'payers/payer_batch/',
  payer_batch_item:                            host + 'payers/payer_batch_item/',
  payer_invoice_batch:                         host + 'payers/payer_invoice_batch/',
  payer_invoice_claim:                         host + 'payers/payer_invoice_claim/',
  payer_invoice_claim_status:                  host + 'payers/payer_invoice_claim_status/',
  payer_private_payment:                       host + 'payers/payer_private_payment/',
  payer_edi_partner:                           host + 'payers/payer_edi_partner/',

  // PHYSICIANS
  physician:                                   host + 'physicians/physician/',

  // HR
  hr_profile:                                  host + 'hr/profile/',
  availability:                                host + 'hr/availability/',
  interview_calenar:                           host + 'hr/interview/calendar/',
  interview:                                   host + 'hr/interview/',
  scheduled_interviews:                        host + 'hr/interview/by_application/',
  vacancy:                                     host + 'hr/vacancy/',
  application:                                 host + 'hr/application/',
  schedule:                                    host + 'hr/schedule/',
  pre_generate_interview_link:                 host + 'hr/pre_generate_interview_link/',
  generate_interview_link:                     host + 'hr/generate_interview_link/',
  resume:                                      host + 'hr/resume/',

  // Notifications
  notifications:                               host + 'notifications/dashboard_notifications/',

  // Communication
  messages:                                    host + 'communication/messages/',
  mailing:                                     host + 'communication/mailing/',

  // Reports
  generate_report:                             host + 'reports/generate_report/',
  report_tempate:                              host + 'reports/report_template/',


  KEY: 'AIzaSyAOiE-bWqw9sz30T-L6cMuaEGIFY0JQBm8',
  googleApisAutocomplete: 'https://maps.googleapis.com/maps/api/place/autocomplete/',
  googlePlaceDetails: 'https://maps.googleapis.com/maps/api/place/details/',
};
