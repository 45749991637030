import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { WsData, WsEvent } from './ws.models';

export function getWsData<T = any>(): (source$: Observable<MessageEvent>) => Observable<WsData<T>> {
  return source$ => source$.pipe(map((message) => JSON.parse(message.data) as WsData<T>));
}

export function filterWsEvents<T = unknown>(events: WsEvent[]): (source$: Observable<MessageEvent>) => Observable<T> {
  return source$ =>
    source$.pipe(
      getWsData<T>(),
      filter(data => events.includes(data.event)),
      map(i => i.payload),
    );
}
