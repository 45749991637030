import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { HttpService } from '@app/core/services/http/http.service';
import { Notification, NotificationParams, NotificationPriority, NotificationUnreadCountResponse } from '@app/models/notifications/notification.model';
import { PaginatedResponse } from '@app/models/paginated-response.model';
import { URLS } from '@app/shared/urls';

@Injectable({
  providedIn: 'root'
})
export class NotificationCenterService {

  notificationsCount: Subject<NotificationUnreadCountResponse> = new Subject<NotificationUnreadCountResponse>();

  constructor(private http: HttpService) { }

  getNotifications(params: NotificationParams): Observable<PaginatedResponse<Notification>> {
    return this.http.GET<PaginatedResponse<Notification>>(URLS.notifications, params);
  }

  deleteNotification(id: number): Observable<void> {
    return this.http.DELETE<void>(`${ URLS.notifications }${ id }/`);
  }

  markAsRead(id: number, data: { mark_as_read: boolean }): Observable<Notification> {
    return this.http.PATCH<Notification>(`${ URLS.notifications }${ id }/`, data);
  }

  clearAllNotifications(params: { priority_type: NotificationPriority }): Observable<void> {
    return this.http.DELETE<void>(`${ URLS.notifications }clear_all/`, params);
  }

  getUnreadCount(): Observable<NotificationUnreadCountResponse> {
    return this.http.GET<NotificationUnreadCountResponse>(`${ URLS.notifications }unread_count/`);
  }
}
