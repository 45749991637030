import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { HttpService } from '@app/core/services';
import {
  MasterWeekAssign,
  MasterWeekAssignApprove,
  MasterWeekAssignedVisitDetail,
  MasterWeekAssignedVisits,
  MasterWeekBlastingCreation,
  MasterWeekBlastingStatus,
  MasterWeekManualAssignAction,
  MasterWeekResponseSuccess
} from '@app/models/patient/masterweek.model';
import { URLS } from '@app/shared/urls';
import { PaginatedResponse } from '@app/models/paginated-response.model';
import { MWAssignApproveAction, NestedBlastedVisit, BlastedVisit } from '@app/models/blasting/blasting.model';
import { ResponseSuccess } from '@app/models/response.model';
import { OfferProjectedHoursResponse } from '@app/models/blasting/blasting-offer-projected-hours.model';
import { PaginationParams } from "@app/shared/interfaces/pagination.class";

@Injectable({
  providedIn: 'root'
})
export class AssignBlastingService {

  constructor(
    private http: HttpService
  ) { }

  getMasterWeekAssign(masterWeekId: number): Observable<MasterWeekAssign> {
    return this.http.GET<MasterWeekAssign>(`${ URLS.master_week_assign }${ masterWeekId }/`);
  }

  getAssignBlastingCaregivers(masterWeekId: number, visits: string, search?: string, limit: number = 100): Observable<PaginatedResponse> {
    let query = `?mw_visits=${ visits }&limit=${ limit }`;
    if (search) {
      query += `&lookup_field=full_name&search=${ search }`;
    }
    return this.http.GET<PaginatedResponse>(`${ URLS.master_week }${ masterWeekId }/available_caregivers/${ query }`);
  }

  assignCaregiversForVisits(masterWeekId: number, selectedVisits: MasterWeekManualAssignAction): Observable<MasterWeekResponseSuccess> {
    return this.http.POST<MasterWeekResponseSuccess>(`${ URLS.master_week }${ masterWeekId }/change_caregivers_for_visits/`, selectedVisits);
  }

  createMasterWeekBlasting(data: MasterWeekBlastingCreation): Observable<MasterWeekBlastingCreation> {
    return this.http.POST<MasterWeekBlastingCreation>(`${ URLS.master_week_blasting }`, data);
  }

  cancelAssignedVisits(masterWeekId: number, masterWeekVisitIds: string): Observable<MasterWeekResponseSuccess> {
    return this.http.POST<MasterWeekResponseSuccess>(`${ URLS.master_week_assign }${ masterWeekId }/cancel_assign/?mw_visits=${ masterWeekVisitIds }`);
  }

  getAssignedVisits(masterWeekId: number): Observable<MasterWeekAssignedVisits> {
    return this.http.GET<MasterWeekAssignedVisits>(`${ URLS.master_week_assign }${ masterWeekId }/assigned/`);
  }

  getAssignedVisitDetail(masterWeekId: number, query: string): Observable<MasterWeekAssignedVisitDetail> {
    return this.http.GET<MasterWeekAssignedVisitDetail>(`${ URLS.master_week_assign }${ masterWeekId }/assigned_detail/?${ query }`);
  }

  getOffersBlastedVisitList(params: PaginationParams & { master_week: number }): Observable<PaginatedResponse<BlastedVisit>> {
    return this.http.GET(URLS.master_week_blasting, params);
  }

  getNestedOffersBlastedVisit(visitId: number, params?: object): Observable<NestedBlastedVisit[]> {
    return this.http.GET<NestedBlastedVisit[]>(`${ URLS.master_week_blasting }${ visitId }/offers/`, params);
  }

  changeVisitBlastingStatus(visitId: number, data): Observable<MasterWeekBlastingStatus> {
    return this.http.PUT<MasterWeekBlastingStatus>(`${ URLS.master_week_blasting }${ visitId }/`, data);
  }

  approveOfferBlastedVisit(blastedVisitId: number, data): Observable<MasterWeekAssignApprove> {
    return this.http.POST<MasterWeekAssignApprove>(`${ URLS.master_week_blasting }${ blastedVisitId }/approve_offer/`, data);
  }

  bulkApproveOfferBlastedVisit(blastedVisitId: number, data: MWAssignApproveAction[]): Observable<ResponseSuccess> {
    return this.http.POST<ResponseSuccess>(`${ URLS.master_week_blasting }${ blastedVisitId }/bulk_approve_offer/`, data);
  }

  rejectOfferBlastedVisit(blastedVisitId: number, data): Observable<MasterWeekAssignApprove> {
    return this.http.POST<MasterWeekAssignApprove>(`${ URLS.master_week_blasting }${ blastedVisitId }/reject_offer/`, data);
  }

  offerProjectedHours(blastedVisitId: number, queryParams?: MWAssignApproveAction): Observable<OfferProjectedHoursResponse> {
    return this.http.GET<OfferProjectedHoursResponse>(`${ URLS.master_week_blasting }${ blastedVisitId }/offer_projected_hours/`, queryParams);
  }
}
