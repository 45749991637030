<app-loading *ngIf="isLoading" class="loading"></app-loading>

<div *ngIf="visitData && !isLoading" class="visit-details">
  <form
    class="visit-details__form form"
    novalidate
    autocomplete="off"
    [formGroup]="form">

    <section class="visit-info">
      <div class="section-title">{{ 'visitDetails.tabVisit_group_visitInfo' | translate }}</div>

      <div class="form__item">
        <span class="form__label">{{ 'visitDetails.tabVisit_field_scheduledTime' | translate }}</span>
        <div class="form__value" [matTooltip]="'visitDetails.tabVisit_field_scheduledTime_tooltip' | translate">
          {{ visitData.start_date_time | date: 'hh:mm a' }} - {{ visitData.end_date_time | date: 'hh:mm a' }}
        </div>
      </div>

      <div class="form__item">
        <span class="form__label">{{ 'visitDetails.tabVisit_field_visitStartTime' | translate }}</span>
        <div class="form__value editable-field status-field">

          <div [matTooltip]="'visitDetails.tabVisit_field_makeClockIn_tooltip' | translate">
            <img
              src="/assets/icons/user-clock.svg"
              alt="change status"
              class="icon-action icon-blue"
              [class.disabled]="visitStatus !== visitStatusesKeys.scheduled && visitStatus !== visitStatusesKeys.missed && visitStatus !== visitStatusesKeys.enRoute"
              (click)="setStatusAsInProgress()">
          </div>

          <app-date-time-field
            [dateTooltip]="'visitDetails.tabVisit_field_visitStartTime_tooltip' | translate"
            [editTooltip]="'visitDetails.tabVisit_field_visitStartTime_editTooltip' | translate"
            [initialDateTime]="visitStart.value"
            (dateTimeChanged)="visitStartTimeChange($event)"
          ></app-date-time-field>
        </div>
      </div>

      <div class="form__item">
        <span class="form__label">{{ 'visitDetails.tabVisit_field_visitEndTime' | translate }}</span>
        <div class="form__value editable-field status-field">
          <div [matTooltip]="'visitDetails.tabVisit_field_makeClockOut_tooltip' | translate">
            <img
              src="/assets/icons/user-clock.svg"
              alt="change status"
              class="icon-action icon-green"
              [class.disabled]="visitStatus !== visitStatusesKeys.inProgress"
              (click)="setStatusAsCompleted()">
          </div>

          <app-date-time-field
            [dateTooltip]="'visitDetails.tabVisit_field_visitEndTime_tooltip' | translate"
            [editTooltip]="'visitDetails.tabVisit_field_visitEndTime_editTooltip' | translate"
            [initialDateTime]="visitEnd.value"
            (dateTimeChanged)="visitEndTimeChange($event)"
          ></app-date-time-field>
        </div>
      </div>

      <div class="form__item">
        <span class="form__label">{{ "visitDetails.tabVisit_field_evvCallInOut" | translate }}</span>
        <div class="form__value">
          <div>
            <ng-container *ngIf="visitData.evv_start_date_time">
              <span class="clock-in-out"> {{ 'visitDetails.tabVisit_field_clock-in' | translate }} </span>
              <svg-icon src="/assets/icons/{{ getEvvIconName(visitData.evv_start_date_time_method) }}.svg"
                        [class]="getEvvIconName(visitData.evv_start_date_time_method)"
                        matTooltipClass="mat-tooltip_pre-line"
                        matTooltip="{{ 'visitDetails.tabVisit_evv-gps' | translate }}: {{ visitData.evv_start_location_point?.lat || '-' }}, {{ visitData.evv_start_location_point?.lng }}
                      {{ 'visitDetails.tabVisit_evv-address' | translate }}: {{ visitData.evv_start_location_address || '-' }}"
              ></svg-icon>
              <span class="evv-date"> {{ visitData.evv_start_date_time | date: 'MM/dd/YYYY hh:mm a' }} </span>
            </ng-container>

            <ng-container *ngIf="visitData.evv_end_date_time">
              <span class="clock-in-out"> {{ 'visitDetails.tabVisit_field_clock-out' | translate }} </span>
              <svg-icon src="/assets/icons/{{ getEvvIconName(visitData.evv_end_date_time_method) }}.svg"
                        [class]="getEvvIconName(visitData.evv_end_date_time_method)"
                        matTooltipClass="mat-tooltip_pre-line"
                        matTooltip="{{ 'visitDetails.tabVisit_evv-gps' | translate }}: {{ visitData.evv_end_location_point?.lat || '-' }}, {{ visitData.evv_end_location_point?.lng }}
                        {{ 'visitDetails.tabVisit_evv-address' | translate }}: {{ visitData.evv_end_location_address || '-' }}"
              ></svg-icon>
            </ng-container>

            <span class="evv-date"> {{ visitData.evv_end_date_time | date: 'MM/dd/YYYY hh:mm a' }} </span>
          </div>

          <div *ngIf="!visitData.evv_start_date_time && !visitData.evv_end_date_time">-</div>
        </div>
      </div>

      <div class="form__item">
        <span class="form__label">{{ "visitDetails.tabVisit_field_overTime" | translate }}</span>
        <div class="form__value" [matTooltip]="'visitDetails.tabVisit_field_overTime_tooltip' | translate">
          {{ doubleNumber(visitData.overtime_hours) }}:{{ doubleNumber(visitData.overtime_minutes) }}
        </div>
      </div>
    </section>

    <section class="visit-verification">
      <div class="section-title">{{ "visitDetails.tabVisit_group_visitVerification" | translate }}</div>

      <div class="form__item">
        <span class="form__label">{{ "visitDetails.tabVisit_field_supervisor" | translate }}</span>
        <div class="form__value select">
          <ng-select
            class="app-ng-select"
            bindLabel="label"
            bindValue="value"
            appendTo="body"
            appearance="outline"
            formControlName="supervisor"
            placeholder="Select"
            [matTooltip]="'visitDetails.tabVisit_field_supervisor_tooltip' | translate"
            [items]="supervisorList"
            [clearable]="false"
            [searchable]="false">
          </ng-select>
        </div>
      </div>

      <div class="form__item">
        <span class="form__label">{{ "visitDetails.tabVisit_field_verifiedBy" | translate }}</span>
        <div class="form__value select">
          <ng-select
            class="app-ng-select"
            bindLabel="label"
            bindValue="value"
            appendTo="body"
            appearance="outline"
            formControlName="verified_by"
            placeholder="Select"
            [matTooltip]="'visitDetails.tabVisit_field_verifiedBy_tooltip' | translate"
            [items]="verifiedList"
            [clearable]="false"
            [searchable]="false">
          </ng-select>
        </div>
      </div>

      <div class="form__item">
        <span class="form__label">{{ "visitDetails.tabVisit_field_timeVerified" | translate }}</span>
        <div class="form__value editable-field">
          <app-date-time-field
            [editTooltip]="'visitDetails.tabVisit_field_timeVerified_editTooltip' | translate"
            [initialDateTime]="timeVerified.value"
            (dateTimeChanged)="verificationDateChange($event)"
          ></app-date-time-field>
        </div>
      </div>
    </section>

    <section class="notes">
      <div class="section-title">{{ "visitDetails.tabVisit_group_notes" | translate }}</div>

      <div class="form__item">
        <span class="form__label">{{ "visitDetails.tabVisit_field_reason" | translate }}</span>
        <div class="form__value select">
          <ng-select
            class="app-ng-select"
            bindLabel="value"
            bindValue="id"
            appendTo="body"
            appearance="outline"
            formControlName="reason"
            placeholder="Select"
            [matTooltip]="'visitDetails.tabVisit_field_reason_tooltip' | translate"
            [items]="reasonList"
            [clearable]="false"
            [searchable]="false"
            (change)="changeReason($event.id)">
          </ng-select>
        </div>
      </div>

      <div class="form__item">
        <span class="form__label">{{ "visitDetails.tabVisit_field_actionTaken" | translate }}</span>
        <div class="form__value select">
          <ng-select
            class="app-ng-select"
            bindLabel="value"
            bindValue="id"
            appendTo="body"
            appearance="outline"
            formControlName="action_taken"
            placeholder="Select"
            [matTooltip]="'visitDetails.tabVisit_field_actionTaken_tooltip' | translate"
            [items]="actionTakenList"
            [clearable]="false"
            [searchable]="false"
            (change)="changeActionsTaken($event.id)">
          </ng-select>
        </div>
      </div>

      <div class="form__item notes">
        <span class="form__label">{{ "visitDetails.tabVisit_field_note" | translate }}</span>
        <div class="form__value textarea-field">
          <textarea
            placeholder="Input"
            class="form-control"
            formControlName="notes"
            autocomplete="off"
            maxlength="1000"
            [matTooltip]="'visitDetails.tabVisit_field_note_tooltip' | translate"
            (input)="checkNotes()"
          ></textarea>

          <ng-container *ngIf="showAddNotesBtns">
            <button
              matRipple
              class="action__btn"
              (click)="addNotes()">
              <mat-icon class="material-icons-two-tone icon-green">done</mat-icon>
            </button>
            <button matRipple class="action__btn" (click)="cancelNotes()">
              <mat-icon class="material-icons-two-tone icon-red">close</mat-icon>
            </button>
          </ng-container>
        </div>
      </div>

      <div class="notes-table">
        <div class="notes-table__title">{{ "visitDetails.tabVisit_field_lastNotes" | translate }}</div>
        <div *ngIf="!notesList.length" class="no-data">{{ "visitDetails.tabVisit_notification_noLastNotes" | translate }}</div>
        <div *ngIf="notesList.length" class="notes-table__row header">
          <span class="notes-table__date">{{ "visitDetails.tabVisit_field_date" | translate }}</span>
          <span class="notes-table__reason">{{ "visitDetails.tabVisit_field_reason" | translate }}</span>
          <span class="notes-table__action-taken">{{ "visitDetails.tabVisit_field_actionTaken" | translate }}</span>
          <span class="notes-table__notes">{{ "visitDetails.tabVisit_field_notes" | translate }}</span>
          <span class="notes-table__user">{{ "visitDetails.tabVisit_field_user" | translate }}</span>
        </div>
        <div *ngFor="let note of notesList" class="notes-table__row">
          <span class="notes-table__date">{{ note.date | date: 'MM/dd/YYYY hh:mm a' }}</span>
          <span class="notes-table__reason">{{ note.reason }}</span>
          <span class="notes-table__action-taken">{{ note.action_taken }}</span>
          <span class="notes-table__notes">{{ note.notes }}</span>
          <span class="notes-table__user">{{ note.user }}</span>
        </div>
      </div>
    </section>

    <section class="poc">
      <div class="section-title poc__head" (click)="showPOC()" [class.poc-open]="isShowPOC">
        <span class="poc__title">{{ "visitDetails.tabVisit_group_planOfCare" | translate }}</span>
        <mat-icon class="material-icons poc__icon">arrow_drop_down</mat-icon>
      </div>

      <div class="poc__section" [@showHidePOC]="isShowPOC ? 'show' : 'hide'">
        <div *ngIf="!pocList?.length" class="no-data">{{ "visitDetails.tabVisit_notification_noPlanOfCare" | translate }}</div>
        <ng-container *ngIf="pocList?.length">
          <div class="poc__amount">
            <div>
              {{ "visitDetails.tabVisit_field_dutySheetPOC" | translate }}:
              <span class="poc__value"
                    [matTooltip]="'visitDetails.tabVisit_field_dutySheetPOC_tooltip' | translate"
              >{{ pocList?.length }}</span>
            </div>
            <div>
              {{ "visitDetails.tabVisit_field_visitDuration" | translate }}:
              <span class="poc__value" [matTooltip]="'visitDetails.tabVisit_field_visitDuration_tooltip' | translate">({{ visitsDuration }})</span>
            </div>
            <div>{{ "visitDetails.tabVisit_field_total" | translate }}:
              <span class="poc__value"
                    [matTooltip]="'visitDetails.tabVisit_field_total_dutiesTooltip' | translate">{{ pocList?.length }} ({{ visitsDuration }})</span>
            </div>
          </div>

          <div class="poc__table">
            <div class="poc__row header">
              <span class="poc__id">{{ "visitDetails.tabVisit_pocTable_column_id" | translate }}</span>
              <span class="poc__category">{{ "visitDetails.tabVisit_pocTable_column_category" | translate }}</span>
              <span class="poc__duty">{{ "visitDetails.tabVisit_pocTable_column_duty" | translate }}</span>
              <span class="poc__minutes">{{ "visitDetails.tabVisit_pocTable_column_minutes" | translate }}</span>
              <span class="poc__done">{{ "visitDetails.tabVisit_pocTable_column_done" | translate }}</span>
              <span class="poc__not-done">{{ "visitDetails.tabVisit_pocTable_column_notDone" | translate }}</span>
            </div>

            <div *ngFor="let poc of pocList" class="poc__row">
              <span class="poc__id" [matTooltip]="'visitDetails.tabVisit_pocTable_column_id_tooltip' | translate">{{ poc.id }}</span>
              <span class="poc__category" [matTooltip]="'visitDetails.tabVisit_pocTable_column_category_tooltip' | translate">{{ poc.categoryName }}</span>
              <span class="poc__duty" [matTooltip]="'visitDetails.tabVisit_pocTable_column_duty_tooltip' | translate">{{ poc.dutyName }}</span>
              <span class="poc__minutes" [matTooltip]="'visitDetails.tabVisit_pocTable_column_minutes_tooltip' | translate">{{ poc.minutes }}</span>
              <span class="poc__done">
              <mat-checkbox
                class="app-checkbox"
                disableRipple
                [checked]="poc.done"
                [matTooltip]="'visitDetails.tabVisit_pocTable_column_done_tooltip' | translate"
                (change)="changePOCCheckbox($event.checked, poc.id, 'done')"
              ></mat-checkbox>
            </span>
              <span class="poc__not-done">
              <mat-checkbox
                class="app-checkbox"
                disableRipple
                [checked]="poc.not_done"
                [matTooltip]="'visitDetails.tabVisit_pocTable_column_notDone_tooltip' | translate"
                (change)="changePOCCheckbox($event.checked, poc.id, 'not_done')"
              ></mat-checkbox>
            </span>
            </div>
          </div>
        </ng-container>
      </div>
    </section>
  </form>

  <div class="visit-details__save">
    <button
      matRipple
      class="app-button app-button_blue app-button_spinner"
      [disabled]="!form.valid || form.pristine || isSaving"
      [matTooltip]="'visitDetails.tabVisit_buttonSave_tooltip' | translate"
      (click)="save()">
      <mat-spinner *ngIf="isSaving" class="spinner" diameter="14" strokeWidth="1"></mat-spinner>
      {{ 'buttons.save' | translate }}
    </button>
  </div>
</div>
