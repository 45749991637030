import { Pipe, PipeTransform } from '@angular/core';
import { pad } from '@app/shared/helper';

@Pipe({
  name: 'duration'
})
export class DurationPipe implements PipeTransform {
  transform(durationInMinutes: number, hours?: number): string {
    const hoursIntoMinutes = (hours || 0) * 60;

    return DurationPipe.durationInMinutesIntoTime((durationInMinutes || 0) + hoursIntoMinutes);
  }

  static durationInMinutesIntoTime(durationInMinutes: number): string {
    const hours = Math.floor(durationInMinutes / 60);
    const minutes = durationInMinutes % 60;

    return `${ pad(hours) }:${ pad(minutes) }`;
  }
}
