<div class="language-switcher">
	<div
		#languageSwitcherMenu="matMenuTrigger"
		[matMenuTriggerFor]="languageSwitcher"
		[matMenuTriggerData]="menuData"
		(menuClosed)="(true)"
		class="language-switcher__trigger flex">
		<span class="language-switcher__name">
			{{ languageService.currentLang.name | translate }}
		</span>

		<span class="language-switcher__arrow flex">
			<mat-icon *ngIf="!languageSwitcherMenu.menuOpen" class="material-icons icon-blue">expand_more</mat-icon>
			<mat-icon *ngIf="languageSwitcherMenu.menuOpen" class="material-icons icon-blue">expand_less</mat-icon>
		</span>
	</div>

	<mat-menu #languageSwitcher="matMenu" [overlapTrigger]="false" xPosition="before" class="language-switcher__menu">
		<button
			*ngFor="let lang of languages"
			[style.width.px]="menuWidth"
			mat-menu-item
			class="language-switcher__item"
			(click)="changeLang(lang)"
			[ngClass]="{ 'language-switcher__item_active': languageService.currentLang.langCode === lang.langCode }">
			{{ lang.name | translate }}

			<mat-icon *ngIf="languageService.currentLang.langCode === lang.langCode" class="material-icons">
				check
			</mat-icon>
		</button>
	</mat-menu>
</div>
