export interface Notification {
  id: number;
  notification_type: NotificationType;
  notification_type_value: string;
  notification_subtype: NotificationSubType;
  notification_subtype_value: string;
  priority_type: NotificationPriority;
  priority_type_value: string;
  is_read: boolean;
  message: string;
  created: string;
  receivers: any;
  object_id: number;
  redirect_payload: object;
}

export interface NotificationUnreadCountResponse {
  unread_regular_count: number;
  unread_regular_ids: number[];
  unread_critical_count: number;
  unread_critical_ids: number[];
}

export interface NotificationDay {
  date: string;
  notifications: Notification[];
}

export enum NotificationType {
  Visit,
  Blasting,
  Patient,
  User,
  Report
}

export enum NotificationSubType {
  MissedVisit,
  CanceledVisit, 
  NotClockoutVisit,
  NotClockinVisit,
  AcceptedOffer,
  AcceptedOfferAll,
  AuthTimeExpired,
  AuthTimeExpire2D,
  AuthTimeExpire1W,
  MwAppointment1D,
  MwAppointment3D,
  MwAppointment1W,
  ReportSharing,
  VisitOvertime,
  VisitOverlapping
}

export enum NotificationPriority {
  Regular,
  Critical
}

export interface NotificationParams {
  limit?: number;
  offset?: number;
  notification_type?: NotificationType[];
  priority_type?: NotificationPriority;
}

export interface NotificationDialogData {
  priority: NotificationPriority;
}