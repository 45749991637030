import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ConnectionPositionPair } from '@angular/cdk/overlay';

import { CONNECTION_POSITION } from '@app/shared/components/overlay-toggle-button/overlay-toggle-button.constants';

@Component({
  selector: 'app-overlay-toggle-button',
  templateUrl: './overlay-toggle-button.component.html',
  styleUrls: ['./overlay-toggle-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OverlayToggleButtonComponent implements OnInit {
  isContentVisible = false;
  @Input() icon = 'history';
  @Input() positionPairs: ConnectionPositionPair[] = [CONNECTION_POSITION.RIGHT, CONNECTION_POSITION.LEFT];
  @Output() opened = new EventEmitter<void>();
  @Output() closed = new EventEmitter<void>();

  constructor() { }

  ngOnInit(): void {
  }

  openContent(): void {
    this.isContentVisible = true;
    this.opened.emit();
  }

  closeContent(): void {
    this.isContentVisible = false;
    this.closed.emit();
  }
}
