<input class="input"
       type="text"
       [value]="searchText"
       [placeholder]="placeholder | translate"
       [matAutocomplete]="auto"
       [class.invalid]="invalid"
       [disabled]="disabled"
       (blur)="onBlur()"
       (input)="onSearch($event.target.value)"
       (focus)="onFocus()">

<svg-icon *ngIf="!disabled" class="reset" src="/assets/icons/close_24dp.svg" (click)="reset()"></svg-icon>

<mat-autocomplete #auto="matAutocomplete"
                  classList="app-autocomplete-field-panel"
                  [displayWith]="findOptionLabel.bind(this)"
                  (optionSelected)="selectOption($event.option.value)">

  <ng-container *ngIf="isFilledRequiredSymbolsLength; else symbolsCountTip">
    <mat-option *ngIf="loading" class="option option_loading" disabled>
      <mat-spinner diameter="24"></mat-spinner>
    </mat-option>

    <mat-option *ngIf="options.length === 0 && !loading" class="option option_no-data" disabled>
      {{ 'commonNotifications.noResultsFound' | translate }}
    </mat-option>

    <ng-container *ngIf="!loading">

      <mat-option *ngFor="let option of options; trackBy: trackByOption"
                  class="option"
                  [value]="option[this.bindValue]">
        {{ option[this.bindLabel] }}
      </mat-option>
    </ng-container>
  </ng-container>

  <ng-template #symbolsCountTip>
    <mat-option class="option option_symbols-hint" disabled>
      {{ 'autocomplete.typeMoreSymbols' | translate: { count:  minSearchSymbols - searchText?.length } }}
    </mat-option>
  </ng-template>

</mat-autocomplete>
