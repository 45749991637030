<div class="form__item">
  <span class="form__label bold"> {{ 'visitDetails.travelForm_time' | translate }} </span>
  <div class="form__value">
    <mat-slide-toggle #travelIncludedControl="ngControl"
                      formControlName="active"
                      [disabled]="disabled"
    ></mat-slide-toggle>
  </div>
</div>

<ng-container *ngIf="travelIncludedControl.value">
  <div class="form__item">
    <span class="form__label"> {{ 'visitDetails.travelForm_timeDuration' | translate }} </span>
    <div class="form__value" [class.editable-field]="!disabled">
      <app-editable-content #travelTimeEditor formControlName="quantity" [disabled]="disabled">
        <div editContent>
          <app-time-duration-field [formControl]="travelTimeEditor.control"
                                   [maxHours]="9"
          ></app-time-duration-field>
        </div>

        <span viewContent>{{ travelTimeEditor.control.value | duration }}</span>
      </app-editable-content>
    </div>
  </div>

  <div class="form__item">
    <span class="form__label"> {{ 'visitDetails.travelForm_timeRates' | translate }} </span>

    <div class="form__value" [class.editable-field]="rateEditable && !disabled">
      <app-editable-content #quantityEditor formControlName="rate" [disabled]="!rateEditable || disabled">
        <div editContent>
          $<app-number-field [formControl]="quantityEditor.control"></app-number-field>
        </div>

        <span viewContent class="text-lowercase">
          {{ ( quantityEditor.control.value || 0 ) | currency }}/{{ 'common.hour' | translate }}
        </span>
      </app-editable-content>
    </div>
  </div>

  <div class="form__item amount">
    <span class="form__label"> {{ 'visitDetails.travelForm_timeExpenseTotal' | translate }} </span>
    <div class="form__value">
      {{ (travelInfo?.total || 0) | currency }}
    </div>
  </div>
</ng-container>
