import {
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';

@Directive({
  selector: '[appMapResize]'
})
export class MapResizableDirective implements OnInit, OnDestroy {
  @Output() widthChanged = new EventEmitter<number>();
  @Output() heightChanged = new EventEmitter<number>();
  @Input() minWidth = 400;
  @Input() maxWidth = 1200;
  @Input() minHeight = 200;
  @Input() public resize: boolean = true;

  private nodes: HTMLElement[] = [];
  private data: { x: number, y: number, rect: DOMRect, direction: string };

  constructor(@Inject(ElementRef) private element: ElementRef) {
    this.mousemove = this.mousemove.bind(this);
    this.mouseup = this.mouseup.bind(this);
  }

  mousemove(e): void {
    if (this.data) {
      let { height, width, top, left } = this.data.rect;
      let style = this.element.nativeElement.style;
      let offsetY = this.data.y - e.clientY;
      let offsetX = this.data.x - e.clientX;
      let set: { [key: string]: number } = {};
      switch (this.data.direction) {
        // case 'top':
        //   set.height = height + offsetY;
        //   set.top = top - offsetY;
        //   break;
        case 'bottom':
          set.height = height - offsetY;
          this.heightChanged.emit(height - offsetY);
          break;
        // case 'left':
        //   set.width = width + offsetX;
        //   set.left = left - offsetX;
        //   break;
        case 'right':
          set.width = width - offsetX;
          this.widthChanged.emit(width - offsetX);
      }
      if (set.width < this.minWidth || set.width > this.maxWidth) {
        delete set.width;
        delete set.left;
      }
      if (set.height < this.minHeight) {
        delete set.height;
        delete set.top;
      }
      Object.entries(set).forEach(([name, value]) => {
        style[name] = value + 'px';
      });
    }
  }

  createNode(side): void {
    let node = document.createElement('div');
    node.classList.add('border-' + side, 'border');
    this.element.nativeElement.appendChild(node);
    this.nodes.push(node);
  }

  ngOnInit(): void {
    ['top', 'left', 'right', 'bottom'].forEach(this.createNode.bind(this));
    window.addEventListener('mousemove', this.mousemove, { passive: true });
    this.element.nativeElement.classList.add('resize');
    window.addEventListener('mouseup', this.mouseup, { passive: true });
  }

  @HostListener('mousedown', ['$event'])
  mousedown(e): void {
    if (e.target.classList.contains('border') && this.resize) {
      let rect = this.element.nativeElement.getBoundingClientRect();
      this.data = {
        x: e.clientX,
        y: e.clientY,
        rect,
        direction: e.target.className.match(/border-([^ ]+)/)[1]
      };
      e.preventDefault();
    } else {
      delete this.data;
    }
  }
  mouseup(e): void {
    delete this.data;
  }

  ngOnDestroy(): void {
    this.nodes.forEach(n => n.remove());
    window.removeEventListener('mousemove', this.mousemove);
    window.removeEventListener('mouseup', this.mouseup);
  }
}
