export interface HistoryChange {
  change_type_value: string;
  change_date_time: string;
  user_short_name: string;
}

export interface HistoryChangeEntry extends HistoryChange {
  id: number;
  change_type: number;
  message: string;
  user_profile_type_value: string;
}

export type NullishString = string | null;

export enum ComplianceHistoryGroup {
  Compliance = 'compliance',
  CriminalBackground = 'criminalbackground',
}

export interface GetComplianceFieldHistory {
  fields: ComplianceFieldsWithHistory[] | ComplianceFieldsWithHistory;
  id: number;
  groupName: ComplianceHistoryGroup;
}

export type LoadComplianceFieldHistory = Pick<GetComplianceFieldHistory, 'fields'> & Partial<GetComplianceFieldHistory>;

export interface CriminalBackgroundDetails {
  id: number;
  compliance?: number;
  send_out: string;
  received: string;
  result_value: string;
  result_files_details: NewComplianceFileInfo[];
}

export type NewCriminalBackgroundDetails =
  Omit<CriminalBackgroundDetails, 'id' | 'result_files_details' | 'result_value'> &
  { result_files_data: NewComplianceFileInfo[], result: number };

export interface NewCertificate {
  school: number;
  discipline: number;
  certification_date: string;
  instructor: string;
  verified: boolean;
  verification_date: string;
  default: boolean;
  certificate_files_data: NewComplianceFileInfo[];
  compliance: number;
}

export interface FileSplitCompliance {
  nonFileFields: NewGeneralCompliance;
  fileFields: NewComplianceFileInfo[];
}

export type NewCertificateForm = Omit<NewCertificate, 'certificate_files_data'>
  & { certificate_files: File[] };

export interface Certificate extends Omit<NewCertificate, 'certificate_files_data' | 'school' | 'discipline'> {
  id: number;
  status: number;
  status_value: string;
  certificate_files_details: ComplianceFileInfo[];
  school_name: string;
  discipline_name: string;
}

export interface CertificateFileDeleteEvent<T extends Certificate> {
  id: T['id'];
  fileInfo: T['certificate_files_details'][number];
}

export interface GeneralCompliance {
  id: number;
  a_b_document_value: NullishString;
  a_b_document: number;
  c_document_value: NullishString;
  c_document: number;
  clinical_skills: NullishString;
  criminal_background_details: CriminalBackgroundDetails[];
  flu_shot: number[];
  physical_shot: number[];
  hire_date: NullishString;
  expiration: NullishString;
  ninety_days_sv_visit_date: NullishString;
  notes: NullishString;
  orientation: NullishString;
  performance_review: NullishString;
  reference_1: number[];
  reference_2: string;
  sexual_harassment_training: NullishString;
  sv_visit_date: NullishString;
  training_school_info_details: Certificate[];
  verified: boolean;
  compliance_files_details: ComplianceFileInfo[];
}

export type NewGeneralCompliance = Omit<{
  [K in keyof GeneralCompliance as K extends `${ infer P }_details` ? `${ P }_data` : K]: GeneralCompliance[K]
}, 'compliance_files_data' | 'id' | 'criminal_background_data'> &
  { compliance_files_data: NewComplianceFileInfo[], criminal_background_data: NewCriminalBackgroundDetails[] };

export type ComplianceFieldsWithHistory = Extract<
  keyof GeneralCompliance, 'hire_date' | 'sv_visit_date' | 'ninety_days_sv_visit_date' | 'clinical_skills' | 'orientation'
  | 'performance_review' | 'sexual_harassment_training' | 'flu_shot' | 'physical_shot' | 'reference_1'
> | 'received';

type FileField = {
  file_origin_name: string;
  upload_path_key: string;
};

export type NewComplianceFileInfo = FileField & ({ file: NullishString; } | { id: number; }); // id is only used for files from backend

export type ComplianceFileInfo = {
  id: number;
} & Exclude<NewComplianceFileInfo, { id: number }>;

export interface InstitutionDictionary {
  id: number;
  value: string;
}

export enum ShotOption {
  SHOT = 0,
  DECLINED = 1
}

export enum ComplianceReferenceOption {
  WORK = 0,
  CHARACTER = 1
}

export enum CriminalBackgroundResultOption {
  CDPA_NOT_NECESSARY = 0,
  DENIED = 1,
  HOLD_IN_ABEYANCE = 2,
  NEEDS_FINGERPRINTING = 3,
  PASSED = 4,
  PENDING = 5,
  SUBMITTED = 6
}

export enum ADocumentOption {
  US_PASSPORT_OR_PASSPORT_CARD = 0,
  PERM_RESIDENT_CARD = 1,
  FOREIGN_PASSPORT_WITH_I551 = 2,
  EMPLOYMENT_AUTHORIZATION_DOCUMENT = 3,
  FOREIGN_PASSPORT_WITH_I94 = 4,
  PASSPORT_FROM_FSM_OR_RMI_WITH_I94 = 5,
}

export enum BCDocumentOption {
  DRIVER_LICENSE,
  ID_CARD,
  SCHOOL_ID,
  VOTERS_REGISTRATION_CARD,
  US_MILITARY_CARD,
  MILITARY_DEPENDENTS_ID_CARD,
  US_COAST_GUARD_CARD,
  NATIVE_AMERICAN_TRIBAL_B_DOCUMENT,
  CANADIAN_DRIVERS_LICENSE,
  ALIEN_RESIDENT_CARD,
  OTHER_B,
  ALIEN_REGISTRATION_RECEIPT,
  SOCIAL_SECURITY_CARD,
  CERTIFICATION_OF_REPORT_OR_BIRTH,
  BIRTH_CERTIFICATE,
  NATIVE_AMERICAN_TRIBAL_C_DOCUMENT,
  US_CITIZEN_ID_CARD,
  IDENTIFICATION_CARD,
  EMPLOYMENT_AUTHORIZATION,
  OTHER_C,
  CERTIFICATE_OF_NATURALIZATION,
}
