import { ValidatorFn } from '@angular/forms';

import { megabytesToBytes } from '@app/shared/helper';
import { TranslationData } from '@app/core/services';

const MAX_FILES_SIZE = 20;
export function maxFilesSizeValidator(mb = MAX_FILES_SIZE): ValidatorFn {
  return (control) => {
    const totalFilesSize = control.value?.reduce((acc, { size }) => acc + size, 0);

    if (control.value && control.value?.length >= 1 && totalFilesSize > megabytesToBytes(mb)) {
      return {
        maxFilesSize: new TranslationData('commonErrors.files_maxSize', { size: mb }),
      };
    }
    return null;
  };
}
