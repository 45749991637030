import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { TranslationData } from '@app/core/services';
import * as moment from 'moment';

export function dateFormatValidator(format: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.value) {
      return null;
    }

    const isFormatValid = moment(control.value, format, true).isValid();
    if (isFormatValid) {
      return null;
    }

    return {
      wrongDateFormat: new TranslationData('commonErrors.date_wrong_format', { format })
    };
  };
}
