<app-loading *ngIf="isLoading"></app-loading>

<div *ngIf="visitSchedule && !isLoading" class="visit-details">
  <div class="caregiver">
    <span>{{ 'visitDetails.tabSchedule_field_caregiver' | translate }}</span>

    <div class="caregiver-content">
      <ng-container *ngIf="caregiver">
        <a [routerLink]="['/', 'users', caregiver, 'edit']" target="_blank">
          <svg-icon
            [matTooltip]="'dispatchScreen.tooltip_openCaregiverProfile' | translate"
            class="icon-profile"
            src="/assets/icons/clinical_notes.svg"
          ></svg-icon>
        </a>

        <svg-icon [matTooltip]="'visitDetails.tabSchedule_startChatWithCaregiver' | translate"
                  (click)="openChat()"
                  class="icon-contact"
                  src="/assets/icons/chat.svg"
        ></svg-icon>
      </ng-container>

      <ng-select
        class="app-ng-select"
        bindLabel="label"
        bindValue="value"
        placeholder="Select"
        appearance="outline"
        [matTooltip]="visitSchedule.status === visitsStatuses.available ? ('visitDetails.tabSchedule_field_caregiver_tooltip' | translate) : visitSchedule?.caregiver_detail?.full_name"
        [(ngModel)]="caregiver"
        [items]="caregiverList"
        [readonly]="visitSchedule.status === visitsStatuses.completed"
        [notFoundText]="customNotFoundText | translate"
        [loading]="caregiversLoading"
        [editableSearchTerm]="true"
        (clear)="removeCaregiver()"
        (search)="searchCaregiver($event)"
        (change)="changeCaregiver($event)">
      </ng-select>
    </div>
  </div>

  <form
    class="visit-details__form form"
    novalidate
    autocomplete="off"
    [formGroup]="form">
    <div class="form__item">
      <span class="form__label">{{ 'visitDetails.tabSchedule_field_visitDate' | translate }}</span>
      <div class="form__value editable-field">
        <app-datepicker-field class="datepicker"
                              formControlName="visitDate"
                              [toggleTooltip]="'visitDetails.tabSchedule_field_visitDate_tooltip' | translate"
        ></app-datepicker-field>
      </div>
    </div>

    <div class="form__item">
      <span class="form__label">{{ 'visitDetails.tabSchedule_field_scheduledTime' | translate }}</span>
      <div class="form__value editable-field">
        <app-ampm-field-range
          [editTooltip]="'visitDetails.tabSchedule_field_scheduledTime_tooltip' | translate"
          [initialRange]="scheduledTime.value"
          [startTimeCanBeBigger]="true"
          (timeRangeChanged)="timeRangeChange($event, scheduledTime)"
        ></app-ampm-field-range>
      </div>
    </div>

    <div class="form__item">
      <span class="form__label">{{ "visitDetails.tabSchedule_field_billedTime" | translate }}</span>
      <div class="form__value"
           [matTooltip]="'visitDetails.tabSchedule_field_billedTime_tooltip' | translate"
      >{{ visitSchedule.bill_minutes | duration: visitSchedule.bill_hours }}</div>
    </div>

    <div class="form__item">
      <span class="form__label">{{ "visitDetails.tabSchedule_field_billType" | translate }}</span>
      <div class="form__value"
           [matTooltip]="'visitDetails.tabSchedule_field_billType_tooltip' | translate"
      >{{ visitSchedule.bill_type_value }}</div>
    </div>

    <div class="form__item">
      <span class="form__label">{{ "visitDetails.tabSchedule_field_visitType" | translate }}</span>
      <div class="form__value" [matTooltip]="'visitDetails.tabSchedule_field_visitType_tooltip' | translate">
        {{ ('visitTypeEnum.' + visitSchedule.visit_type) | translate }}
      </div>
    </div>

    <div class="form__item">
      <span class="form__label">{{ 'visitDetails.tabSchedule_field_visitChanges' | translate }}</span>
      <div class="form__value">
        <mat-slide-toggle
          [matTooltip]="(acceptForMasterWeekControl.value ? 'visitDetails.tabSchedule_field_visitChanges_onTooltip' : 'visitDetails.tabSchedule_field_visitChanges_offTooltip') | translate"
          disableRipple="true"
          formControlName="acceptForMasterweek"
          #acceptForMasterWeekControl="ngControl"
        ></mat-slide-toggle>
      </div>
    </div>

    <div class="form__item">
      <span class="form__label">{{ 'visitDetails.tabSchedule_field_includeInMileage' | translate }}</span>
      <div class="form__value">
        <mat-slide-toggle
          formControlName="mileage_included"
          [matTooltip]="'visitDetails.tabSchedule_field_includeInMileage_tooltip' | translate"
          [disabled]="!visitSchedule.mileage_included_access"
        ></mat-slide-toggle>
      </div>
    </div>

    <div class="form__item">
      <span class="form__label">{{ 'visitDetails.tabSchedule_field_travelTime' | translate }}</span>
      <div class="form__value">
        <mat-slide-toggle
          formControlName="travel_time"
          [matTooltip]="'visitDetails.tabSchedule_field_travelTime_tooltip' | translate"
          [disabled]="!visitSchedule.travel_time_access"
        ></mat-slide-toggle>
      </div>
    </div>

    <div *ngIf="visitSchedule.visit_type === visitsTypes.Temporary" class="form__item">
      <span class="form__label">{{ 'visitDetails.tabSchedule_field_specialInstructions' | translate }}</span>
      <div class="form__value editable-field">
        <app-editable-content #notesEditor formControlName="notes"
                              [editTooltip]="'visitDetails.tabSchedule_field_specialInstructions_editTooltip'">
          <textarea editContent #editTextarea
                    class="form-control"
                    [formControl]="notesEditor.control"
                    [placeholder]="'visitDetails.tabSchedule_field_specialInstructions' | translate"
          ></textarea>
          <textarea viewContent readonly class="form-control"
                    [formControl]="notesEditor.control"
                    [placeholder]="'visitDetails.tabSchedule_field_specialInstructions' | translate"
                    (click)="notesEditor.startEdit(); editTextarea.focus()"
          ></textarea>
        </app-editable-content>
      </div>
    </div>
  </form>

  <div class="visit-details__save">
    <button
      matRipple
      class="app-button app-button_blue app-button_spinner"
      [disabled]="!form.valid || form.pristine || isSaving"
      (click)="save()">
      <mat-spinner *ngIf="isSaving" class="spinner" diameter="14" strokeWidth="1"></mat-spinner>
      {{ "buttons.save" | translate }}
    </button>
  </div>
</div>
