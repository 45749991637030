import {
  Component,
  ElementRef,
  ViewChild,
  Input,
  Output,
  EventEmitter,
  OnInit,
  DoCheck,
  forwardRef,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { NgModelBase } from '@app/shared/ng-model.base';


export interface ChangeEvent {
  name: string;
  value?: string | number;
}

const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => TextareaFieldComponent),
  multi: true
};

@Component({
  selector: 'app-textarea-field',
  templateUrl: './textarea-field.component.html',
  styleUrls: ['./textarea-field.component.scss'],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TextareaFieldComponent extends NgModelBase<string> implements OnInit, DoCheck {
  active = false;
  labelTop = false;
  legendWidth = 0;

  @Input() isDisabled = false;
  @Input() isUppercase = false;
  @Input() invalid = false;
  @Input() labelHidden = false;
  @Input() placeholder = '';
  @Input() errorMessage = '';
  @Input() height = '70px';
  @Input() maxlength = 1000;
  @Input() size: 'large' | 'medium' | 'small' = 'large';

  @Output() inputKeyUp = new EventEmitter<ChangeEvent>();
  @Output() inputChange = new EventEmitter<ChangeEvent>();
  @Output() iconClick = new EventEmitter<ChangeEvent>();

  @ViewChild('label', { static: false }) label: ElementRef;
  @ViewChild('optionsListEl', { static: false }) optionsListEl: ElementRef;
  @ViewChild('input', { static: false }) inputEl: ElementRef;

  constructor(private eRef: ElementRef,
              private cdr: ChangeDetectorRef) {
    super();
  }

  ngOnInit() {
  }

  ngDoCheck() {
    this.cdr.detectChanges();
    this.checkValue();
  }

  focusInput() {
    this.active = true;
    this.legendWidth = this.label.nativeElement.clientWidth + 6;
    this.labelTop = true;
    this.cdr.detectChanges();
  }

  focusOutInput(event: any) {
    this.value = event.target.value as string;
    this.active = false;
    this.checkValue();
    this.onBlur();
  }

  checkValue(): void {
    if (typeof this.value !== 'string' || (this.value as string).length === 0) {
      if (!this.active) {
        this.labelTop = false;
        this.legendWidth = 0;
      }
    } else {
      this.labelTop = true;
      this.legendWidth = this.label.nativeElement.clientWidth + 6;
    }
    this.cdr.detectChanges();
  }
}
