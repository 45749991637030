import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-chat-image',
  templateUrl: './chat-image.component.html',
  styleUrls: ['./chat-image.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatImageComponent implements OnInit {
  @Input() imageUrl: string;
  @Output() clickOnImage = new EventEmitter<void>();

  constructor() { }

  ngOnInit(): void {
  }
}
