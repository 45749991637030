import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  ViewChild
} from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

import { FileHandle } from './drag-and-drop-file.directive';
import { ALLOWED_MIME_TYPES } from '@app/shared/constants';

@Component({
  selector: 'app-drag-and-drop-file-uploading',
  templateUrl: './drag-and-drop-file-uploading.component.html',
  styleUrls: ['./drag-and-drop-file-uploading.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DragAndDropFileUploadingComponent implements OnChanges {
  @Input() readonly allowedMimeTypes: string = [...ALLOWED_MIME_TYPES].join(',');
  @Input() urlFile: string | SafeUrl = '';
  @Input() abilityToChangeFileName: boolean = false;

  @Output() fileUploaded: EventEmitter<File> = new EventEmitter();
  @Output() fileNameChanged: EventEmitter<string> = new EventEmitter();
  @Output() fileNameChanging: EventEmitter<boolean> = new EventEmitter();

  @ViewChild('fileInput') fileInput: ElementRef;

  file: File;
  fileName: string;
  isFileNameChanging: boolean = false;
  showNameChangeBtn: boolean = false;
  inputFileName: string = '';
  fileExtension: string;

  constructor(
    private sanitizer: DomSanitizer
  ) { }

  ngOnChanges(): void {
    if (this.urlFile) {
      const urlArr = this.urlFile.toString().split('/');
      this.fileName = urlArr.length > 2 ? urlArr[urlArr.length - 1] : '';
    }
  }

  changeName(): void {
    const splitName = this.file.name.split('.');
    this.fileExtension = '.' + splitName[splitName.length - 1].toLowerCase();
    this.isFileNameChanging = true;
    this.fileNameChanging.emit(true);
  }

  saveNewName(): void {
    if (!this.inputFileName) {
      return;
    }
    this.fileNameChanging.emit(false);
    this.updateFile(this.inputFileName.trim() + this.fileExtension);
    this.inputFileName = '';
  }

  doNotSave(): void {
    this.isFileNameChanging = false;
    this.inputFileName = '';
    this.fileNameChanging.emit(false);
  }

  filesDropped(files: Array<FileHandle>): void {
    this.file = files[0].file;
    this.updateFile();
  }

  filesUploaded(event: Event): void {
    this.file = (event.target as HTMLInputElement).files[0];
    this.updateFile();
  }

  updateFile(newFileName?: string): void {
    if (this.file) {
      this.urlFile = this.sanitizer.bypassSecurityTrustUrl(window.URL.createObjectURL(this.file));
      this.fileName = this.file.name;
      this.showNameChangeBtn = true;

      if (newFileName) {
        this.fileName = newFileName;
        this.fileNameChanged.emit(newFileName);
        this.isFileNameChanging = false;
      } else {
        this.fileNameChanged.emit('');
      }
    } else {
      this.fileInput.nativeElement.value = '';
      this.urlFile = '';
      this.fileName = '';
      this.showNameChangeBtn = false;
    }

    this.fileUploaded.emit(this.file);
  }

  openDialogUpload(): void {
    this.fileInput.nativeElement.click();
  }

  removeFileUpload(): void {
    this.file = null;
    this.updateFile();
    this.fileNameChanged.emit(null);
    this.inputFileName = '';
  }

}
