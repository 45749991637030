import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { getFirstLetterName } from '../../helper';

@Component({
  selector: 'app-user-avatar',
  templateUrl: './user-avatar.component.html',
  styleUrls: ['./user-avatar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserAvatarComponent {
  @Input() name: string;
  @Input() avatar: string;
  @Input() iconSize: string = '56px';

  getFirstLetterName(name: string): string {
    return getFirstLetterName(name);
  }

}
