import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

import { VisitAttentions } from '@app/models/patient/visit-details/visit-details.model';

@Component({
  selector: 'app-visit-attentions',
  templateUrl: 'visit-attentions.component.html',
  styleUrls: ['visit-attentions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VisitAttentionsComponent {
  filteredAttentions: VisitAttentions[];

  @Input() set attentions(attentions: VisitAttentions[]) {
    this.filteredAttentions = this.filterNotRegisteredAttentions(attentions);
  }

  readonly attentionMap = {
    [VisitAttentions.TIME_OF_ASSIGNMENT_IS_ENDING]: { icon: 'time.svg', text: 'visitDetails.attentions_assignmentTimeEnding' },
    [VisitAttentions.HAS_NOT_VIEWED_NEW_BLASTING_OFFERS]: { icon: 'accepted-offer.svg', text: 'visitDetails.attentions_newBlastingOffer' },
    [VisitAttentions.NOT_TRANSFER_TO_EN_ROUTE_BEFORE_START]: { icon: 'clock.svg', text: 'visitDetails.attentions_notTransferToEnRoute' },
    [VisitAttentions.WAS_CANCELED]: { icon: 'date-canceled.svg', text: 'visitDetails.attentions_visitCanceled' },
    [VisitAttentions.NOT_TRANSFER_TO_IN_PROGRESS_AFTER_START]: { icon: 'time-attention.svg', text: 'visitDetails.attentions_notTransferToInProgress' },
    [VisitAttentions.TIME_TO_CLOCK_IN_WAS_EXPIRED]: { icon: 'enter.svg', text: 'visitDetails.attentions_clockInExpired' },
    [VisitAttentions.TIME_TO_CLOCK_OUT_WAS_EXPIRED]: { icon: 'exit.svg', text: 'visitDetails.attentions_clockOutExpired' },
    [VisitAttentions.POC_COMPLIANCE]: { icon: 'list.svg', text: 'visitDetails.attentions_pocCompliance' },
    [VisitAttentions.IS_CONFIRMED]: { icon: 'confirmed.svg', text: 'visitDetails.attentions_confirmed' },
    [VisitAttentions.HAS_OVERTIME]: { icon: 'overtime.svg', text: 'visitDetails.attentions_overtime' },
    [VisitAttentions.HAS_OVERLAPPING]: { icon: 'overlapping.svg', text: 'visitDetails.attentions_overlapping' },
  };

  private filterNotRegisteredAttentions(attentions: VisitAttentions[]): VisitAttentions[] {
    return attentions.filter(attention => !!this.attentionMap[attention]);
  }
}
