<mat-form-field class="mc-chip-select" [class.isError]="isError" appearance="outline">
  <mat-chip-list #chipList>
    <mat-chip
      *ngFor="let chip of selectedChips"
      (removed)="remove(chip)">
      {{ chip }}
      <button matChipRemove>
        <mat-icon>cancel</mat-icon>
      </button>
    </mat-chip>
    <input
      #chipInput
      placeholder="Select"
      (click)="onClick()"
      (blur)="onBlur()"
      (input)="onInput($event)"
      [formControl]="inputCtrl"
      [matAutocomplete]="auto"
      [matChipInputFor]="chipList"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      (matChipInputTokenEnd)="add($event, chipInput)">
  </mat-chip-list>
  <mat-autocomplete
    #auto="matAutocomplete"
    class="mc-mat-autocomplete"
    (optionSelected)="selected($event)">
    <mat-option *ngFor="let chip of filteredChips | async" [value]="chip">
      {{ chip }}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
