import { ChangeDetectionStrategy, Component, forwardRef, inject, Input } from '@angular/core';
import { ControlContainer } from '@angular/forms';
import { TravelInfo } from '@app/models/patient/visit-details/travel-time';

@Component({
  selector: 'app-mileage-form',
  templateUrl: './mileage-form.component.html',
  styleUrls: ['./mileage-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  viewProviders: [
    {
      provide: ControlContainer,
      useFactory: forwardRef(() => inject(ControlContainer, { skipSelf: true })),
    }
  ]
})
export class MileageFormComponent {
  @Input() millageInfo: TravelInfo;
  @Input() rateEditable = true;
  @Input() disabled = false;
}
