import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { HttpService } from '@app/core/services';
import { URLS } from '@app/shared/urls';
import { UserIfc } from '@app/models/users/user.ifc';
import { PaginatedResponse } from '@app/models/paginated-response.model';
import { EditUserData, UserDisease } from '@app/models/users/edit-user-data.model';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  updateUserProfile$: Subject<void> = new Subject<void>();

  constructor(
    private http: HttpService
  ) { }

  getUserById(url: string): Observable<UserIfc> {
    return this.http.GET<UserIfc>(url);
  }

  getUsers(params: object = null): Observable<PaginatedResponse> {
    return this.http.GET<PaginatedResponse>(`${ URLS.profile }`, params);
  }

  updateUser(userId: number, payload): Observable<EditUserData> {
    return this.http.PUT<EditUserData>(`${ URLS.profile }${ userId }/`, payload);
  }

  createUser(user: any): Observable<any> {
    return this.http.POST(URLS.profile, user);
  }
}
