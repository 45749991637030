import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError as observableThrowError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { HttpService } from './http/http.service';
import { URLS } from '@app/shared/urls';
import { DocumentManagement } from '@app/models/hr/document-mangement.model';
import { PaginatedResponse } from '@app/models/paginated-response.model';

@Injectable({
  providedIn: 'root'
})
export class DocumentManagementService {

  constructor(
    private http: HttpService
  ) { }

  getDocuments(url: string): Observable<PaginatedResponse> {
    return <Observable<PaginatedResponse>>this.http
    .GET(url)
    .pipe(
      catchError((error: HttpErrorResponse) => observableThrowError(error))
    );
  }

  deleteDocument(id: number): Observable<void> {
    return <Observable<void>>this.http
    .DELETE(`${ URLS.document }${ id }/`)
    .pipe(
      catchError((error: HttpErrorResponse) => observableThrowError(error))
    );
  }

  downloadDocument(url: string): Observable<DocumentManagement> {
    return <Observable<DocumentManagement>>this.http
    .GET(url)
    .pipe(
      catchError((error: HttpErrorResponse) => observableThrowError(error))
    );
  }

  addDocument(data): Observable<DocumentManagement> {
    return <Observable<DocumentManagement>>this.http
    .POST(`${ URLS.document }`, data)
    .pipe(
      catchError((error: HttpErrorResponse) => observableThrowError(error))
    );
  }

}
