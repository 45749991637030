import { WsEvent, WsData } from '@app/core/services/websocket/ws.models';

export interface RealtimeEventHandler<Payload = any, MappedResult = any> {
  name: WsEvent;
  mapper?: (message: WsData<Payload>) => MappedResult;
  filter?: (message: WsData<Payload>) => boolean;
}

export enum MergeStrategy {
  Append,
  Prepend,
  Replace
}
