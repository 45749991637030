<div class="date-time">
  <div class="date-time__field">
    <ng-container *ngIf="!isShowForm && initialDateTime">
      <span
        class="date-time__input-value underline-text"
        [matTooltip]="dateTooltip"
        (click)="isShowForm = true"
      >{{ dateInput }} {{ hoursInput }}<span class="date-time__colon">:</span>{{ minutesInput }} {{ ampmInput }}</span>
    </ng-container>

    <ng-container *ngIf="isShowForm">
      <form [formGroup]="form" class="date-time__form">
        <mat-form-field
          appearance="fill"
          class="date-time__date"
          [class.is-invalid]="date.touched && date.invalid">
          <input
            hidden
            [min]="minDate"
            [matDatepicker]="picker"
            [(ngModel)]="date2"
            (ngModelChange)="setValueInControl($event, date)"
            [ngModelOptions]="{ standalone: true }"/>
          <input
            matInput
            readonly
            [imask]="{ mask: '00/00/0000' }"
            [placeholder]="placeholderDate"
            formControlName="date"/>
          <mat-datepicker-toggle matPrefix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker xPosition="start"></mat-datepicker>
        </mat-form-field>
        <input
          class="date-time__input"
          (input)="hoursChanged($event)"
          [ngClass]="{ 'has-error': (hours.touched || hours.dirty) && hours.invalid }"
          placeholder="HH"
          maxlength="2"
          formControlName="hours"
          type="text"/>
        <span class="date-time__colon">:</span>
        <input
          class="date-time__input"
          (input)="minutesChanged($event)"
          [ngClass]="{ 'has-error': (minutes.touched || minutes.dirty) && minutes.invalid }"
          placeholder="MM"
          maxlength="2"
          formControlName="minutes"
          type="text"/>
        &nbsp;&nbsp;
        <ng-select
          class="app-ng-select"
          bindLabel="label"
          bindValue="value"
          appendTo="body"
          appearance="outline"
          formControlName="ampm"
          [items]="amPMList"
          [clearable]="false"
          [searchable]="false">
        </ng-select>

        <button
          matRipple
          class="date-time__btn"
          [disabled]="!form.valid"
          (click)="saveDateTime()"
          type="button">
          <mat-icon class="material-icons-two-tone icon-green">done</mat-icon>
        </button>
        <button matRipple class="date-time__btn" (click)="cancel()" type="button">
          <mat-icon class="material-icons-two-tone icon-red">close</mat-icon>
        </button>
      </form>
    </ng-container>

    <button
      matRipple
      class="date-time__edit-btn"
      [matTooltip]="editTooltip"
      [class.no-show]="isShowForm"
      (click)="showForm()">
      <mat-icon class="material-icons-two-tone icon-gray icon-edit">edit</mat-icon>
    </button>
  </div>
</div>
