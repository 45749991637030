<div class="group">
  <div class="group__header">
    <span class="group__title">
      <ng-container *ngIf="!data?.chatId; else editGroup">Create New</ng-container>
      <ng-template #editGroup>Edit</ng-template>
      Group
    </span>
    <button matRipple class="btn-close" (click)="close()">
      <span class="material-icons btn-close__icon">close</span>
    </button>
  </div>

  <app-loading *ngIf="isLoading" class="loading"></app-loading>

  <ng-container *ngIf="!isLoading">
    <form
    class="group__form form"
    novalidate
    [formGroup]="form"
    autocomplete="off">

    <div class="form__data">
      <app-file-upload
        type="field"
        fieldSize="87px"
        [urlImg]="avatar.value"
        [multiple]="false"
        (filesAdded)="addAvatar($event)"
      ></app-file-upload>
      <div class="form__inputs">
        <app-form-field label="Name*" fieldClasses="field-with-errors">
          <input
            class="mc-form-field-input"
            type="text"
            appTrimInput
            placeholder="Group Name"
            formControlName="name"
            [class.error]="name.hasError('maxlength') || name.hasError('uniqueGroupName')"/>
          <div class="error-field">
            <mat-error *ngIf="name.touched && name.hasError('maxlength')">
              The group name cannot exceed 24 characters.
            </mat-error>
            <mat-error *ngIf="name.hasError('uniqueGroupName')">
              {{ name.errors.uniqueGroupName }}
            </mat-error>
          </div>
        </app-form-field>

        <app-form-field label="Type*">
          <mat-select
            class="mc-form-field-input"
            panelClass="mc-select-panel"
            placeholder="Select"
            formControlName="type">
            <mat-option *ngFor="let type of groupsTypes" [value]="type.value">
              {{ type.title }}
            </mat-option>
          </mat-select>
        </app-form-field>
      </div>
    </div>

    <app-form-field label="Add Users*">
      <div class="added-users-list">
        <app-select-chip-input
          ngDefaultControl
          [allChips]="allChipsUsers"
          [selectedChips]="selectedChipsUsers"
          (selectedChip)="changeChipsUsers($event)"
          (valueChanged)="searchUsers($event)"
          (removedChip)="removeChip($event)"
          (blurEmitter)="loadUsers()"
        ></app-select-chip-input>
      </div>
    </app-form-field>
  </form>

  <div class="group__buttons">
    <button class="mc-button mc-button_red" matRipple (click)="close()">Cancel</button>
    <button
      class="mc-button mc-button_blue"
      matRipple
      [disabled]="!form.valid || !selectedUsers.length || isSending"
      (click)="createGroup()">
      <span *ngIf="!isSending" class="material-icons-two-tone btn-icon">group_add</span>
      <mat-spinner
        *ngIf="isSending"
        class="spinner"
        diameter="16"
        strokeWidth="2"
      ></mat-spinner>
      <ng-container *ngIf="!data?.chatId; else editMode">Create</ng-container>
      <ng-template #editMode>Edit</ng-template>
    </button>
  </div>
  </ng-container>
</div>
