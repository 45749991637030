import { Injectable } from '@angular/core';
import { URLS } from '@app/shared/urls';
import { Observable } from 'rxjs';

import { HttpService } from '@app/core/services';
import { AvailableCaregiverForAvailableVisit } from '@app/models/dispatch-screen/available-caregivers.model';
import { PaginatedResponse } from '@app/models/paginated-response.model';
import { PaginationParams } from '@app/shared/interfaces/pagination.class';

@Injectable({
  providedIn: 'root'
})
export class AvailableCaregiversService {

  constructor(
    private http: HttpService
  ) { }

  getAvailableCaregiversForAvailableVisitId(visitId: number, params: PaginationParams): Observable<PaginatedResponse<AvailableCaregiverForAvailableVisit>> {
    const url = `${ URLS.dispatch_screen_visit }${ visitId }/available_caregivers/`;
    return this.http.GET<PaginatedResponse<AvailableCaregiverForAvailableVisit>>(url, params);
  }
}
