import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncate'
})
export class TruncatePipe implements PipeTransform {
  private limit: number = 20;

  transform(value: string | number, limit: number, separate?: boolean): string {
    value = typeof value === 'string' ? value : value.toString();
    this.limit = limit || this.limit;
    if (separate) {
      const truncatedStart = value.slice(0, limit / 2);
      const truncatedEnd = value.slice(-limit / 2);

      return value.length > this.limit ? `${ truncatedStart }...${ truncatedEnd }` : value;
    }
    return value.length > this.limit ? value.slice(0, this.limit) + (value.length > this.limit ? '...' : '') : value;
  }
}
