<div class="field {{size}}"
     [ngClass]="{
       'disabled': isDisabled,
       'invalid': invalid,
       'active': active
     }">
  <fieldset class="field-label">
            <!-- [ngClass]="{
              'label-top': labelTop,
              'label-hidden': labelHidden
            }"> -->
    <!-- <legend [style.width.px]="legendWidth"></legend> -->
    <!-- <div #label class="label">{{placeholder}}</div> -->
  </fieldset>
  <div class="field-text">
    <input #input
           [ngModel]="value"
           type="text"
           [imask]="{mask: mask}"
           [unmask]="true"
           [ngClass]="{'text-uppercase': isUppercase, 'with-icon': buttonTwo}"
           (ngModelChange)="changeValue($event)"
           (focus)="focusInput()"
           (focusout)="focusOutInput($event)"
           [autocomplete]="false"
           [disabled]="isDisabled"/>
    <div class="icon icon-clickable"
         [ngClass]="{'clickable-one': buttonOne}"
         *ngIf="buttonOne"
         (click)="buttonOneClicked()">
      <i class="fa" [ngClass]="buttonOne"></i>
    </div>
    <div class="icon icon-clickable"
         [ngClass]="{'clickable-two': buttonTwo}"
         *ngIf="buttonTwo"
         (click)="buttonTwoClicked()">
      <img src="/assets/images/delete-icon24px.svg" alt="">
    </div>
  </div>
</div>

