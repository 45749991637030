import { Injectable } from '@angular/core';
import { throwError as observableThrowError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { HttpService } from './http/http.service';
import { URLS } from '@app/shared/urls';
import { UserAvailability } from '@app/models/users/user-availability.model';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AvailabilityService {

  constructor(
    private http: HttpService
  ) { }

  getAvailabilityByUserId(id: number, timeZoneOffset: number): Observable<UserAvailability[]> {
    return <Observable<UserAvailability[]>>this.http
    .GET(`${ URLS.availability }user/${ id }/?utc_offset=${ timeZoneOffset }`)
    .pipe(
      catchError((error: HttpErrorResponse) => observableThrowError(error))
    );
  }

  setAvailability(timeZoneOffset: number, data: UserAvailability): Observable<UserAvailability> {
    return <Observable<UserAvailability>>this.http
    .POST(`${ URLS.availability }?utc_offset=${ timeZoneOffset }`, data)
    .pipe(
      catchError((error: HttpErrorResponse) => observableThrowError(error))
    );
  }

  changeAvailability(id: number, timeZoneOffset: number, data: UserAvailability): Observable<UserAvailability> {
    return <Observable<UserAvailability>>this.http
    .PUT(`${ URLS.availability }${ id }/?utc_offset=${ timeZoneOffset }`, data)
    .pipe(
      catchError((error: HttpErrorResponse) => observableThrowError(error))
    );
  }

  deleteAvailability(id: string): Observable<any> {
    return <Observable<any>>this.http
    .DELETE(`${ URLS.availability }${ id }/`)
    .pipe(
      catchError((error: HttpErrorResponse) => observableThrowError(error))
    );
  }

}
