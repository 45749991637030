<div class="messages" cdkScrollable>
  <div *ngIf="isThreadMessagesLoading" class="loading">
    <app-loader></app-loader>
  </div>

  <ng-container *ngIf="!isThreadMessagesLoading">
    <app-chat-message
      #message
      *ngFor="let message of threadMessages; trackBy: trackChatMessage; first as first"
      [showDivider]="first"
      [message]="message"
      [userChatId]="userChat.id"
      [userId]="userId"
      [isThread]="true"
    ></app-chat-message>
  </ng-container>
</div>
