<div class="ampm">
  <div class="ampm__field">
    <ng-container *ngIf="!isShowForm && initialTime">
      <span
        class="ampm__input-value underline-text"
        (click)="isShowForm = true"
      >{{ hoursInput }}<span class="ampm__colon">:</span>{{ minutesInput }} {{ ampmInput }}</span>
    </ng-container>

    <ng-container *ngIf="isShowForm">
      <form [formGroup]="form" class="ampm__form">
        <input
          class="ampm__input"
          placeholder="HH"
          maxlength="2"
          formControlName="hours"
          type="text"
          [ngClass]="{ 'has-error': (hours.touched || hours.dirty) && hours.invalid }"
          (input)="hoursChanged($event)"/>
        <span class="ampm__colon">:</span>
        <input
          class="ampm__input"
          placeholder="MM"
          maxlength="2"
          formControlName="minutes"
          type="text"
          [ngClass]="{ 'has-error': (minutes.touched || minutes.dirty) && minutes.invalid }"
          (input)="minutesChanged($event)"/>
        &nbsp;&nbsp;
        <ng-select
          class="app-ng-select"
          bindLabel="label"
          bindValue="value"
          appendTo="body"
          appearance="outline"
          formControlName="ampm"
          [items]="amPMList"
          [clearable]="false"
          [searchable]="false">
        </ng-select>

        <button
          matRipple
          class="ampm__btn"
          type="button"
          [disabled]="!form.valid"
          (click)="saveTime()">
          <mat-icon class="material-icons-two-tone icon-green">done</mat-icon>
        </button>
        <button
          matRipple
          class="ampm__btn"
          type="button"
          (click)="cancel()">
          <mat-icon class="material-icons-two-tone icon-red">close</mat-icon>
        </button>

        <button
          matRipple
          class="ampm__btn"
          type="button"
          [disabled]="!initialTime"
          (click)="reset()">
          <mat-icon class="material-icons-two-tone icon-gray">restart_alt</mat-icon>
        </button>
      </form>
    </ng-container>
  </div>

  <button
    *ngIf="!isShowForm"
    matRipple
    class="ampm__edit-btn"
    (click)="showForm()">
    <mat-icon class="material-icons-two-tone icon-gray icon-edit">edit</mat-icon>
  </button>
</div>
