import { NgModule } from '@angular/core';
import { MAT_SELECT_CONFIG } from '@angular/material/select';

import { MessageCenterComponent } from './message-center.component';
import { MessageCenterService } from './services/message-center.service';
import { SharedModule } from './shared/shared.module';
import { CreateGroupComponent } from './create-group/create-group.component';
import { services } from './services';
import { LoadingModule } from '@app/shared/components/loading/loading.module';

@NgModule({
  declarations: [
    MessageCenterComponent,
    CreateGroupComponent
  ],
    imports: [
        SharedModule,
        LoadingModule
    ],
  exports: [
    MessageCenterComponent
  ],
  providers: [
    MessageCenterService,
    ...services,
    { provide: MAT_SELECT_CONFIG, useValue: { overlayPanelClass: 'mc-select-overlay' } }
  ]
})
export class MessageCenterModule {
}
