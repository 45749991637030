import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError as observableThrowError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { HttpService } from '../index';
import { URLS } from '@app/shared/urls';
import { PaginatedResponse } from '@app/models/paginated-response.model';
import {
  PatientPOCDuty,
  PlanOfCare,
  POCDuty,
  PreCalculatePOCDuration,
  PreCalculatePOCDurationResponse
} from '@app/models/patient/plan-of-care.model';

@Injectable({
  providedIn: 'root'
})
export class PlanOfCareService {

  constructor(
    private http: HttpService
  ) { }

  getPOCList(request: string): Observable<PaginatedResponse<PlanOfCare>> {
    return <Observable<PaginatedResponse<PlanOfCare>>>this.http
    .GET(`${ URLS.poc }${ request }`)
    .pipe(
      catchError((error: HttpErrorResponse) => observableThrowError(error))
    );
  }

  precalculateDurationPOCDuty(data: PreCalculatePOCDuration): Observable<PreCalculatePOCDurationResponse> {
    return <Observable<PreCalculatePOCDurationResponse>>this.http
    .POST(URLS.poc_precalculate_duration, data)
    .pipe(
      catchError((error: HttpErrorResponse) => observableThrowError(error))
    );
  }

  createPOC(data: POCDuty): Observable<POCDuty> {
    return <Observable<POCDuty>>this.http
    .POST(URLS.poc, data)
    .pipe(
      catchError((error: HttpErrorResponse) => observableThrowError(error))
    );
  }

  updatePOC(pocId: number, data: POCDuty): Observable<POCDuty> {
    return <Observable<POCDuty>>this.http
    .PUT(`${ URLS.poc }${ pocId }/`, data)
    .pipe(
      catchError((error: HttpErrorResponse) => observableThrowError(error))
    );
  }

  deletePOC(pocId: number): Observable<void> {
    return <Observable<void>>this.http
    .DELETE(`${ URLS.poc }${ pocId }/`)
    .pipe(
      catchError((error: HttpErrorResponse) => observableThrowError(error))
    );
  }

  getPOCDutiesById(dutyId: number): Observable<PatientPOCDuty> {
    return <Observable<PatientPOCDuty>>this.http
    .GET(`${ URLS.poc }${ dutyId }/`)
    .pipe(
      catchError((error: HttpErrorResponse) => observableThrowError(error))
    );
  }

  getPOCDutyList(query: string): Observable<PaginatedResponse> {
    return <Observable<PaginatedResponse>>this.http
    .GET(`${ URLS.duty }?${ query }`)
    .pipe(
      catchError((error: HttpErrorResponse) => observableThrowError(error))
    );
  }

}
