import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

import { NotificationComponent } from '../shared/components/notification/notification.component';
import { NotificationType } from '../shared/constants';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {
  readonly alertDuration: number = 5;

  constructor(
    private snackBar: MatSnackBar
  ) { }

  nonFieldErrors(errors: any) {
    if (!errors.status) {
      this.snackBar.openFromComponent(NotificationComponent, {
        duration: this.alertDuration * 1000,
        data: {
          type: NotificationType.error,
          text: 'Unable to connect to server!'
        }
      });
      return;
    }
    if (typeof errors.error === 'string') {
      this.errorAsString(errors.error);
    } else if (errors.error instanceof Array) {
      this.errorAsArray(errors.error);
    } else if (typeof errors.error === 'object') {
      this.errorAsObject(errors.error);
    } else {
      this.snackBar.openFromComponent(NotificationComponent, {
        duration: this.alertDuration * 1000,
        data: {
          type: NotificationType.error,
          text: `Server error! Error code is ${ errors.status }`
        }
      });
    }
  }

  errorAsString(value: string, key?: string): void {
    let msg = '';
    if (key) {
      msg += key + ': ';
    }
    msg += value;
    if (key === 'non_field_errors') {
      msg = value;
    }
    this.snackBar.openFromComponent(NotificationComponent, {
      duration: this.alertDuration * 1000,
      data: {
        type: NotificationType.error,
        text: msg
      }
    });
  }

  errorAsArray(errors: Array<any>, key?: string) {
    errors.forEach((error) => {
      if (typeof error === 'string') {
        this.errorAsString(error, key);
      } else if (error instanceof Array) {
        this.errorAsArray(error, key);
      } else if (typeof error === 'object') {
        this.errorAsObject(error, key);
      }
    });
  }

  errorAsObject(errors: object, key?: string) {
    for (const key in errors) {
      if (errors.hasOwnProperty(key)) {
        if (typeof errors[key] === 'string') {
          this.errorAsString(errors[key], key);
        } else if (errors[key] instanceof Array) {
          this.errorAsArray(errors[key], key);
        } else if (typeof errors[key] === 'object') {
          this.errorAsObject(errors[key], key);
        }
      }
    }
  }

}
