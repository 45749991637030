<div
  class="element {{ size }}"
  tabindex="0"
  [ngClass]="{
    active: optionsAreOpened,
    'close-for-select': closeForSelect && !options.length
  }">

  <fieldset class="element-label"></fieldset>
  <div class="element-text" (click)="clickOptions()">
    <div class="value">
      <div class="value__input">
        <span class="value__title">{{ title }}:</span>
        <span>{{ checkedCount }} / </span>
        <span>{{ options.length }}</span>
      </div>
      <img
        class="value__icon"
        [ngClass]="{ rotated: optionsAreOpened }"
        src="/assets/images/arrow-drop-down-24px.svg"
      />
    </div>
  </div>

  <div
    #optionsListEl
    class="element-options"
    [ngStyle]="{ 'max-height': maxOptionsListHeight ? maxOptionsListHeight + 'px' : 'auto' }"
    [ngClass]="{
      'open': optionsAreOpened,
      'top': optionsPosition === 'top',
      'bottom': optionsPosition === 'bottom'
    }">

    <div
      *ngIf="allCheckboxTitle"
      class="option {{ size }}"
      (click)="switchAllCheckbox()">
      <div class="checkbox" [ngClass]="{ 'checked': allCheckbox }" >
        <i class="fa fa-check"></i>
      </div>
      <div class="checkbox-label">{{ allCheckboxTitle }}</div>
    </div>

    <div
      *ngFor="let option of options; let i = index"
      class="option {{ size }}"
      (click)="optionWasSelected(i)">
      <div class="checkbox" [ngClass]="{ 'checked': option.checked }">
        <i class="fa fa-check"></i>
      </div>
      <div class="checkbox-label">{{ option.label | translate }}</div>
    </div>
  </div>
</div>