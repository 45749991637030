import { Injectable } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { share, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ScriptsLoader {
  private pendingScripts: Map<string, Observable<boolean>> = new Map<string, Observable<boolean>>();
  private loadedScripts: Set<string> = new Set<string>();

  loadScript(url: string): Observable<boolean> {
    if (this.pendingScripts.has(url)) {
      return this.pendingScripts.get(url);
    }

    if (this.loadedScripts.has(url)) {
      return of(true);
    }

    const script = document.createElement('script');
    script.async = true;
    script.type = 'text/javascript';
    script.src = url;

    const loadSubject = new Subject<boolean>();
    const load$ = loadSubject.asObservable().pipe(share(), take(1));

    this.pendingScripts.set(url, load$);

    script.onload = () => {
      loadSubject.next(true);
      this.loadedScripts.add(url);
      this.pendingScripts.delete(url);
    };
    script.onerror = () => {
      loadSubject.next(false);
      this.pendingScripts.delete(url);
    };
    document.head.appendChild(script);

    return load$;
  }
}
