import { BillingVsPayrollReport } from '@app/models/reports/report-types/billing-vs-payroll.model';
import { EmployeeWorkingHoursReport } from '@app/models/reports/report-types/employee-working-hours.model';
import { PayrollHoursByCaregiverReport } from '@app/models/reports/report-types/payroll-hours-by-caregiver.model';
import { BatchReport } from '@app/models/reports/report-types/batch.model';
import { OptionsListItemIfc } from '@app/shared/interfaces/options-list-item.ifc';
import { ReportTemplate } from '@app/models/reports/report-template.model';
import { OptionsLoadStrategy } from '@app/shared/fields/base-select/models';

export interface ReportTemplatesGroup {
  name: string;
  type: ReportGroupType;
  expanded: boolean;
  reports: ReportTemplateData[];
}

export interface ReportTemplateData {
  name: string;
  type: ReportType;
  description: string;
  fields: ReportTemplateField[];
}

export interface ReportTemplateField {
  // Common
  label: string;
  type: ReportFieldType;
  required: boolean;
  formControlName?: string;
  formControlStart?: string;
  formControlEnd?: string;
  customOptions?: OptionsListItemIfc[];

  // Multiselect field
  bindLabel?: string;
  bindValue?: string;
  checkAll?: boolean;
  requestParams?: object | (() => object);
  loadStrategy?: OptionsLoadStrategy;
  optionsLoader?: string;

  // Many-to-many
  retrieveObjects?: string;
  searchKwargs?: string[];
}

export interface GenerateReportDialogData {
  template?: ReportTemplate;
  reportName: string;
  reportType: ReportType;
  fields: ReportTemplateField[];
}

export enum ReportFieldType {
  TextField = 'text-field',
  MultiSelect = 'multi-select-field',
  DateRange = 'date-range'
}

export enum ReportGroupType {
  Users = 2,
  Billing = 3
}

export enum ReportFileType {
  Pdf = 0,
  Excel = 1,
  Csv = 2
}

export enum ReportType {
  BillingVsPayrollReport = 0,
  EmployeeWorkingHoursReport = 1,
  PayrollHoursByCaregiverReport = 2,
  BatchReport = 3
}

export type ReportModelType = 
    BillingVsPayrollReport
  | EmployeeWorkingHoursReport
  | PayrollHoursByCaregiverReport
  | BatchReport;