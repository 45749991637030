import { Injectable } from '@angular/core';
import { Observable, throwError as observableThrowError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { HttpService } from './http/http.service';
import { DataImportResponse } from '@app/models/hr/data-import.model';
import { URLS } from '@app/shared/urls';
import { PaginatedResponse } from '@app/models/paginated-response.model';

@Injectable({
  providedIn: 'root'
})
export class DataImportService {

  constructor(
    private http: HttpService
  ) { }

  getDataImport(url: string): Observable<PaginatedResponse> {
    return <Observable<PaginatedResponse>>this.http
    .GET(url)
    .pipe(
      catchError((error: any) => observableThrowError(error))
    );
  }

  uploadFile(data): Observable<DataImportResponse> {
    return <Observable<DataImportResponse>>this.http
    .POST(`${ URLS.upload_history }`, data)
    .pipe(
      catchError((error: any) => observableThrowError(error))
    );
  }
}
