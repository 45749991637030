import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError as observableThrowError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { HttpService } from '@app/core/services';
import { URLS } from '@app/shared/urls';
import {
  CaregiverReport,
  DetailedReport,
  GenerateReportPayload,
  GenerateReportResponse
} from '@app/models/payer/detailed-report.model';
import { SelectPayerUnbatchVisit } from '@app/models/payer/payer-batches.model';

@Injectable({
  providedIn: 'root'
})
export class BatchReportService {

  constructor(
    private http: HttpService
  ) { }

  getMainBatches(query: string): Observable<SelectPayerUnbatchVisit[]> {
    return <Observable<SelectPayerUnbatchVisit[]>> this.http
    .GET(`${ URLS.payer_batch }${ query }`)
    .pipe(
      catchError((error: HttpErrorResponse) => observableThrowError(error))
    );
  }

  getDetailedReportByMainBatchId(batchId: number): Observable<DetailedReport> {
    return <Observable<DetailedReport>>this.http
    .GET(`${ URLS.payer_batch }${ batchId }/report_selectors/`)
    .pipe(
      catchError((error: HttpErrorResponse) => observableThrowError(error))
    );
  }

  generateBillingMainBatchReport(payload: GenerateReportPayload): Observable<GenerateReportResponse> {
    return <Observable<GenerateReportResponse>>this.http
    .POST(`${ URLS.generate_report }billing_main_batch/general/?utc_offset=${ new Date().getTimezoneOffset() }`, payload)
    .pipe(
      catchError((error: HttpErrorResponse) => observableThrowError(error))
    );
  }

  getPayrollBatches(query: string): Observable<SelectPayerUnbatchVisit[]> {
    return <Observable<SelectPayerUnbatchVisit[]>> this.http
    .GET(`${ URLS.caregiver_invoice_batch }${ query }`)
    .pipe(
      catchError((error: HttpErrorResponse) => observableThrowError(error))
    );
  }

  getPayrollCaregivers(query: string): Observable<CaregiverReport[]> {
    return <Observable<CaregiverReport[]>> this.http
    .GET(`${ URLS.profile }${ query }`)
    .pipe(
      catchError((error: HttpErrorResponse) => observableThrowError(error))
    );
  }

  getDetailedReportByPayrollBatchId(batchId: number): Observable<DetailedReport> {
    return <Observable<DetailedReport>>this.http
    .GET(`${ URLS.caregiver_invoice_batch }${ batchId }/report_selectors/`)
    .pipe(
      catchError((error: HttpErrorResponse) => observableThrowError(error))
    );
  }

  generateBillingPayrollBatchReport(payload: GenerateReportPayload): Observable<GenerateReportResponse> {
    return <Observable<GenerateReportResponse>>this.http
    .POST(`${ URLS.generate_report }billing_payroll_batch/general/?utc_offset=${ new Date().getTimezoneOffset() }`, payload)
    .pipe(
      catchError((error: HttpErrorResponse) => observableThrowError(error))
    );
  }

  getEdiBatches(query: string): Observable<SelectPayerUnbatchVisit[]> {
    return <Observable<SelectPayerUnbatchVisit[]>> this.http
    .GET(`${ URLS.payer_invoice_batch }${ query }`)
    .pipe(
      catchError((error: HttpErrorResponse) => observableThrowError(error))
    );
  }

  getDetailedReportByEdiBatchId(batchId: number): Observable<DetailedReport> {
    return <Observable<DetailedReport>>this.http
    .GET(`${ URLS.payer_invoice_batch }${ batchId }/report_selectors/`)
    .pipe(
      catchError((error: HttpErrorResponse) => observableThrowError(error))
    );
  }

  generateBillingEdiBatchReport(payload: GenerateReportPayload): Observable<GenerateReportResponse> {
    return <Observable<GenerateReportResponse>>this.http
    .POST(`${ URLS.generate_report }billing_edi_batch/general/?utc_offset=${ new Date().getTimezoneOffset() }`, payload)
    .pipe(
      catchError((error: HttpErrorResponse) => observableThrowError(error))
    );
  }
}
