import { Injectable } from '@angular/core';
import { Address } from 'ngx-google-places-autocomplete/objects/address';

@Injectable({
  providedIn: 'root'
})
export class GmapUtilsService {
  constructor() {}

  getCountry(place: Address): string | null {
    const addr = place.adr_address;
    const str = addr.substring(addr.indexOf('country-name')).slice(14);
    return str.slice(0, str.indexOf('</span>'));
  }
  getState(place: Address): string | null {
    const set = place.address_components
      .filter((i) => i.types.indexOf('administrative_area_level_1') >= 0)
      .map((i) => i.short_name);
    return set.length ? set[0] : null;
  }
  getCounty(place: Address): string {
    const set = place.address_components
      .filter((i) => i.types.indexOf('administrative_area_level_2') >= 0 && i.long_name.indexOf(' County') > 0)
      .map((i) => i.long_name.split(' County')[0]);
    return set.length ? set[0] : '';
  }
  getCity(place: Address): string {
    const set = place.address_components
      .filter((i) => i.types.indexOf('locality') >= 0)
      .map((i) => i.long_name);
    return set.length ? set[0] : '';
  }
  getZip(place: Address): number | string {
    const set = place.address_components
      .filter((i) => i.types.indexOf('postal_code') >= 0)
      .map((i) => i.long_name);
    return set.length ? Number(set[0]) : '';
  }
  getStreetName(place: Address): string {
    const set = place.address_components
      .filter((i) => i.types.indexOf('route') >= 0)
      .map((i) => i.long_name);
    return set.length ? set[0] : '';
  }
  getStreetNumber(place: Address): string {
    const set = place.address_components
      .filter((i) => i.types.indexOf('street_number') >= 0)
      .map((i) => i.long_name);
    return set.length ? set[0] : '';
  }
}
