import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { TranslationData } from '@app/core/services';
import { DatePipe } from '@angular/common';

const datePipe = new DatePipe('en');

export function maxDateValidator(date: Date): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.value || !date) {
      return null;
    }

    if (new Date(new Date(control.value).toDateString()) > new Date(date.toDateString())) {
      return {
        wrongMaxDate: new TranslationData('commonErrors.date_maxDate', { date: datePipe.transform(date,'MM/dd/YYYY') })
      };
    }

    return null;
  };
}
