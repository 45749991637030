<div class="confirmation-dialog">
  <main class="confirmation-dialog__main">
    <img
      class="confirmation-dialog__img"
      alt="warning"
      src="assets/icons/warning.svg"/>
    <div class="confirmation-dialog__text">{{ data.text | translate }}</div>
  </main>

  <footer class="confirmation-dialog__footer">
    <button
      matRipple
      class="app-button app-button_gray"
      [mat-dialog-close]="false">
      {{ (data.buttonCloseLabel ?? 'buttons.cancel') | translate }}
    </button>

    <button
      matRipple
      class="app-button"
      [ngClass]="data.buttonClass ? data.buttonClass : 'app-button_red'"
      [mat-dialog-close]="true">
      {{ (data.buttonLabel ? data.buttonLabel : 'buttons.delete') | translate }}
    </button>
  </footer>
</div>
