import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-ampm-field',
  templateUrl: './ampm-field.component.html',
  styleUrls: ['./ampm-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AmpmFieldComponent implements OnChanges {
  @Input() initialTime: string;
  @Input() defaultTime: string;
  @Output() timeValueChanged = new EventEmitter<string>();

  isShowForm: boolean = false;
  form: FormGroup;
  amPMList = [
    { value: 'AM', label: 'AM' },
    { value: 'PM', label: 'PM' }
  ];
  hoursInput: string;
  minutesInput: string;
  ampmInput: string;

  constructor(
    private fb: FormBuilder
  ) {
    this.initForm();
  }

  get hours(): FormControl {
    return this.form.get('hours') as FormControl;
  }
  get minutes(): FormControl {
    return this.form.get('minutes') as FormControl;
  }
  
  ngOnChanges(): void {
    this.setInitialTime();
  }

  private setInitialTime(): void {
    if (!this.initialTime) {
      return;
    }

    const splitTime = this.initialTime.split(' ');
    this.ampmInput = splitTime[1];

    const splitHM = splitTime[0].split(':');
    this.hoursInput = splitHM[0];
    this.minutesInput = splitHM[1];

    this.form.setValue({
      hours: splitHM[0],
      minutes: splitHM[1],
      ampm: splitTime[1]
    });
  }

  private initForm(): void {
    this.form = this.fb.group({
      hours: [null, Validators.required],
      minutes: [null, Validators.required],
      ampm: 'AM'
    });
  }

  hoursChanged(event): void {
    let value = event.target.value;
    if (value) {
      value = value.replace(/[^0-9]*/g, '');
      this.hours.setValue(value);
    }
    if (!value || +value > 12) {
      this.hours.setErrors({ hours: true });
    } else {
      this.hours.setErrors(null);
    }
  }

  minutesChanged(event): void {
    let value = event.target.value;
    if (value) {
      value = value.replace(/[^0-9]*/g, '');
      this.minutes.setValue(value);
    }
    if (!value || +value > 59) {
      this.minutes.setErrors({ minutes: true });
    } else {
      this.minutes.setErrors(null);
    }
  }

  saveTime(): void {
    const form = this.form.value;

    this.hoursInput = form.hours;
    this.minutesInput = form.minutes;
    this.ampmInput = form.ampm;
    this.initialTime = `${ form.hours }:${ form.minutes } ${ form.ampm }`;
    this.timeValueChanged.emit(this.initialTime);
    this.isShowForm = false;
  }

  showForm(): void {
    this.isShowForm = true;

    if (this.defaultTime && !this.form.valid) {
      this.setDefaultTime();
    }
  }

  private setDefaultTime(): void {
    const splitTime = this.defaultTime.split(' ');
    const splitHM = splitTime[0].split(':');

    this.form.setValue({
      hours: splitHM[0],
      minutes: splitHM[1],
      ampm: splitTime[1]
    });
  }

  cancel(): void {
    this.setInitialTime();
    this.timeValueChanged.emit(this.initialTime);
    this.isShowForm = false;
  }

  reset(): void {
    this.form.setValue({ hours: null, minutes: null, ampm: null });
    this.timeValueChanged.emit('');
    this.isShowForm = false;
  }
}
