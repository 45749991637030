import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';

import { CaregiverAvailability, CaregiverAvailabilityCachedCalendars, CaregiverAvailabilityCalendar } from '@app/models/users/caregiver-availability.model';
import { CaregiverService } from './caregiver.service';

@Injectable({
  providedIn: 'root'
})
export class CaregiverAvailabilityService {
  private cachedCalendars: CaregiverAvailabilityCachedCalendars = {};

  refresh$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  availability$: BehaviorSubject<CaregiverAvailability> = new BehaviorSubject<CaregiverAvailability>(null);

  constructor(
    private caregiverService: CaregiverService
  ) { }

  loadCalendar(id: number, month: number, year: number, fullReload: boolean = false): Observable<CaregiverAvailabilityCalendar[]> {
    const key = this.createYearMonthKey(month, year);

    if (fullReload) {
      this.cachedCalendars = {};
    }

    if (this.cachedCalendars[key]) {
      return of(this.cachedCalendars[key]);
    }

    return this.caregiverService.getCalendar(id, `month=${ month }&year=${ year }`).pipe(
      tap(data => this.cacheData(key, data))
    );
  }

  private cacheData(key: string, data: CaregiverAvailabilityCalendar[]): void {
    this.cachedCalendars[key] = data;
  }

  private createYearMonthKey(month: number, year: number): string {
    return `${ year }-${ month }`;
  }
}
