import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError as observableThrowError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { HttpService } from './http/http.service';
import { URLS } from '@app/shared/urls';
import { PaginatedResponse } from '@app/models/paginated-response.model';
import { BlastedVisit } from '@app/models/blasting/blasting.model';
import { ActiveBlastedVisit } from '@app/models/blasting/blasting-visit.model';

@Injectable({
  providedIn: 'root'
})
export class ActiveBlastingService {
  constructor(
    private http: HttpService
  ) { }

  getBlastedMasterweeks(query: string): Observable<PaginatedResponse<BlastedVisit>> {
    return <Observable<PaginatedResponse<BlastedVisit>>>this.http
    .GET(`${ URLS.master_week_blasting }?${ query }&utc_offset=${ new Date().getTimezoneOffset() }`)
    .pipe(
      catchError((error: HttpErrorResponse) => observableThrowError(error))
    );
  }

  getBlastedVisits(query: string): Observable<PaginatedResponse<ActiveBlastedVisit>> {
    return <Observable<PaginatedResponse<ActiveBlastedVisit>>>this.http
    .GET(`${ URLS.visit_blasting }?${ query }&utc_offset=${ new Date().getTimezoneOffset() }`)
    .pipe(
      catchError((error: HttpErrorResponse) => observableThrowError(error))
    );
  }
}
