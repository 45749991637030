<div class="send-field" (click)="isInputClicked()" tabindex="1">
  <div class="send-field__input">
    <input
      placeholder="Message ..."
      type="text"
      appAutoFocus
      #textInput
      [(ngModel)]="messageText"
      (keydown.enter)="sendMessage()"/>
    <app-file-upload
      type="icon"
      [chatFiles]="true"
      (filesAdded)="changeLoadedFiles($event)"
    ></app-file-upload>
  </div>
  <button matRipple class="send-field__button" (click)="sendMessage()">
    <span class="material-icons-two-tone send-icon">send</span>
  </button>
</div>
