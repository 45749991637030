import { AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FlatTreeControl } from '@angular/cdk/tree';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';

import { TreeNode } from '@app/models/tree-node.model';

interface MenuFlatNode {
  expandable: boolean;
  name: string;
  level: number;
}

export type MenuNodeNotificationsDictionary = { [nodeName: string]: number };

@Component({
  selector: 'app-editor-menu',
  templateUrl: './editor-menu.component.html',
  styleUrls: ['./editor-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditorMenuComponent implements OnInit, AfterViewInit {
  @Input() menuNodes: TreeNode[];
  @Input() invalidMenuNodes: Array<string>;
  @Input() nodesWithNotifications: MenuNodeNotificationsDictionary;
  @Input() activeNode: string;
  @Output() activeNodeChanged = new EventEmitter<string>();

  private transformer = (node: TreeNode, level: number): TreeNode & { expandable: boolean, level: number } => {
    return {
      expandable: !!node.children && node.children.length > 0,
      name: node.name,
      marked: node.marked,
      disabled: node.disabled,
      notifications: node.notifications,
      level
    };
  }

  treeControl = new FlatTreeControl<MenuFlatNode>(
    node => node.level,
    node => node.expandable
  );

  treeFlattener = new MatTreeFlattener(
    this.transformer,
    node => node.level,
    node => node.expandable,
    node => node.children,
  );

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

  ngOnInit(): void {
      this.dataSource.data = this.menuNodes;
  }

  ngAfterViewInit(): void {
    this.treeControl.expandAll();
  }

  hasChild = (_: number, node: MenuFlatNode) => node.expandable;

  isInvalid(nodeName: string): boolean {
    return this.invalidMenuNodes ? this.invalidMenuNodes.includes(nodeName) : false;
  }

  getNotifications(nodeName: string): number | undefined {
    return this.nodesWithNotifications && this.nodesWithNotifications[nodeName];
  }

  changeActiveNode(node: string): void {
    this.activeNodeChanged.emit(node);
  }
}
