import { Component, forwardRef, Input, OnInit, ChangeDetectionStrategy, Output, EventEmitter } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { untilDestroyed } from '@ngneat/until-destroy';
import { debounceTime, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { OptionChangeEvent } from '@app/shared/fields/autocomplete-field/models';
import { BaseSelectComponent } from '@app/shared/fields/base-select/select.component';

@Component({
  selector: 'app-autocomplete-field',
  templateUrl: 'autocomplete-field.component.html',
  styleUrls: ['autocomplete-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AutocompleteFieldComponent),
      multi: true
    }
  ]
})
export class AutocompleteFieldComponent extends BaseSelectComponent implements ControlValueAccessor, OnInit {
  @Input() minSearchSymbols: number = 0;
  @Input() placeholder: string = 'placeholders.select';

  @Output() optionSelect: EventEmitter<OptionChangeEvent> = new EventEmitter<OptionChangeEvent>();

  searchText: string = '';
  selectedOption: any;

  private searchSubject: Subject<string> = new Subject<string>();
  private isSearchInputWasChangedByUser = false;

  get isFilledRequiredSymbolsLength(): boolean {
    return this.searchText?.length >= this.minSearchSymbols;
  }

  ngOnInit(): void {
    this.searchSubject.pipe(
      tap(() => this.loading = true),
      debounceTime(700),
      untilDestroyed(this)
    ).subscribe(query => {
      this.searchText = query;
      this.loadOptions();
    });
  }

  protected override loadOptions() {
    super.loadOptions({ search: this.searchText });
  }

  onSearch(query: string): void {
    this.searchText = query;
    this.isSearchInputWasChangedByUser = true;

    if (this.loadStrategy === 'none' || query.length < this.minSearchSymbols) {
      return;
    }

    this.searchSubject.next(query);
  }

  reset(): void {
    this.selectOption(null);
    this.onSearch('');
  }

  selectOption(value: string | number | null) {
    if (value === this.value) {
      return;
    }

    this.value = value ?? null;
    this.selectedOption = this.findOption(this.value);
    this.searchText = this.selectedOption ? this.selectedOption[this.bindLabel] : '';

    this.onChangeCallback(this.value);
    this.optionSelect.emit({ value, label: this.searchText, optionData: this.selectedOption });
  }

  findOption(value: string | number): any {
    return this.options.find(option => option[this.bindValue] === value);
  }

  findOptionLabel(value: string | number): string {
    const option = this.findOption(value);

    return option ? option[this.bindLabel] : '';
  }


  onFocus(): void {
    if (!this.isFilledRequiredSymbolsLength) {
      return;
    }

    super.onFocus();
  }

  protected onOptionsLoad(options: any[]) {
    this.options = options;

    // Update input text only if options were load silently without changing input manually by user
    if (!this.optionsWereLoadedAtLeastOnce && !this.isSearchInputWasChangedByUser) {
      this.updateInputView();
    }
  }

  protected override updateInputView(): void {
    this.selectedOption = this.findOption(this.value);
    this.searchText = this.selectedOption ? this.selectedOption[this.bindLabel] : '';
  }
}
