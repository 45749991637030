import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { TranslationData } from '@app/core/services';
import { DatePipe } from '@angular/common';

const datePipe = new DatePipe('en');

export function minDateValidator(date: Date): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.value || !date) {
      return null;
    }

    if (new Date(new Date(control.value).toDateString()) < new Date(date.toDateString())) {
      return {
        wrongMinDate: new TranslationData('commonErrors.date_minDate', { date: datePipe.transform(date,'MM/dd/YYYY') })
      };
    }

    return null;
  };
}
