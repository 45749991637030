import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MultiselectFieldComponent } from './multiselect-field.component';
import { NgSelectModule } from "@ng-select/ng-select";
import { TranslateModule } from "@ngx-translate/core";
import { MatSelectModule } from "@angular/material/select";
import { PipesModule } from "@app/shared/pipes/pipes.module";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MaterialModule } from "@app/material.module";

@NgModule({
    imports: [
        CommonModule,
        NgSelectModule,
        TranslateModule,
        MatSelectModule,
        PipesModule,
        MatProgressSpinnerModule,
        MaterialModule
    ],
  declarations: [
    MultiselectFieldComponent,
  ],
  exports: [
    MultiselectFieldComponent
  ]
})
export class MultiselectFieldModule {}
