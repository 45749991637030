import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'firstLetterOfName'
})
export class FirstLetterOfNamePipe implements PipeTransform {

  transform(str: string): string {
    return !str.length ? '' : str.charAt(0).toUpperCase();
  }
}
