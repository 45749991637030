import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable, Subject, throwError as observableThrowError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { HttpService } from '@app/core/services';
import { PaginatedResponse } from '@app/models/paginated-response.model';
import { URLS } from '@app/shared/urls';
import { VisitTimeLog } from '@app/models/visits/visits.model';
import { EditVisitData } from '@app/models/visits/edit-visit-data.model';
import { CaregiverLocationInfo } from '@app/models/visits/caregiver-location-info.model';

@Injectable({
  providedIn: 'root'
})
export class VisitsService {

  recentlyCreatedVisits: Subject<EditVisitData> = new Subject<EditVisitData>;

  constructor(
    private http: HttpService
  ) { }

  getVisits(url: string): Observable<PaginatedResponse> {
    return <Observable<PaginatedResponse>>this.http
    .GET(`${ URLS.patients_visit }?${ url }&utc_offset=${ new Date().getTimezoneOffset() }`)
    .pipe(
      catchError((error: HttpErrorResponse) => observableThrowError(error))
    );
  }

  updateVisitById(visitId: number, data): Observable<EditVisitData> {
    return <Observable<EditVisitData>>this.http
    .PUT(`${ URLS.patients_visit }${ visitId }/`, data)
    .pipe(
      catchError((error: HttpErrorResponse) => observableThrowError(error))
    );
  }

  getVisitById(visitId: number): Observable<EditVisitData> {
    return <Observable<EditVisitData>>this.http
    .GET(`${ URLS.patients_visit }${ visitId }/`)
    .pipe(
      catchError((error: HttpErrorResponse) => observableThrowError(error))
    );
  }

  getVisitTimeLogs(visitId: number): Observable<VisitTimeLog> {
    return <Observable<VisitTimeLog>>this.http
    .GET(`${ URLS.patients_visit }${ visitId }/time_logs/`)
    .pipe(
      catchError((error: HttpErrorResponse) => observableThrowError(error))
    );
  }

  getCaregiverLocationInfo(visitId: number): Observable<CaregiverLocationInfo> {
    return this.http.GET<CaregiverLocationInfo>(`${ URLS.patients_visit }${ visitId }/caregiver_location_info/`);
  }
}
