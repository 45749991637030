import { EventEmitter, Injectable, Output } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';

import { HttpService } from '@app/core/services';
import { DispatchFilter } from '@app/models/dispatch-screen/dispatch-filter.model';
import { DispatchPin } from '@app/models/dispatch-screen/dispatch-pin.model';
import { DispatchScreenVisit } from '@app/models/dispatch-screen/dispatch-screen-visit.model';
import {
  AssignCaregiverForVisit,
  DispatchScreen,
  DispatchScreenResponse
} from '@app/models/dispatch-screen/dispatch-screen.model';
import { DispatchStatus } from '@app/models/dispatch-screen/dispatch-status.model';
import { PaginatedResponse } from '@app/models/paginated-response.model';
import { UserWidget } from '@app/models/widgets.model';
import { URLS } from '@app/shared/urls';

@Injectable({
  providedIn: 'root'
})
export class DispatchScreenService {
  @Output() resetMapFilters = new EventEmitter<void>();

  private tableVisitOpeningStatus = new BehaviorSubject<{ visitId: number, open: boolean }>(null);
  private mapVisitOpeningStatus = new BehaviorSubject<{ visitId: number, open: boolean }>(null);

  private availableCasesSubject = new BehaviorSubject<DispatchScreen[]>([]);

  constructor(
    private http: HttpService
  ) { }

  set setAvailableCasesSubject(value: DispatchScreen[]) {
    this.availableCasesSubject.next(value);
  }
  get getAvailableCasesSubject(): Observable<DispatchScreen[]> {
    return this.availableCasesSubject.asObservable();
  }

  set setTableVisitOpeningStatus(value: { visitId: number, open: boolean }) {
    this.tableVisitOpeningStatus.next(value);
  }
  get getTableVisitOpeningStatus(): Observable<{ visitId: number, open: boolean }> {
    return this.tableVisitOpeningStatus.asObservable();
  }
  set setMapVisitOpeningStatus(value: { visitId: number, open: boolean }) {
    this.mapVisitOpeningStatus.next(value);
  }
  get getMapVisitOpeningStatus(): Observable<{ visitId: number, open: boolean }> {
    return this.mapVisitOpeningStatus.asObservable();
  }

  getDispatchScreen(params: object): Observable<PaginatedResponse<DispatchScreen>> {
    return this.http.GET<PaginatedResponse<DispatchScreen>>(URLS.dispatch_screen_visit, params);
  }

  createPin(userId: number, data: DispatchPin): Observable<DispatchPin> {
    return <Observable<DispatchPin>>this.http
    .POST(`${ URLS.employee }${ userId }/dispatch_screen_pin/`, data);
  }

  deletePin(userId: number, rowId: number): Observable<void> {
    return <Observable<void>>this.http
    .DELETE(`${ URLS.employee }${ userId }/dispatch_screen_pin/${ rowId }/`);
  }

  detailDispatchScreenByVisitId(visitId: number): Observable<DispatchScreenVisit> {
    return <Observable<DispatchScreenVisit>>this.http
    .GET(`${ URLS.dispatch_screen_visit }${ visitId }/`);
  }

  resetFilters(userId: number, body): Observable<DispatchFilter> {
    return <Observable<DispatchFilter>>this.http
    .PUT(`${ URLS.employee }${ userId }/dispatch_screen_filter/`, body);
  }

  updateFilters(userId: number, body): Observable<DispatchFilter> {
    return <Observable<DispatchFilter>>this.http
    .PUT(`${ URLS.employee }${ userId }/dispatch_screen_filter/`, body);
  }

  getFilters(userId: number): Observable<DispatchFilter> {
    return <Observable<DispatchFilter>>this.http
    .GET(`${ URLS.employee }${ userId }/dispatch_screen_filter/`);
  }

  getAvailableCaregiversByVisitId(visitId: number, search: string = '', limit: number = 100): Observable<PaginatedResponse<UserWidget>> {
    let query = `?limit=${ limit }`;
    if (search) {
      query += `&lookup_field=full_name&search=${ search }`;
    }
    return <Observable<PaginatedResponse<UserWidget>>>this.http
    .GET(`${ URLS.patients_visit }${ visitId }/available_caregivers/${ query }`);
  }

  assignCaregiverForVisit(visitId: number, body): Observable<AssignCaregiverForVisit> {
    return <Observable<AssignCaregiverForVisit>>this.http
    .PATCH(`${ URLS.patients_visit }${ visitId }/change_caregiver/`, body);
  }

  changeStatus(visitId: number, body): Observable<DispatchStatus> {
    return this.http.PATCH<DispatchStatus>(`${ URLS.patients_visit }${ visitId }/change_status/`, body);
  }
}
