import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { QueryBuilder } from '@app/core/query-builder';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class HttpService {
  constructor(private http: HttpClient) {
  }

  private get defaultHeaders(): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/json;charset=utf-8',
    });
  }

  public POST<T = any>(url: string, data = {}): Observable<T> {
    const options = { headers: this.defaultHeaders };
    return this.http.post<T>(url, data, options);
  }

  public GET<T = any>(url: string, queryParams?: any): Observable<T> {
    const options = { headers: this.defaultHeaders };
    if (queryParams) {
      url += `?${ QueryBuilder.buildFromObject(queryParams) }`;
    }

    return this.http.get<T>(url, options);
  }

  public PUT<T = any>(url: string, data = {}): Observable<T> {
    const options = { headers: this.defaultHeaders };
    return this.http.put<T>(url, data, options);
  }

  public PATCH<T = any>(url: string, data = {}): Observable<T> {
    const options = { headers: this.defaultHeaders };
    return this.http.patch<T>(url, data, options);
  }

  public DELETE<T = any>(url: string, queryParams?: any): Observable<T> {
    const options = { headers: this.defaultHeaders };

    if (queryParams) {
      url += `?${ QueryBuilder.buildFromObject(queryParams) }`;
    }

    return this.http.delete<T>(url, options);
  }
}
