<div class="element">
  <div class="element-navigation">
<!--    <a [routerLink]="[previousPageUrl]">-->
<!--      <div class="back-button">-->
<!--        <img src="/assets/images/chevron-left24px.svg" alt="Back">-->
<!--      </div>-->
<!--      <p class="back-title txt1">Back</p>-->
<!--    </a>-->

<!--    <div class="navigate-button">-->
<!--      <a [routerLink]="[previousPageUrl]">{{previousPageLabel}}</a>-->
<!--      <p class="previous-page-dash mb0">-</p>-->
<!--      <p class="current-page txt1">{{currentPageLabel}}</p>-->
<!--    </div>-->
  </div>
  <div class="element-tabs" [hidden]="!tabs?.length">
    <a *ngFor="let tab of tabs"
       [routerLinkActive]="['tab-active']"
       [routerLinkActiveOptions]="{exact: true}"
       [routerLink]="[tab.link]">
      <div class="tab-text">
        <p class="mb0">{{tab.label}}</p>
      </div>
      <div class="active-line"></div>

    </a>

  </div>
</div>

