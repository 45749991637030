import {
  Component,
  ElementRef,
  ViewChild,
  Input,
  Output,
  EventEmitter,
  HostListener,
} from '@angular/core';

import { OptionsListItemIfc } from '@app/shared/interfaces/options-list-item.ifc';

@Component({
  selector: 'app-parametric-search',
  templateUrl: './parametric-search.component.html',
  styleUrls: ['./parametric-search.component.scss'],
})
export class ParametricSearchComponent {
  optionsAreOpened = false;
  searchTimeout: any;
  active = false;
  selectedIndex = 0;
  options: OptionsListItemIfc[] = [];

  @Input() searchText = '';
  @Input() placeholder = '';
  @Input() optionsPosition = 'bottom';
  @Input() timeout = 500;
  @Input() size: 'small' | 'medium' | 'large' = 'medium';

  @Output() searchTextChanged = new EventEmitter<string>();
  @Output() searchOptionChanged = new EventEmitter<string>();

  @ViewChild('optionsListWrapper', {static: false})
  optionsListWrapper: ElementRef;

  constructor(private eRef: ElementRef) { }

  @Input()
  set initOptions(options: any[]) {
    this.options = options.map((item) => new OptionsListItemIfc(item.value, item.label, item.checked));
    this.selectedIndex = this.options.findIndex((item) => item.checked);
  }

  @HostListener('document:click', ['$event.target'])
  clickOut(target) {
    if (!this.eRef.nativeElement.contains(target)) { this.closeOptions(); }
  }

  // keyDown(event: KeyboardEvent) {
  //   if (this.optionsAreOpened || this.searchIsOpened) {
  //     if (event.keyCode === 27) {
  //       this.leaveSearch();
  //     }
  //   }
  // }

  closeOptions() {
    this.optionsAreOpened = false;
    this.active = false;
  }

  openOptions(): void {
    this.optionsAreOpened = true;
    this.active = true;
  }

  optionWasSelected(index: number) {
    this.selectedIndex = index;
    this.options[this.selectedIndex].checked = true;
    this.options.filter((item) => item.value !== this.options[this.selectedIndex].value)
                .forEach((item) => item.checked = false);
    this.searchOptionChanged.emit(this.options[this.selectedIndex].value as string);
    this.closeOptions();
  }

  searchInputChange() {
    clearTimeout(this.searchTimeout);
    this.searchTimeout = setTimeout(() => {
      this.searchTextChanged.emit(this.searchText);
    }, this.timeout);
  }

  focusInput(): void { this.active = true; }
  focusOut(): void { this.active = false; }
}
