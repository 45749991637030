import { Pipe, PipeTransform } from '@angular/core';
import { FilterObjIfc } from '@app/shared/interfaces/pipes.ifc';

@Pipe({name: 'arrayFilter'})
export class ArrayFilterPipe implements PipeTransform {
  transform(items: any[], filterObj: FilterObjIfc): any {
    if (!items || !filterObj) {
      return items;
    }
    // filter items array, items which match and return true will be
    // kept, false will be filtered out
    return items.filter(item => item[filterObj.key] === filterObj.value);
  }

}
