import { Injectable } from '@angular/core';
import { Observable, throwError as observableThrowError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { HttpService } from './http/http.service';
import { URLS } from '@app/shared/urls';
import {
  BookingTimeSlots, InterviewDetails,
  ScheduleTimeSlot,
  ScheduleTimeSlotResponse
} from '@app/models/hr/booking.model';

@Injectable({
  providedIn: 'root'
})
export class BookingService {

  constructor(
    private http: HttpService
  ) { }

  getBookingTimeSlots(uuid: string, date: string, timeZoneOffset: string): Observable<BookingTimeSlots> {
    return <Observable<BookingTimeSlots>>this.http
    .GET(`${ URLS.schedule }${ uuid }/?on_date=${ date }&utc_offset=${ timeZoneOffset }`)
    .pipe(
      catchError((error: any) => observableThrowError(error))
    );
  }

  setBookingTime(uuid: string, data: ScheduleTimeSlot): Observable<ScheduleTimeSlotResponse> {
    return <Observable<ScheduleTimeSlotResponse>>this.http
    .POST(`${ URLS.schedule }${ uuid }/submit/`, data)
    .pipe(
      catchError((error: any) => observableThrowError(error))
    );
  }

  getInterviewData(uuid: string): Observable<BookingTimeSlots> {
    return <Observable<BookingTimeSlots>>this.http
    .GET(`${ URLS.schedule }${ uuid }/`)
    .pipe(
      catchError((error: any) => observableThrowError(error))
    );
  }

  cancelBookedInterview(uuid: string): Observable<InterviewDetails> {
    return <Observable<InterviewDetails>>this.http
    .POST(`${ URLS.schedule }${ uuid }/cancel/`)
    .pipe(
      catchError((error: any) => observableThrowError(error))
    );
  }

}
