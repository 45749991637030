/* tslint:disable */
export class GeoPointIfc {
  lat: number;
  lng: number;

  constructor(lat: number, lng: number) {
    this.lat = lat;
    this.lng = lng;
  }
}

export class GeoJsonIfc {
  address: string;
  formattedAddress: string;
  addressId: string;
  addressPoint: GeoPointIfc | null;
  country: string;
  state: string;
  county: string;
  city: string;
  zip: number|string;
  streetName: string;
  streetNumber: string;

  constructor(address: string,
              formattedAddress: string,
              addressId: string = '',
              lat: number | null = null,
              lng: number | null = null,
              country: string = '',
              state: string =  '',
              county: string =  '',
              city: string =  '',
              zip: number | string = '',
              streetName: string = '',
              streetNumber: string = '') {
    this.address = address;
    this.formattedAddress = formattedAddress;
    this.addressId = addressId;
    this.addressPoint = (lat && lng) ? new GeoPointIfc(lat, lng) : null;
    this.country = country;
    this.state = state;
    this.county = county;
    this.city = city;
    this.zip = zip;
    this.streetName = streetName;
    this.streetNumber = streetNumber;
  }
}
