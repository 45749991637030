import { isObservable, Observable, of } from 'rxjs';
import { catchError, finalize, shareReplay, take } from 'rxjs/operators';
import { CustomCache } from './custom-cache';

export class ObservableCache<T = unknown> extends CustomCache<Observable<T>> {
  public cache = new Map<any, Observable<T>>();

  get(key: unknown, setValue?: Observable<T>): Observable<T> {
    if (this.cache.has(key)) {
      return this.cache.get(key).pipe(take(1));
    }

    if (setValue) {
      return this.set(key, setValue);
    }

    return of(null);
  }

  set(key: unknown, data: T | Observable<T>): Observable<T> {
    data = isObservable(data) ? data : of(data);

    return this.cache.set(key, data.pipe(
      catchError((err) => {
        this.cache.delete(key);

        return of(err);
      }),
      finalize(() => this.setClearTimeoutIfNeed(key)),
      shareReplay(1),
    )).get(key).pipe(
      take(1),
    );
  }

  has(key: any): boolean {
    return this.cache.has(key);
  }

  clear(): void {
    this.cache.clear();
  }

  delete(key: unknown): Observable<T> {
    const deleteItem = this.cache.get(key);

    this.cache.delete(key);

    return deleteItem;
  }
}
