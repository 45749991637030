import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { HttpService } from '@app/core/services';
import { PaginatedResponse } from '@app/models/paginated-response.model';
import {
  PayrollResponse,
  PayrollTemplate,
  PayrollTemplatePayload,
  PayrollWidget
} from '@app/models/settings/payroll.model';
import { URLS } from '@app/shared/urls';


@Injectable({
  providedIn: 'root'
})
export class PayrollService {

  constructor(
    private http: HttpService
  ) { }

  getPayroll(): Observable<PayrollResponse> {
    return this.http.GET<PayrollResponse>(URLS.payroll_setup);
  }

  updatePayroll(data: Array<PayrollTemplate>): Observable<PayrollResponse> {
    return this.http.PUT<PayrollResponse>(`${ URLS.payroll_setup }`, { templates_data: data });
  }

  getTemplates(query: string): Observable<PaginatedResponse<PayrollTemplate | PayrollWidget>> {
    return this.http.GET<PaginatedResponse<PayrollTemplate>>(`${ URLS.payroll_template }?${ query }`);
  }

  getTemplateById(id: number): Observable<PayrollTemplate> {
    return this.http.GET<PayrollTemplate>(`${ URLS.payroll_template }${ id }/`);
  }

  createTemplate(data: PayrollTemplatePayload): Observable<PayrollTemplate> {
    return this.http.POST<PayrollTemplate>(`${ URLS.payroll_template }`, data);
  }

  editTemplate(id: number, data: PayrollTemplatePayload): Observable<PayrollTemplate> {
    return this.http.PUT<PayrollTemplate>(`${ URLS.payroll_template }${ id }/`, data);
  }

  deleteTemplate(id: number): Observable<void> {
    return this.http.DELETE<void>(`${ URLS.payroll_template }${ id }/`);
  }
}
