import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren
} from '@angular/core';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { Subscription } from 'rxjs';

import { ChatService } from '../../services/chat.service';
import { PaginatedResponse } from '../../models/paginated-response.model';
import { Message } from '../../models/message.model';
import { UserChat } from '../../models/chat.model';
import { chatMessageLimit } from '../../shared/constants';
import { MessageCenterService } from '../../services';
import { unsubscribe } from '../../shared/helper';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ModuleSubscription, RealtimeService } from '@app/core/services';
import { WsAction, WsConnectionModule, WsEvent } from '@app/core/services/websocket/ws.models';

@UntilDestroy()
@Component({
  selector: 'app-messages-list',
  templateUrl: './messages-list.component.html',
  styleUrls: ['./messages-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MessagesListComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() userChat: UserChat;
  @Output() selectedForThreadMessageId: EventEmitter<number> = new EventEmitter<number>();
  @Output() updateChat: EventEmitter<UserChat> = new EventEmitter<UserChat>();

  @ViewChild(CdkScrollable) container: CdkScrollable;
  @ViewChildren('message', { read: ElementRef }) messageRefs: QueryList<ElementRef>;

  messages: Message[] = [];
  totalMessages: number;
  private oldHeight = 0;
  isLoading: boolean = false;
  scrolledFromBottom: number;
  userId: number;

  // intObserver: IntersectionObserver;
  selectedThreadMessage: Message;

  unreadIndex: number = null;
  offsetUnreadMessage: number = 0;

  private addedChatsSubscription: Subscription;
  private openedWsModule: ModuleSubscription;

  constructor(
    private chatService: ChatService,
    private messageCenterService: MessageCenterService,
    private realtimeService: RealtimeService,
    private cdr: ChangeDetectorRef
  ) {
  }

  ngOnInit(): void {
    this.userId = this.messageCenterService.getUser().id;
    this.initWebsocket();
    this.loadChatMessages(this.userChat.unread_messages);

    this.messageCenterService.threadClosedEmitter
      .pipe(untilDestroyed(this))
      .subscribe((userChatId: number) => {
        if (this.userChat.id === userChatId) {
          this.userChat.open_thread = false;
          this.cdr.detectChanges();
        }
      });

    this.addedChatsSubscription = this.messageCenterService.addedUsersChats$
      .subscribe((chats: UserChat[]) => {
        const currentChat = chats.find(chat => chat.id === this.userChat.id);
        if (currentChat && !currentChat.collapsed) {
          this.sendReadMessages();

          this.messageCenterService.newMessagesToCollapsedChats =
            this.messageCenterService.newMessagesToCollapsedChats.filter(chat => chat.chatId !== this.userChat.id);
        }
      });
  }

  private initWebsocket(): void {
    const user = this.messageCenterService.getUser();
    this.openedWsModule = this.realtimeService.subscribeToModule({
      user_id: user.id,
      module: WsConnectionModule.chat,
      module_pk: this.userChat.id
    });

    this.openedWsModule.moduleMessages$
      .pipe(untilDestroyed(this))
      .subscribe((response: MessageEvent) => {
        const data = JSON.parse(response.data);
        const event = data.event;
        const payload = data.payload;
        // console.log('socket response to chat:',data);

        if (payload.chat === this.userChat.id) {
          let viewedMessage = this.messages.find(message => message.id === payload.id);
          switch (event) {
            case WsEvent.newMessage:
              if (!payload.reply_to) {
                this.messages.push(payload);
              } else {
                this.chatService.setMessageSentThread = payload;
              }

              const addedChats = this.messageCenterService.getAddedChats();
              const userChat = addedChats.find(chat => chat.id === payload.chat);
              if (userChat) {
                if (userChat.collapsed) {
                  const idx = this.messageCenterService.newMessagesToCollapsedChats.findIndex(chat => chat.chatId === userChat.id);
                  if (idx === -1) {
                    this.messageCenterService.newMessagesToCollapsedChats.push({
                      chatId: userChat.id,
                      messages: [payload.id]
                    });
                  } else {
                    if (!this.messageCenterService.newMessagesToCollapsedChats[idx].messages.includes(payload.id)) {
                      this.messageCenterService.newMessagesToCollapsedChats[idx].messages.push(payload.id);
                    }
                  }
                } else {
                  const user = this.messageCenterService.getUser();
                  const payloadToSend = {
                    company_id: user.company.id,
                    user_id: user.id,
                    message_id: payload.id,
                    chat_id: payload.chat
                  };
                  this.openedWsModule.websocket.sendMessage({
                    action: WsAction.userViewedMessage,
                    payload: payloadToSend
                  });
                }
              }
              break;
            case WsEvent.deleteMessage:
              const idx = this.messages.findIndex(message => message.id === payload.id);
              this.messages.splice(idx, 1);
              break;
            case WsEvent.messageRepliesInfoUpdating:
              viewedMessage.replies_info.last_message_time = payload.last_message_time;
              viewedMessage.replies_info.messages_count = payload.messages_count;
              viewedMessage.replies_info.users = payload.users;
              break;
            case WsEvent.userViewedMessage:
              if (viewedMessage) {
                viewedMessage.is_viewed = true;
              }
              break;
            case WsEvent.messageStatusUpdating:
              if (viewedMessage) {
                viewedMessage.status = payload.status;
                viewedMessage.status_value = payload.status_value;
              }
              break;
          }
          this.cdr.detectChanges();
        } else {
          switch (event) {
            case WsEvent.chatUpdating:
              this.updateChat.emit(payload);
              break;
            case WsEvent.userUpdating:
              this.userChat.image = payload.avatar;
              this.userChat.name = payload.short_name;
              this.userChat.direct_to.avatar = payload.avatar;
              this.userChat.direct_to.short_name = payload.short_name;
              this.messages.forEach(message => message.sender.avatar = payload.avatar);
              break;
          }
          this.cdr.detectChanges();
        }
      });
  }

  private loadChatMessages(unreadMessages: number = 0): void {
    this.isLoading = true;
    if (this.messages.length) {
      this.cdr.detectChanges();
    }

    let limit = chatMessageLimit;
    if (unreadMessages > 10) {
      limit = unreadMessages;
    }
    this.chatService.getChatMessages({
      offset: this.messages.length,
      limit,
      chat_id: this.userChat.id,
    })
      .pipe(untilDestroyed(this))
      .subscribe((response: PaginatedResponse<Message>) => {
          this.isLoading = false;
          const result = [];
          const indexes = [];
          response.results.reverse().forEach((message, i) => {
            if (message.is_new) {
              indexes.push(i);
            }
            result.push(message);
          });

          if (!this.messages.length) {
            this.messages = result;
          } else {
            this.messages.unshift(...result);
          }

          if (indexes.length) {
            this.unreadIndex = Math.min(...indexes);
          }
          this.totalMessages = response.count;
          this.cdr.detectChanges();

          this.sendReadMessages();
        },
        () => {
          this.isLoading = false;
          this.cdr.detectChanges();
        });
  }

  private sendReadMessages(): void {
    const newMessages = this.messages.filter(message => message.is_new);
    if (newMessages.length) {
      const user = this.messageCenterService.getUser();
      const payloadToSend = {
        company_id: user.company.id,
        user_id: user.id,
        chat_id: this.userChat.id
      };
      newMessages.forEach(message => {
        payloadToSend['message_id'] = message.id;
        this.openedWsModule.websocket.sendMessage({
          action: WsAction.userViewedMessage,
          payload: payloadToSend
        });
        this.messageCenterService.removeUnreadMessageHeader(1);
      });
    }
  }

  openMessageThread(message: Message): void {
    this.selectedThreadMessage = message;
    this.cdr.detectChanges();
    this.selectedForThreadMessageId.emit(message.id);
    this.chatService.setMessageSentThread = message;
  }

  ngAfterViewInit(): void {
    // this.setIntersectionObserver();

    this.container.elementScrolled().pipe(untilDestroyed(this)).subscribe((event: Event) => {
      this.scrolledFromBottom = this.container.measureScrollOffset('bottom');

      let fromTop = this.container.measureScrollOffset('top');
      if (fromTop <= 1) {
        if (this.messages.length < this.totalMessages) {
          if (!this.isLoading) {
            this.loadChatMessages();
          }
        }
      }
    });

    this.messageRefs.changes.pipe(untilDestroyed(this)).subscribe(messages => {
      let height = this.container.getElementRef().nativeElement.scrollHeight;
      this.messageRefs.forEach((message, i) => {
        if (i === this.unreadIndex) {
          this.offsetUnreadMessage = height - message.nativeElement.offsetTop - 50;
        }
      });
      let scrollTo = this.scrolledFromBottom;
      if (height - this.oldHeight > this.container.measureScrollOffset('bottom')) {
        scrollTo = this.offsetUnreadMessage;
      }

      this.container.scrollTo({ bottom: scrollTo, behavior: 'auto' });
      this.oldHeight = height;
      this.cdr.detectChanges();

      // if (messages.first) {
      //   this.intObserver.observe(messages.first.nativeElement);
      // }
    });
  }

  // private setIntersectionObserver(): void {
  //   this.intObserver = new IntersectionObserver((entries) => {
  //     this.handleIntersection(entries);
  //   }, {
  //     root: this.container.getElementRef().nativeElement,
  //     threshold: 0.8
  //   });
  // }

  // private handleIntersection(entries: IntersectionObserverEntry[]): void {
  //   if (entries[0].isIntersecting) {
  //     if (this.messages.length < this.totalMessages) {
  //       this.loadChatMessages();
  //     }
  //   }
  // }

  resendMessage(messageId: number): void {
    const msg = this.messages.find(message => message.id === messageId);
    msg.resending = true;
    this.chatService.resendMessage(messageId)
      .pipe(untilDestroyed(this))
      .subscribe(() => {
          msg.resending = false;
          this.cdr.detectChanges();
        },
        () => {
          msg.resending = false;
          this.cdr.detectChanges();
        });
  }

  deleteMessage(messageId: number): void {
    this.chatService.deleteMessage(messageId).subscribe();
  }

  ngOnDestroy(): void {
    unsubscribe(this.addedChatsSubscription);
    this.openedWsModule.unsubscribe();
  }
}
