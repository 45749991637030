import { Pipe, PipeTransform } from '@angular/core';

import { getFormattedStringByPhone } from '@app/common';

@Pipe({
  name: 'phone'
})
export class PhonePipe implements PipeTransform {
  transform(value: any): string | null {
    return getFormattedStringByPhone(value);
  }
}
