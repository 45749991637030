import { Component, ChangeDetectorRef, Input, forwardRef, ChangeDetectionStrategy } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

type CheckboxValue = string | number;

interface CheckboxOption {
  label: string;
  value: CheckboxValue;
}

@Component({
  selector: 'app-checkbox-multiselect-field',
  templateUrl: 'checkbox-multiselect-field.component.html',
  styleUrls: ['checkbox-multiselect-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxMultiselectFieldComponent),
      multi: true
    },
  ]
})
export class CheckboxMultiselectFieldComponent implements ControlValueAccessor {
  @Input() disabled = false;
  @Input() checkboxes: CheckboxOption[] = [];

  checkedCheckboxes: CheckboxValue[] = [];

  private onChangeCallback: any = () => null;
  private onTouchedCallback: any = () => null;

  constructor(private cdr: ChangeDetectorRef) {
  }

  onCheckboxToggle(value: CheckboxValue, isChecked: boolean): void {
    if (isChecked) {
      this.checkedCheckboxes.push(value);
    } else {
      this.checkedCheckboxes = this.checkedCheckboxes.filter(i => i !== value);
    }

    this.onChangeCallback(this.checkedCheckboxes);
  }

  onBlur(): void {
    this.onTouchedCallback();
  }

  writeValue(value: CheckboxValue[] | null): void {
    this.checkedCheckboxes = value ?? [];
    this.cdr.markForCheck();
  }

  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.cdr.markForCheck();
  }
}
