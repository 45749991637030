import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';
import { BehaviorSubject, Observable } from 'rxjs';

import { SITE_THEMES, MAP_THEMES } from '@app/shared/constants';
import { HttpService } from '@app/core/services';
import { URLS } from '@app/shared/urls';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  private _siteTheme = new BehaviorSubject<string>(SITE_THEMES.LIGHT);
  private _mapTheme = new BehaviorSubject<string>(MAP_THEMES.LIGHT);
  private _modalWindowOpen = new BehaviorSubject<boolean>(false);

  /**
   * Create test div element for getting width of scrollbar
   */
  static checkScrollWidth(): number {
    const div = document.createElement('div');
    div.style.overflowY = 'scroll';
    div.style.width = '50px';
    div.style.height = '50px';
    div.style.visibility = 'hidden';
    document.body.appendChild(div);
    const scrollWidth = div.offsetWidth - div.clientWidth > 0 ? div.offsetWidth - div.clientWidth : 0;
    document.body.removeChild(div);
    return scrollWidth;
  }

  static getFormatedNowDate(format: string): string {
    return <string>new DatePipe('en-US').transform(Date.now(), format);
  }

  static getFormatedDate(date: number, format: string): string {
    return <string>new DatePipe('en-US').transform(date, format);
  }

  constructor(private http: HttpService) {
  }

  public get modalWindowOpen(): BehaviorSubject<boolean> {
    return this._modalWindowOpen;
  }

  set siteTheme(color: string) {
    this._siteTheme.next(color);
  }

  set mapTheme(color: string) {
    this._mapTheme.next(color);
  }

  get siteTheme$(): BehaviorSubject<string> {
    return this._siteTheme;
  }

  get mapTheme$(): BehaviorSubject<string> {
    return this._mapTheme;
  }

  get siteTheme(): string {
    return this._siteTheme.getValue();
  }

  get mapTheme(): string {
    return this._mapTheme.getValue();
  }

  setModalWindowStatus(status: boolean) {
    this._modalWindowOpen.next(status);
  }

  deleteFile(fileId: number): Observable<void> {
    return this.http.DELETE<void>(`${ URLS.generic_file }${ fileId }/`);
  }
}
