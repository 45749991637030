<div class="status-wrapper {{ isColored ? 'status status-' + currentStatus : '' }}">
  <ng-content></ng-content>

  <ng-select
    #ngSelect
    class="app-ng-select status-select"
    bindLabel="label"
    bindValue="value"
    appendTo="body"
    placeholder="{{ 'placeholders.select' | translate }}"
    appearance="outline"
    [matTooltip]="'visitDetails.status_tooltip' | translate"
    [(ngModel)]="status"
    [items]="list"
    [readonly]="status === visitsStatuses.completed"
    [clearable]="false"
    [searchable]="false"
    [notFoundText]="customNotFoundText"
    (change)="changeStatus($event.value)">

    <ng-template ng-option-tmp let-item="item">
      <div class="option-label {{ isColored ? 'status status-'+currentStatus : '' }}">{{ item.label }}</div>
    </ng-template>
  </ng-select>
</div>
