import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { URLS } from '@app/shared/urls';
import { HttpService } from './http/http.service';
import { Attentions } from '@app/models/attentions.model';

@Injectable({
  providedIn: 'root'
})
export class AttentionsService {
  attentions: BehaviorSubject<Attentions> = new BehaviorSubject<Attentions>(null);

  constructor(
    private http: HttpService
  ) { }

  getAttentions(): Observable<Attentions> {
    return this.http.GET<Attentions>(URLS.attentions);
  }
}
