<div class="drag-and-drop-file-uploading">
	<div class="upload-file-area" appDragAndDropFile (files)="filesDropped($event)">
		<div class="upload-file-area__icon">
			<mat-icon [inline]="true">upload_file</mat-icon>
		</div>
		{{ "common.uploadFile" | translate }}
	</div>
	<div class="upload-file-selector">

		<input hidden type="file" #fileInput [accept]="allowedMimeTypes" (change)="filesUploaded($event)">

		<ng-container *ngIf="abilityToChangeFileName">
			<button *ngIf="showNameChangeBtn" [hidden]="isFileNameChanging" (click)="changeName()" matTooltip="Change file name">
				<mat-icon class="rename-icon">drive_file_rename_outline</mat-icon>
			</button>

			<div *ngIf="isFileNameChanging" class="file-rename">
				<input class="file-rename__input" type="text" appTrimInput [(ngModel)]="inputFileName"/>
				{{ this.fileExtension }}
				<button class="app-button app-button_blue file-rename__btn" (click)="saveNewName()">Save</button>
				<button class="app-button app-button_red file-rename__btn" (click)="doNotSave()">Cancel</button>
			</div>
		</ng-container>

		<button
			[hidden]="isFileNameChanging"
			matRipple
			class="action-button"
			type="button"
			(click)="openDialogUpload()">
			<mat-icon>cloud_download</mat-icon>
		</button>

		<a *ngIf="fileName" [hidden]="isFileNameChanging" class="upload-file-selector__name" target="_blank" [href]="urlFile">
			{{ fileName }}
		</a>

		<button
			*ngIf="fileName"
			[hidden]="isFileNameChanging"
			matRipple
			class="action-button"
			(click)="removeFileUpload()">
			<mat-icon svgIcon="close"></mat-icon>
		</button>
	</div>

</div>
