/* tslint:disable */
export class FullAddressIfc {
  id: number;
  address_line: string;
  address_details: string;
  full_address: string;
  city: string;
  state: string;
  state_value: string;
  county: string;
  country: string;
  zip: string;
  address_point: { lat: number, lng: number };
  is_active: boolean;

  constructor(newAddress: any) {
    this.id = newAddress.id || null;
    this.full_address = newAddress.full_address || '';
    this.address_line = newAddress.address_line || '';
    this.address_details = newAddress.address_details || '';
    this.city = newAddress.city || '';
    this.state = newAddress.state || '';
    this.state_value = newAddress.state_value || '';
    this.county = newAddress.county || '';
    this.country = newAddress.country || '';
    this.zip = newAddress.zip || '';
    this.address_point = newAddress.address_point || null;
    this.is_active = newAddress.is_active || true
  }
}
