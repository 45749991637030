import { EditUserData } from "@app/models/users/edit-user-data.model";

export interface CaregiverPayrollTemplate {
  id: number;
  template: number;
  template_name: string;
  was_changed: boolean;
  is_default: boolean;
  overtime: boolean;
  pay_cycle_value: string;
  weekend_days: string[];
  offices: { id: number; name: string; }[];
  disciplines: { id: number; name: string; is_nurse: boolean; }[];
}

export interface CaregiverPayrollTemplateDetail {
  id: number;
  template: number;
  template_name: string;
  basic_rates_cannot_be_modified: boolean;
  is_default: boolean;
  disciplines_rates_details: CaregiverPayrollTemplateRateDetails[],
  exclusions_details: CaregiverPayrollTemplateExclusionDetails[];
}

export interface CaregiverPayrollTemplateRateDetails {
  id: number;
  discipline_setup: number;
  discipline_setup_name?: string;
  regular_rate: string;
  regular_rate_is_locked: boolean;
  holiday_rate: string;
  holiday_rate_is_locked: boolean;
  overtime_rate: string;
  overtime_rate_is_locked: boolean;
}

export interface CaregiverPayrollTemplateExclusionDetails {
  id?: number;
  exclusion_type: CaregiverPayrollExclusionType;
  exclusion_type_value?: string;
  time_from?: string;
  time_to?: string;
  status: CaregiverPayrollTemplateExclusionStatus;
  status_value?: string;
  patient?: number;
  patient_short_name?: string;
  authorizations?: string[];
  disciplines_rates_details?: CaregiverPayrollTemplateRateDetails[];
  disciplines_rates_data?: CaregiverPayrollTemplateRateDetails[];
}

export interface CaregiverPayrollTemplateUpdate {
  is_default: boolean;
  disciplines_rates_data: CaregiverPayrollTemplateRateDetails[];
  exclusions_data: CaregiverPayrollTemplateExclusionDetails[];
}

export interface CaregiverPayrollTemplateCreate {
  user: number;
  template: number;
  is_default: boolean;
}

export interface CaregiverPayrollTemplateCreateResponse {
  id: number;
  user: number;
  template: number;
  is_default: boolean;
}

export interface CaregiverPayrollTemplateUpdateResponse extends CaregiverPayrollTemplateDetail {}

export interface CaregiverPayrollDialogData {
  mode: string;
  title: string;
  buttonLabel: string;
  templatesInUse: number[];
  user: EditUserData;
  templateId?: number;
}

export enum CaregiverPayrollExclusionType {
  Time,
  PatientWithAuth
}

export enum CaregiverPayrollTemplateExclusionStatus {
  Active,
  Inactive
}
