import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'multipleStringData'
})
export class MultipleStringDataPipe implements PipeTransform {

  transform(obj: any, property: string): string {
    let props = property.split(',');
    let arr = [];
    props.forEach(el => {
      let val = this.getValue(obj, el);
      if (val) {
        arr.push(val);
      }
    });
    return arr.join(', ');
  }

  getValue(data: any, val: string): string {
    const valSplit = val.split('.');
    for (const val of valSplit) {
      if (Array.isArray(data)) {
        return data.map(el => el[val]).join(', ');
      } else {
        data = data[val];
        if (!data) {
          return '';
        }
      }
    }
    return data;
  }
}
