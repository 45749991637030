import { Directive, Self } from '@angular/core';
import { FormControlName } from '@angular/forms';

// This directive allows to get NgControl instance in template when formControlName directive is binding.
// Example:
// <input formControlName="name" #controlRef="ngControl"/> --> {{ controlRef }} <-- here will be NgControl instance

@Directive({ selector: '[formControlName]', exportAs: 'ngControl', standalone: true })
export class FormControlNameExport extends FormControlName {
  constructor(@Self() formControlName: FormControlName) {
    super(<any>null, <any>null, <any>null, <any>null, <any>null);
    return formControlName;
  }
}
