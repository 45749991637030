import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input, OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren
} from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { Subscription } from 'rxjs';

import { UserChat } from '../../models/chat.model';
import { Message } from '../../models/message.model';
import { ChatService } from '../../services';
import { PaginatedResponse } from '../../models/paginated-response.model';
import { chatMessageLimit } from '../../shared/constants';
import { unsubscribe } from '../../shared/helper';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-thread-messages-list',
  templateUrl: './thread-messages-list.component.html',
  styleUrls: ['./thread-messages-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ThreadMessagesListComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() userChat: UserChat;
  @Input() userId: number;
  @Input() selectedThreadMessage: Message;
  @ViewChild(CdkScrollable) container: CdkScrollable;
  @ViewChildren('message', { read: ElementRef }) messageRefs: QueryList<ElementRef>;
  isThreadMessagesLoading: boolean = false;
  threadMessages: Message[] = [];
  intObserver: IntersectionObserver;
  totalMessages: number;
  currentThreadMessageId: number;

  private getThreadMessageSubscription: Subscription;

  constructor(
    private chatService: ChatService,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.getThreadMessageSubscription = this.chatService.getMessageSentThread
      .pipe(untilDestroyed(this))
      .subscribe((response: Message) => {
        if (this.selectedThreadMessage && response?.id && this.userChat.id === response?.chat) {
          if (this.currentThreadMessageId !== this.selectedThreadMessage.id) {
            this.isThreadMessagesLoading = true;
            this.cdr.detectChanges();
          }
          this.loadThreadMessages(this.selectedThreadMessage);
        }
      });
  }

  private loadThreadMessages(message: Message): void {
    this.chatService.getChatMessages({
      limit: chatMessageLimit,
      chat_id: this.userChat.id,
      reply_to_id: message.id,
    })
      .pipe(untilDestroyed(this))
      .subscribe((response: PaginatedResponse<Message>) => {
        this.isThreadMessagesLoading = false;
        this.threadMessages = response.results.reverse();
        this.totalMessages = response.count;
        this.threadMessages.unshift(message);
        this.currentThreadMessageId = this.selectedThreadMessage.id;
        this.cdr.detectChanges();
      },
      (error: HttpErrorResponse) => {
        this.isThreadMessagesLoading = false;
        this.cdr.detectChanges();
      });
  }

  ngAfterViewInit(): void {
    this.setIntersectionObserver();

    this.messageRefs.changes.pipe(untilDestroyed(this)).subscribe(messages => {
      this.container.scrollTo({ bottom: 0, behavior: 'auto' });
    });
  }

  private setIntersectionObserver(): void {
    this.intObserver = new IntersectionObserver((entries) => {
      this.handleIntersection(entries);
    }, {
      root: null,
      threshold: 0.5
    });
  }

  private handleIntersection(entries: IntersectionObserverEntry[]): void {
    if (entries[0].isIntersecting) {
      if (this.threadMessages.length < this.totalMessages) {
        this.loadThreadMessages(this.selectedThreadMessage);
      }
    }
  }

  trackChatMessage(idx: number, message): number {
    return message.id;
  }

  ngOnDestroy(): void {
    unsubscribe(this.getThreadMessageSubscription);
  }
}
