<div class="avatar" (click)="imageInput.click()">
  <div class="avatar__container avatar__layout" [hidden]="value"></div>
  <div
    class="avatar__container avatar2"
    [hidden]="!value"
    [ngStyle]="{ 'background-image': 'url(' + value + ')' }"
  ></div>
  <label
    class="avatar__label"
    data-action="change"
    data-toggle="tooltip"
    data-original-title="Change avatar">
    <i class="fa fa-pen icon-sm text-muted"></i>
  </label>
</div>

<input
  type="file"
  #imageInput
  accept="image/jpeg,image/png"
  (change)="onFileChanged($event)"
  hidden>
