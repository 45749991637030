import { Injectable } from '@angular/core';
import { Observable, throwError as observableThrowError } from 'rxjs';

import { HttpService } from '../http/http.service';
import { PermissionTemplate, PermissionTemplateById, PermissionTemplateCreateUpdate, ProfilePermissions, PermissionsForTemplate, DefaultPermissions } from '@app/models/settings/permissions.model';
import { PaginatedResponse } from '@app/models/paginated-response.model';
import { URLS } from '@app/shared/urls';

@Injectable({
  providedIn: 'root'
})
export class PermissionsService {

  constructor(
    private http: HttpService
  ) { }

  getPermissionsForTemplate(): Observable<PermissionsForTemplate> {
    return this.http.GET<PermissionsForTemplate>(`${ URLS.permission_template }permissions/`);
  }

  getDefaultPermissions(query: string): Observable<DefaultPermissions> {
    return this.http.GET<DefaultPermissions>(`${ URLS.permission_template }default_permissions/?${ query }`);
  }

  getPermissionTemplates(query: string): Observable<PaginatedResponse<PermissionTemplate>> {
    return this.http.GET<PaginatedResponse<PermissionTemplate>>(`${ URLS.permission_template }?${ query }`);
  }

  getPermissionTemplateById(id: number): Observable<PermissionTemplateById> {
    return this.http.GET<PermissionTemplateById>(`${ URLS.permission_template }${ id }/`);
  }

  createPermissionTemplate(data: PermissionTemplateCreateUpdate): Observable<PermissionTemplateById> {
    return this.http.POST<PermissionTemplateById>(`${ URLS.permission_template }`, data);
  }

  editPermissionTemplate(id: number, data: PermissionTemplateCreateUpdate): Observable<PermissionTemplateById> {
    return this.http.PUT<PermissionTemplateById>(`${ URLS.permission_template }${ id }/`, data);
  }

  deletePermissionsTemplate(id: number): Observable<void> {
    return this.http.DELETE<void>(`${ URLS.permission_template }${ id }/`);
  }
}
