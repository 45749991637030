<mat-form-field class="app-chip-select" [class.isError]="isError" appearance="outline">
	<mat-chip-list #chipList>
		<mat-chip
			*ngFor="let chip of selectedChips"
			(removed)="remove(chip)">
			{{ chip }}
			<button matChipRemove>
				<mat-icon>cancel</mat-icon>
			</button>
		</mat-chip>
		<input
			#chipInput
			placeholder="Select"
			(click)="onClick()"
			(blur)="onBlur()"
			[formControl]="inputCtrl"
			[matAutocomplete]="auto"
			[matChipInputFor]="chipList"
			[matChipInputSeparatorKeyCodes]="separatorKeysCodes"
			(matChipInputTokenEnd)="add($event, chipInput)">
	</mat-chip-list>
	<mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)" class="app-mat-autocomplete">
		<mat-option *ngFor="let chip of filteredChips | async" [value]="chip">
			{{ chip }}
		</mat-option>
	</mat-autocomplete>
</mat-form-field>
