<input
  #hoursInput
  matInput
  type="number"
  placeholder="HH"
  [value]="hours"
  [disabled]="disabled"
  (blur)="onInputBlur()"
  (input)="onHoursChanged($event.target.value)"/>

<span class="colon">:</span>

<input
  #minutesInput
  matInput
  type="number"
  placeholder="MM"
  [value]="minutes"
  [disabled]="disabled"
  [max]="59"
  (blur)="onInputBlur()"
  (input)="onMinutesChanged($event.target.value)"/>
