<div class="element {{size}}"
     [ngClass]="{'active': active}">
  <fieldset class="element-label"></fieldset>

  <div class="element-text element-search" [ngClass]="{'partial': options.length > 1}">
    <div class="icon-before"><i class="fa fa-search"></i></div>
    <input
      class="value"
      [placeholder]="placeholder"
      [(ngModel)]="searchText"
      [matTooltip]="'parametricSearch.searchBy_tooltip' | translate"
      (focus)="focusInput()"
      (focusout)="focusOut()"
      (keyup)="searchInputChange()"/>
    <div
      class="icon delete-cross-icon"
      [hidden]="!searchText.length"
      (click)="searchText = ''; searchInputChange()">
    </div>
  </div>

  <div
    class="element-text element-select"
    [matTooltip]="'parametricSearch.searchFilter_tooltip' | translate"
    [ngClass]="{
      active: optionsAreOpened,
      partial: options.length,
      single: options.length === 1
    }"
    (click)="optionsAreOpened ? closeOptions() : openOptions()">
    <div class="value">{{ options[selectedIndex].label | translate}}</div>
    <div *ngIf="options.length > 1" class="icon-after">
      <img
        src="/assets/images/arrow-drop-down-24px.svg"
        alt=""
        [ngClass]="{ 'rotated': optionsAreOpened }">
    </div>
  </div>

  <div
    *ngIf="options.length > 1"
    #optionsListWrapper
    class="element-options"
    [ngClass]="{
      'open': optionsAreOpened,
      'top': optionsPosition === 'top',
      'bottom': optionsPosition === 'bottom'
    }">
    <div
      *ngFor="let option of options; let i = index"
      class="option {{ size }} text-right"
      (click)="optionWasSelected(i)">
      {{ option.label | translate }}
    </div>
  </div>
</div>
