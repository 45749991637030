<div class="popup">
	<div class="popup__header">
		<h3 class="popup__title">Scheduled Interviews</h3>
		<button mat-icon-button class="close-icon" (click)="close()">
			<mat-icon>close</mat-icon>
		</button>
	</div>

  <app-loading *ngIf="isLoading" class="loading"></app-loading>

	<div matDialogContent>
		<div class="table-container">
			<mat-table [dataSource]="interviewsDataSource" [hidden]="isLoading">
				<ng-container matColumnDef="position">
					<mat-header-cell *matHeaderCellDef>Position</mat-header-cell>
					<mat-cell *matCellDef="let row">{{ row.position }}</mat-cell>
				</ng-container>

				<ng-container matColumnDef="type">
					<mat-header-cell *matHeaderCellDef>Type</mat-header-cell>
					<mat-cell *matCellDef="let row">{{ row.type }}</mat-cell>
				</ng-container>

				<ng-container matColumnDef="interviewers">
					<mat-header-cell *matHeaderCellDef>Interviewers</mat-header-cell>
					<mat-cell *matCellDef="let row">
						<div class="interviewers-cell">
							<div class="interviewer" *ngFor="let interviewer of row.interviewers">
								{{ interviewer }}
							</div>
						</div>
					</mat-cell>
				</ng-container>

				<ng-container matColumnDef="notes">
					<mat-header-cell *matHeaderCellDef>Notes</mat-header-cell>
					<mat-cell *matCellDef="let row">{{ row.notes }}</mat-cell>
				</ng-container>

				<ng-container matColumnDef="date">
					<mat-header-cell *matHeaderCellDef>Date</mat-header-cell>
					<mat-cell *matCellDef="let row">{{ row.date }}</mat-cell>
				</ng-container>

				<ng-container matColumnDef="status">
					<mat-header-cell *matHeaderCellDef>Status</mat-header-cell>
					<mat-cell class="status-{{ setColor(row.status) }}" *matCellDef="let row">
						{{ row.status }}
					</mat-cell>
				</ng-container>

				<mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>

				<mat-row *matRowDef="let row; columns: displayedColumns;" (click)="rowClick(row)"></mat-row>
			</mat-table>

			<div *ngIf="!interviewsDataSource?.data.length && !isLoading" class="table-container__message">
				There are no scheduled Interviews
			</div>
		</div>
	</div>

</div>
