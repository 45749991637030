export interface CacheConfig {
  clearTimeout?: number;
  isPlatformServer?: boolean; // For SSR
}

export enum Time {
  OneMinute = 1000 * 60,
  HalfHour = 1000 * 60 * 30,
  OneHour = 1000 * 60 * 60,
}

const SSRCacheMaxClearTimeout = 100;

const defaultCacheConfig: Partial<CacheConfig> = {
  isPlatformServer: false,
};

export abstract class CustomCache<T, C extends CacheConfig = CacheConfig> {
  // tslint:disable-next-line:variable-name
  private _config: C = null;

  get config() {
    return this._config;
  }

  set config(value: C) {
    this._config = { ...defaultCacheConfig, ...this._config, ...value };
  }

  constructor(config?: C) {
    this.config = config;
  }

  abstract get(key: unknown, setData?: T): T;

  abstract set(key: unknown, data: T);

  abstract clear(): void;

  abstract delete(key: unknown): T;

  abstract has(key: unknown): boolean;

  protected configHasProperty(property: keyof C) {
    return this.config && this.config[property] != null;
  }

  protected setClearTimeoutIfNeed(key: unknown): void {
    if (!this.configHasProperty('clearTimeout')) {
      return;
    }

    const timeout = (this.config.isPlatformServer && this.config.clearTimeout > SSRCacheMaxClearTimeout)
      ? SSRCacheMaxClearTimeout : this.config.clearTimeout;

    setTimeout(() => {
      this.delete(key);
    }, timeout);
  }
}
