<input
  #fileInput
  hidden
  type="file"
  [multiple]="multiple"
  [accept]="allowedMIMETypes.join(',')"
  (change)="fileUploaded($event)"/>
<button
  *ngIf="type === 'button'"
  matRipple
  class="mc-button mc-button_blue"
  (click)="openDialogUpload()">
  <span class="material-icons attach-icon">attach_file</span>
  Attach
</button>
<span
  *ngIf="type === 'icon'"
  class="material-icons file-icon"
  (click)="openDialogUpload()"
>attach_file</span>
<div
  *ngIf="type === 'field'"
  class="file-field"
  [ngStyle]="{ width: fieldSize, height: fieldSize }"
  (click)="openDialogUpload()">
  <ng-container *ngIf="urlImg; else placeholderImg">
    <span class="material-icons file-field__icon" (click)="removeAvatar($event)">cancel</span>
    <img class="file-field__img" [src]="urlImg" alt="">
  </ng-container>
  <ng-template #placeholderImg>Avatar</ng-template>
</div>
