import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';

import { AvailabilityExclusionDaySchedule } from '@app/models/users/caregiver-availability.model';
import { cloneDeep, convertFrom12To24Format } from '@app/shared/helper';

export interface DayScheduleRow {
  id: number;
  name: string;
  week_day: number;
  time_from: string;
  time_to: string;
  day_off: boolean;
  valid?: boolean;
}

const DAYS_SCHEDULE_ROWS: DayScheduleRow[] = [
  {
    id: null, name: 'fullDaysOfWeek.sunday', week_day: 6,
    time_from: '', time_to: '', day_off: false, valid: true
  },
  {
    id: null, name: 'fullDaysOfWeek.monday', week_day: 0,
    time_from: '', time_to: '', day_off: false, valid: true
  },
  {
    id: null, name: 'fullDaysOfWeek.tuesday', week_day: 1,
    time_from: '', time_to: '', day_off: false, valid: true
  },
  {
    id: null, name: 'fullDaysOfWeek.wednesday', week_day: 2,
    time_from: '', time_to: '', day_off: false, valid: true
  },
  {
    id: null, name: 'fullDaysOfWeek.thursday', week_day: 3,
    time_from: '', time_to: '', day_off: false, valid: true
  },
  {
    id: null, name: 'fullDaysOfWeek.friday', week_day: 4,
    time_from: '', time_to: '', day_off: false, valid: true
  },
  {
    id: null, name: 'fullDaysOfWeek.saturday', week_day: 5,
    time_from: '', time_to: '', day_off: false, valid: true
  }
];

@Component({
  selector: 'app-availability-table',
  templateUrl: './availability-table.component.html',
  styleUrls: ['./availability-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AvailabilityTableComponent implements OnChanges {
  @Input() passedDays: AvailabilityExclusionDaySchedule[];
  @Input() defaultStartTime: string;
  @Input() defaultEndTime: string;

  @Output() valueChanged: EventEmitter<DayScheduleRow[]> = new EventEmitter<DayScheduleRow[]>();
  
  daysSchedule: DayScheduleRow[] = cloneDeep(DAYS_SCHEDULE_ROWS);
  displayedColumns: string[] = ['day', 'dateFrom', 'dateTo', 'dayOff'];

  constructor() { }

  ngOnChanges(): void {
    if (this.passedDays && this.passedDays.length > 0) {
      this.setDaysScheduleValues();
    }
  }

  private setDaysScheduleValues() {
    this.passedDays.forEach(daySchedule => {
      const rowDay = this.daysSchedule.find(ds => ds.week_day === daySchedule.week_day);
      rowDay.id = daySchedule.id;
      rowDay.week_day = daySchedule.week_day;
      rowDay.time_from = daySchedule.time_from;
      rowDay.time_to = daySchedule.time_to;
      rowDay.day_off = daySchedule.day_off;
    });
  }

  private checkRowValidity(row: AvailabilityExclusionDaySchedule): boolean {
    if ((row.time_from && !row.time_to) || (!row.time_from && row.time_to)) {
      return false;
    }

    if (!row.time_from && !row.time_to) {
      return true;
    }

    const start = this.splitTime(row.time_from);
    const end = this.splitTime(row.time_to);

    const start24 = convertFrom12To24Format(`${ start[0] }:${ start[1] } ${ start[2] }`);
    const end24 = convertFrom12To24Format(`${ end[0] }:${ end[1] } ${ end[2] }`);

    const start24Split = start24.split(':');
    const end24Split = end24.split(':');

    const startMin = +start24Split[0] * 60 + +start24Split[1];
    const endMin = +end24Split[0] * 60 + +end24Split[1];

    return startMin < endMin;
  }

  private splitTime(time: string): string[] {
    const splitTime = time.split(' ');

    const hhmm = splitTime[0];
    const ampm = splitTime[1];

    const hhmmSplit = hhmm.split(':');
    const hours = hhmmSplit[0];
    const minutes = hhmmSplit[1];

    return [hours, minutes, ampm];
  }

  timeChange(event: string, row: DayScheduleRow, key: 'time_from' | 'time_to'): void {
    row[key] = event;
    row.valid = this.checkRowValidity(row);
    
    this.valueChanged.emit(row.valid ? this.daysSchedule : []);
  }

  dayOffChange(): void {
    const allValid = this.daysSchedule.every(d => d.valid);
    this.valueChanged.emit(allValid ? this.daysSchedule : []);
  }
}
