export class OptionsListItemIfc {
  value: string | number | any;
  label: string;
  checked: boolean;

  constructor(value, label, checked = false) {
    this.value = value;
    this.label = label;
    this.checked = checked;
  }
}
