export interface ExternalLink {
  communication_mode: CommunicationMode;
  user_to?: number;
  success: boolean;
  authorized_link: string;
  jwt_token: string;
}

export interface ExternalLinkPayload extends Pick<ExternalLink, 'communication_mode' | 'user_to'> {
}

export enum CommunicationMode {
  Chat,
  ListOfChats,
  JwtToken
}
