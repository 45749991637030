import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError as observableThrowError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { HttpService } from './http/http.service';
import { URLS } from '@app/shared/urls';
import { Mailing, Recipient } from '@app/models/hr/mailing.model';
import { PaginatedResponse } from '@app/models/paginated-response.model';

@Injectable({
  providedIn: 'root'
})
export class BlastingService {
  private selectedLetter = new BehaviorSubject<Mailing>(null);
  private statusLoading: Observable<boolean>;

  constructor(
    private http: HttpService
  ) { }

  get getStatusLoading(): Observable<boolean> {
    return this.statusLoading;
  }

  set setStatusLoading(status: Observable<boolean>) {
    this.statusLoading = status;
  }

  set setSelectedLetter(letter: Mailing) {
    this.selectedLetter.next(letter);
  }

  get getSelectedLetter(): Observable<Mailing> {
    return this.selectedLetter.asObservable();
  }

  getBlastingList(url: string): Observable<PaginatedResponse<Mailing>> {
    return <Observable<PaginatedResponse<Mailing>>>this.http
    .GET(url)
    .pipe(
      catchError((error: HttpErrorResponse) => observableThrowError(error))
    );
  }

  getUserNames(userIds: string): Observable<Recipient[]> {
    return <Observable<Recipient[]>>this.http
    .GET(`${ URLS.profile }?no_limits=true&widget=fk&user_ids=${ userIds }`)
    .pipe(
      catchError((error: HttpErrorResponse) => observableThrowError(error))
    );
  }

}
