import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'splitByDot'})
export class SplitByDotPipe implements PipeTransform {

  transform(someObject: object, someProperty: string): any {
    const parts = someProperty.split('.');
    for (const part of parts) {
      if (Array.isArray(someObject)) {
        return someObject.map((i) => i[part]).join(', ');
      } else {
        someObject = someObject[part] ?? '';
      }
    }
    return someObject;
  }
}
