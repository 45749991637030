import { Component, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Component({
  template: ''
})
export class SortTableComponent implements OnInit {
  protected sort = new BehaviorSubject<string>(null);
  defaultSortingColumn = 'id';
  keySortingPage = '';
  sortingColumns = {};
  sortBy: string;

  constructor() { }

  ngOnInit(): void {
    const sortClients = localStorage.getItem(this.keySortingPage);
    if (!this.sortBy) {
      if (!sortClients) {
        this.sortBy = `${ this.defaultSortingColumn },desc`;
        localStorage.setItem(this.keySortingPage, this.sortBy);
      } else {
        this.sortBy = sortClients;
        const sortSplit = sortClients.split(',');
        let sortDirect;
        sortSplit[1] === 'asc' ? sortDirect = 'sortAsc' : sortDirect = 'sortDesc';
        this.sortingColumns[sortSplit[0]] = sortDirect;
      }
    } else {
      this.sortBy = sortClients;
    }

    this.sort.subscribe((response: string) => {
      if (response) {
        const sortSplit = response.split(',');
        this.sortBy = `${ sortSplit[0] },${ sortSplit[1] }`;
        localStorage.setItem(this.keySortingPage, this.sortBy);
        this.loadTableData();
      }
    });
  }

  public loadTableData(): void {}

  public onSortClick(field: string): void {
    this.clearSortingColumns();

    const sortSplit = this.sortBy.split(',');
    if (field !== sortSplit[0]) {
      this.sortingColumns[field] = 'sortAsc';
      this.sort.next(`${ field },asc`);
    } else {
      if (sortSplit[1] === 'asc') {
        this.sortingColumns[field] = 'sortDesc';
        this.sort.next(`${ field },desc`);
      } else {
        this.sortingColumns[this.defaultSortingColumn] = 'noSort';
        this.sort.next(`${ this.defaultSortingColumn },desc`);
      }
    }
  }

  private clearSortingColumns(): void {
    for (let key in this.sortingColumns) {
      if (this.sortingColumns.hasOwnProperty(key)) {
        this.sortingColumns[key] = 'noSort';
      }
    }
  }

}
