<nav class="editor-menu">
	<mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
		<mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding>
			<button
				matRipple
				class="editor-menu__item"
				[ngClass]="{
          'active': node.name === activeNode,
          'invalid': isInvalid(node.name)
        }"
				[disabled]="node.disabled"
				(click)="changeActiveNode(node.name)">
				<span>{{ node.name | translate }}</span>
        <span *ngIf="getNotifications(node.name)" class="notification-budget">
          + {{ getNotifications(node.name) }}
        </span>
				<ng-container *ngIf="node.marked"><span class="mark"></span></ng-container>
			</button>
		</mat-tree-node>
		<mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding>
			<button mat-icon-button matTreeNodeToggle
				[attr.aria-label]="'toggle ' + node.name">
				<mat-icon class="mat-icon-rtl-mirror">
					{{ treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right' }}
				</mat-icon>
			</button>
			{{ node.name | translate }}
		</mat-tree-node>
	</mat-tree>
</nav>
