<div class="messages" cdkScrollable [hidden]="userChat.open_thread">
  <div class="loading" [class.hidden]="messages.length === totalMessages">
    <app-loader *ngIf="isLoading"></app-loader>
  </div>

  <app-chat-message #message *ngFor="let message of messages"
    [message]="message"
    [userChatId]="userChat.id"
    [userId]="userId"
    (openMessageThread)="openMessageThread($event)"
    (deleteMessageEmitter)="deleteMessage($event)"
    (resendMessageEmitter)="resendMessage($event)"
  ></app-chat-message>
</div>

<app-thread-messages-list
  [hidden]="!userChat.open_thread"
  [userChat]="userChat"
  [userId]="userId"
  [selectedThreadMessage]="selectedThreadMessage"
></app-thread-messages-list>
