import { Component, Input } from '@angular/core';

import { SubmenuItem } from '@app/models/menu/submenu-item.model';

@Component({
  selector: 'app-page-submenu',
  templateUrl: './page-submenu.component.html',
  styleUrls: ['./page-submenu.component.scss']
})
export class PageSubmenuComponent {
  @Input() submenuList: SubmenuItem[];

}
